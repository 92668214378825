import PropTypes from 'prop-types';
import React from 'react';

import { ParSmall } from 'components/Texts/Body';

import Tooltip from './Tooltip';

class TooltipPrim extends React.Component {
  constructor(props) {
    super(props);
    this.outerRef = React.createRef();
    this.state = { needsTooltip: false };
  }

  componentDidMount() {
    if (
      !this.state.needsTooltip
      && this.outerRef.current
      && this.outerRef.current.scrollWidth > this.outerRef.current.clientWidth
    ) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState(() => ({ needsTooltip: true }));
    }
  }

  render() {
    const {
      text,
      delay = 350,
      className = '',
      placement = 'top',
      children,
      tooltipClassName = '',
      noTooltip = false,
    } = this.props;
    const content = children || text;

    const showTooltip = this.state.needsTooltip;

    return (
      <>
        <div className={`text-truncate ${className}`} ref={this.outerRef}>
          {content}
        </div>
        {showTooltip && !noTooltip && (
          <Tooltip
            placement={placement}
            autohide={false}
            targetRef={this.outerRef}
            delay={delay}
            className={`ds-tooltip-prim ${tooltipClassName}`}
          >
            <ParSmall className="mb-0 ds-text-sec">{content}</ParSmall>
          </Tooltip>
        )}
      </>
    );
  }
}

TooltipPrim.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  delay: PropTypes.number,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.any,
  noTooltip: PropTypes.bool,
};

export default TooltipPrim;
