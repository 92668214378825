"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_MENU_RECHARGES = exports.MENU_ACCOUNTANTS = exports.MENU_SUPER_ADMIN_SETTINGS = exports.MENU_COMPANY_SETTINGS = exports.MENU_PERSONAL_SETTINGS = exports.PROFILE_POPULATES = exports.BILLING_MAPPER = exports.SET_IMPERSONATING = exports.TOGGLE_TEAM_VIEW = exports.SET_PRESSED_KEY = void 0;
/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
var faArrowsTurnToDots_1 = require("@fortawesome/pro-light-svg-icons/faArrowsTurnToDots");
var faBoxes_1 = require("@fortawesome/pro-light-svg-icons/faBoxes");
var faLayerGroup_1 = require("@fortawesome/pro-light-svg-icons/faLayerGroup");
var faLightbulbDollar_1 = require("@fortawesome/pro-light-svg-icons/faLightbulbDollar");
var faPollH_1 = require("@fortawesome/pro-light-svg-icons/faPollH");
var faSlidersH_1 = require("@fortawesome/pro-light-svg-icons/faSlidersH");
var faSnowboarding_1 = require("@fortawesome/pro-light-svg-icons/faSnowboarding");
var faUsers_1 = require("@fortawesome/pro-light-svg-icons/faUsers");
var faArrowsTurnToDots_2 = require("@fortawesome/pro-regular-svg-icons/faArrowsTurnToDots");
var faBoxes_2 = require("@fortawesome/pro-regular-svg-icons/faBoxes");
var faLayerGroup_2 = require("@fortawesome/pro-regular-svg-icons/faLayerGroup");
var faLightbulbDollar_2 = require("@fortawesome/pro-regular-svg-icons/faLightbulbDollar");
var faPollH_2 = require("@fortawesome/pro-regular-svg-icons/faPollH");
var faSnowboarding_2 = require("@fortawesome/pro-regular-svg-icons/faSnowboarding");
var faUsers_2 = require("@fortawesome/pro-regular-svg-icons/faUsers");
var faLock_1 = require("@fortawesome/pro-solid-svg-icons/faLock");
var end2end_1 = require("@hudled/end2end");
exports.SET_PRESSED_KEY = 'app/SET_PRESSED_KEY';
exports.TOGGLE_TEAM_VIEW = 'app/TOGGLE_TEAM_VIEW';
exports.SET_IMPERSONATING = 'app/SET_IMPERSONATING';
exports.BILLING_MAPPER = { monthly: 'Monthly', annual: 'Annually' };
exports.PROFILE_POPULATES = [
    { child: 'companyId', root: 'companies', childAlias: 'company' },
];
exports.MENU_PERSONAL_SETTINGS = [
    {
        label: 'My account', link: '/settings/my-account', icon: faSnowboarding_1.faSnowboarding, activeIcon: faSnowboarding_2.faSnowboarding, autoFocus: true,
    },
];
exports.MENU_COMPANY_SETTINGS = [
    { label: 'Company profile', link: '/settings/my-company', icon: faLayerGroup_1.faLayerGroup, activeIcon: faLayerGroup_2.faLayerGroup },
    !end2end_1.IS_RECHARGLY && { label: 'Billing', link: '/settings/billing', icon: faLightbulbDollar_1.faLightbulbDollar, activeIcon: faLightbulbDollar_2.faLightbulbDollar },
    {
        label: 'User management', link: '/settings/users', icon: faUsers_1.faUsers, activeIcon: faUsers_2.faUsers, isExact: false,
    },
    { label: 'Integrations', link: '/settings/integrations', icon: faSlidersH_1.faSlidersH },
].filter(end2end_1.notFalsy);
var isStaging = window.location.host.match(/staging[.-]hudled|localhost/);
exports.MENU_SUPER_ADMIN_SETTINGS = [
    !end2end_1.IS_RECHARGLY && { label: 'CSV Upload', link: '/admin/csv-upload', icon: faLock_1.faLock },
    !end2end_1.IS_RECHARGLY && { label: 'Admin Dashboard', link: '/admin/dashboard', icon: faLock_1.faLock },
    { label: 'Customer Management', link: '/admin/customer-management', icon: faLock_1.faLock },
    end2end_1.IS_RECHARGLY && { label: 'Vendors Management', link: '/admin/vendor-management', icon: faLock_1.faLock },
    // { label: 'References', link: '/admin/references', icon: faLock },
    isStaging && { label: 'Emails', link: '/admin/emails', icon: faLock_1.faLock },
    !end2end_1.IS_RECHARGLY && {
        label: 'Survey Invite', link: '/admin/survey-invites', icon: faPollH_1.faPollH, activeIcon: faPollH_2.faPollH,
    },
    !end2end_1.IS_RECHARGLY && { label: 'Tools Database', link: '/admin/tools-database', icon: faLock_1.faLock },
    { label: 'Front-end Refactor', link: '/admin/frontend-refactor', icon: faPollH_1.faPollH, activeIcon: faPollH_2.faPollH },
].filter(Boolean);
exports.MENU_ACCOUNTANTS = [
    { label: 'My customers', link: '/accountant', icon: faBoxes_1.faBoxes, activeIcon: faBoxes_2.faBoxes },
];
var GET_MENU_RECHARGES = function (rechargerCompany, isAdmin) { return [
    {
        label: 'Recharges',
        link: '/recharges',
        icon: faArrowsTurnToDots_1.faArrowsTurnToDots,
        activeIcon: faArrowsTurnToDots_2.faArrowsTurnToDots,
        subMenuItems: [
            { label: 'Setup', link: '/recharges/setup', isExact: true },
            isAdmin && { label: 'Vendors', link: '/recharges/setup/vendors', isExact: true },
            isAdmin && { label: 'Migration', link: '/recharges/migration' },
            (rechargerCompany === null || rechargerCompany === void 0 ? void 0 : rechargerCompany.hasImports) && { label: 'Imports', link: '/recharges/imports' },
            { label: 'Charges', link: '/recharges/charges' },
            { label: 'Mapping', link: '/recharges/mapping' },
            (rechargerCompany === null || rechargerCompany === void 0 ? void 0 : rechargerCompany.isAdvancedFlow) && { label: 'Connections', link: '/recharges/connections' },
            { label: 'Billing', link: '/recharges/billing' },
            { label: 'Invoices', link: '/recharges/invoices' },
            (rechargerCompany === null || rechargerCompany === void 0 ? void 0 : rechargerCompany.rechargesBillingMethod) === 'Stripe' && { label: 'Agreements', link: '/recharges/agreements' },
        ].filter(end2end_1.notFalsy),
    },
]; };
exports.GET_MENU_RECHARGES = GET_MENU_RECHARGES;
