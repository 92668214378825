"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var end2end_1 = require("@hudled/end2end");
var constants_1 = require("common/constants");
var BASE_PRIMARY = end2end_1.IS_RECHARGLY
    ? {
        main: '#EE35F5',
        dark: '#200384',
        light: '#FAC2FC',
        contrastText: '#EE35F5',
    }
    : {
        light: constants_1.COLORS.roseQuartz.A300,
        main: constants_1.COLORS.roseQuartz.A500,
        dark: constants_1.COLORS.roseQuartz.A600,
        contrastText: constants_1.COLORS.roseQuartz.A400,
    };
var BASE_SECONDARY = end2end_1.IS_RECHARGLY
    ? {
        main: '#EE35F5',
        dark: '#200384',
        light: '#FEF5FE',
    }
    : {
        main: constants_1.COLORS.roseQuartz.A500,
        light: constants_1.COLORS.roseQuartz.A100,
        dark: '#FF5C83', // Darker pink
    };
var BUTTON_COLOR_MAPPER = {
    primary: __assign(__assign({}, BASE_PRIMARY), { contrastText: BASE_PRIMARY.contrastText, contained: {
            color: constants_1.COLORS.white,
            backgroundColor: BASE_PRIMARY.main,
            hover: {
                color: constants_1.COLORS.white,
                backgroundColor: BASE_PRIMARY.dark,
            },
            pressed: {
                color: constants_1.COLORS.white,
                backgroundColor: BASE_PRIMARY.contrastText,
            },
            disabled: {
                color: constants_1.COLORS.white,
                backgroundColor: BASE_PRIMARY.light,
            },
        }, outlined: {
            color: BASE_PRIMARY.contrastText,
            backgroundColor: constants_1.COLORS.white,
            borderColor: BASE_PRIMARY.contrastText,
            hover: {
                color: BASE_PRIMARY.main,
                backgroundColor: constants_1.COLORS.roseQuartz.A100,
            },
            pressed: {
                color: BASE_PRIMARY.main,
                backgroundColor: '#FFF9FB',
                borderColor: BASE_PRIMARY.contrastText,
            },
            disabled: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.roseQuartz.A100,
                borderColor: BASE_PRIMARY.light,
            },
        }, text: {
            color: BASE_PRIMARY.contrastText,
            backgroundColor: constants_1.COLORS.white,
            hover: {
                color: BASE_PRIMARY.main,
                backgroundColor: constants_1.COLORS.roseQuartz.A100,
            },
            pressed: {
                color: BASE_PRIMARY.main,
                backgroundColor: '#FFF9FB',
            },
            disabled: {
                color: BASE_PRIMARY.light,
                backgroundColor: constants_1.COLORS.white,
            },
        } }),
    secondary: BASE_SECONDARY,
    neutral: {
        light: constants_1.COLORS.neutral.A100,
        main: constants_1.COLORS.neutral.A400,
        dark: constants_1.COLORS.neutral.A600,
        contrastText: constants_1.COLORS.neutral.A500,
        contained: {
            color: constants_1.COLORS.white,
            backgroundColor: constants_1.COLORS.neutral.A400,
            hover: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.neutral.A600,
            },
            pressed: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.neutral.A300,
            },
            disabled: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.neutral.A100,
            },
        },
        outlined: {
            color: constants_1.COLORS.neutral.A400,
            backgroundColor: constants_1.COLORS.white,
            borderColor: constants_1.COLORS.neutral.A400,
            hover: {
                color: constants_1.COLORS.neutral.A500,
                backgroundColor: constants_1.COLORS.neutral.A100,
            },
            pressed: {
                color: constants_1.COLORS.neutral.A500,
                backgroundColor: '#F6F8F9',
            },
            disabled: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.neutral.A100,
                borderColor: constants_1.COLORS.neutral.A300,
            },
        },
        text: {
            color: constants_1.COLORS.neutral.A400,
            backgroundColor: constants_1.COLORS.white,
            hover: {
                color: constants_1.COLORS.neutral.A500,
                backgroundColor: constants_1.COLORS.neutral.A100,
            },
            pressed: {
                color: constants_1.COLORS.neutral.A500,
                backgroundColor: '#F6F8F9',
            },
            disabled: {
                color: constants_1.COLORS.neutral.A100,
                backgroundColor: constants_1.COLORS.white,
            },
        },
    },
    green: {
        light: constants_1.COLORS.green.A100,
        main: constants_1.COLORS.green.A500,
        dark: constants_1.COLORS.green.A600,
        contrastText: constants_1.COLORS.green.A400,
        contained: {
            color: constants_1.COLORS.white,
            backgroundColor: constants_1.COLORS.green.A500,
            hover: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.green.A600,
            },
            pressed: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.green.A600,
            },
            disabled: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.green.A100,
            },
        },
        outlined: {
            color: constants_1.COLORS.green.A500,
            backgroundColor: constants_1.COLORS.white,
            borderColor: constants_1.COLORS.green.A500,
            hover: {
                color: constants_1.COLORS.green.A600,
                backgroundColor: constants_1.COLORS.green.A100,
            },
            pressed: {
                color: constants_1.COLORS.green.A600,
                backgroundColor: constants_1.COLORS.green.A100,
            },
            disabled: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.green.A100,
                borderColor: constants_1.COLORS.green.A500,
            },
        },
        text: {
            color: constants_1.COLORS.green.A500,
            backgroundColor: constants_1.COLORS.white,
            hover: {
                color: constants_1.COLORS.green.A600,
                backgroundColor: constants_1.COLORS.green.A100,
            },
            pressed: {
                color: constants_1.COLORS.green.A600,
                backgroundColor: constants_1.COLORS.green.A100,
            },
            disabled: {
                color: constants_1.COLORS.green.A100,
                backgroundColor: constants_1.COLORS.white,
            },
        },
    },
    red: {
        light: constants_1.COLORS.red.A100,
        main: constants_1.COLORS.red.A500,
        dark: constants_1.COLORS.red.A600,
        contrastText: constants_1.COLORS.red.A400,
        contained: {
            color: constants_1.COLORS.white,
            backgroundColor: constants_1.COLORS.red.A500,
            hover: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.red.A600,
            },
            pressed: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.red.A600,
            },
            disabled: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.red.A300,
            },
        },
        outlined: {
            color: constants_1.COLORS.red.A500,
            backgroundColor: constants_1.COLORS.white,
            borderColor: constants_1.COLORS.red.A500,
            hover: {
                color: constants_1.COLORS.red.A500,
                backgroundColor: constants_1.COLORS.red.A100,
            },
            pressed: {
                color: constants_1.COLORS.red.A500,
                backgroundColor: constants_1.COLORS.red.A300,
            },
            disabled: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.red.A300,
                borderColor: constants_1.COLORS.red.A500,
            },
        },
        text: {
            color: constants_1.COLORS.red.A500,
            backgroundColor: constants_1.COLORS.white,
            hover: {
                color: constants_1.COLORS.red.A500,
                backgroundColor: constants_1.COLORS.red.A100,
            },
            pressed: {
                color: constants_1.COLORS.red.A600,
                backgroundColor: constants_1.COLORS.red.A300,
            },
            disabled: {
                color: constants_1.COLORS.red.A300,
                backgroundColor: constants_1.COLORS.white,
            },
        },
    },
    azzurro: {
        light: constants_1.COLORS.azzurro.A100,
        main: constants_1.COLORS.azzurro.A400,
        dark: constants_1.COLORS.azzurro.A500,
        contrastText: constants_1.COLORS.azzurro.A300,
        contained: {
            color: constants_1.COLORS.white,
            backgroundColor: constants_1.COLORS.azzurro.A400,
            hover: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.azzurro.A500,
            },
            pressed: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.azzurro.A500,
            },
            disabled: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.azzurro.A100,
            },
        },
        outlined: {
            color: constants_1.COLORS.azzurro.A400,
            backgroundColor: constants_1.COLORS.white,
            borderColor: constants_1.COLORS.azzurro.A400,
            hover: {
                color: constants_1.COLORS.azzurro.A400,
                backgroundColor: constants_1.COLORS.azzurro.A100,
            },
            pressed: {
                color: constants_1.COLORS.azzurro.A400,
                backgroundColor: constants_1.COLORS.azzurro.A100,
            },
            disabled: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.azzurro.A100,
                borderColor: constants_1.COLORS.azzurro.A400,
            },
        },
        text: {
            color: constants_1.COLORS.azzurro.A400,
            backgroundColor: constants_1.COLORS.white,
            hover: {
                color: constants_1.COLORS.azzurro.A400,
                backgroundColor: constants_1.COLORS.azzurro.A100,
            },
            pressed: {
                color: constants_1.COLORS.azzurro.A500,
                backgroundColor: constants_1.COLORS.azzurro.A100,
            },
            disabled: {
                color: constants_1.COLORS.azzurro.A100,
                backgroundColor: constants_1.COLORS.white,
            },
        },
    },
    isSubmitted: {
        main: constants_1.COLORS.azzurro.A400,
        dark: constants_1.COLORS.azzurro.A500,
        light: constants_1.COLORS.azzurro.A100,
        contained: {
            color: constants_1.COLORS.white,
            backgroundColor: constants_1.COLORS.azzurro.A400,
            hover: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.azzurro.A400,
            },
            pressed: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.azzurro.A400,
            },
            disabled: {
                color: constants_1.COLORS.white,
                backgroundColor: constants_1.COLORS.azzurro.A400,
            },
        },
        outlined: {
            color: constants_1.COLORS.azzurro.A400,
            backgroundColor: constants_1.COLORS.white,
            hover: {
                color: constants_1.COLORS.azzurro.A400,
                borderColor: constants_1.COLORS.azzurro.A400,
                backgroundColor: constants_1.COLORS.white,
            },
            pressed: {
                color: constants_1.COLORS.azzurro.A400,
                borderColor: constants_1.COLORS.azzurro.A100,
            },
            disabled: {
                color: constants_1.COLORS.azzurro.A400,
                backgroundColor: '#FFFFF',
                borderColor: constants_1.COLORS.azzurro.A400,
            },
        },
        text: {
            color: constants_1.COLORS.azzurro.A400,
            backgroundColor: constants_1.COLORS.white,
            hover: {
                color: constants_1.COLORS.azzurro.A400,
                borderColor: constants_1.COLORS.azzurro.A400,
                backgroundColor: '#FFFFF',
            },
            pressed: {
                color: constants_1.COLORS.azzurro.A400,
                backgroundColor: constants_1.COLORS.azzurro.A100,
            },
            disabled: {
                color: constants_1.COLORS.azzurro.A400,
                backgroundColor: '#FFFFF',
            },
        },
    },
};
exports.default = BUTTON_COLOR_MAPPER;
