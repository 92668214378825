"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = void 0;
var material_1 = require("@mui/material");
exports.Footer = (0, material_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(2, 4, 2, 2)
        },
        _b[theme.breakpoints.down('md')] = {
            width: '100%',
        },
        _b);
});
