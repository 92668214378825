"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SIZE_MAPPER = void 0;
exports.SIZE_MAPPER = {
    small: {
        fontSize: 14,
        height: 14,
        lineHeight: '20px',
    },
    medium: {
        fontSize: 16,
        height: 16,
        lineHeight: '24px',
    },
};
