"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RECOMMENDATIONS_STATUS = exports.RECOMMENDATIONS_TYPE = void 0;
var RECOMMENDATIONS_TYPE;
(function (RECOMMENDATIONS_TYPE) {
    // PAPR = 'Partnership Price',
    // M2Y = 'M-to-Y Billing',
    // DORM = 'Dormant Users',
    // USUB = 'Unused subscriptions',
    RECOMMENDATIONS_TYPE["FX"] = "Forex Costs";
    // ALT = 'Offer Alternative Products',
    RECOMMENDATIONS_TYPE["NEGOT"] = "Negotiate for higher expenditure items";
})(RECOMMENDATIONS_TYPE = exports.RECOMMENDATIONS_TYPE || (exports.RECOMMENDATIONS_TYPE = {}));
var RECOMMENDATIONS_STATUS;
(function (RECOMMENDATIONS_STATUS) {
    RECOMMENDATIONS_STATUS["DEFERRED"] = "Deferred";
    RECOMMENDATIONS_STATUS["REJECTED"] = "Rejected";
    RECOMMENDATIONS_STATUS["USED"] = "Used";
})(RECOMMENDATIONS_STATUS = exports.RECOMMENDATIONS_STATUS || (exports.RECOMMENDATIONS_STATUS = {}));
