"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var MailContentWrapper = (0, material_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return ({
        backgroundColor: (_b = theme.palette.neutral) === null || _b === void 0 ? void 0 : _b.light,
        borderRadius: 0.67,
        padding: theme.spacing(1, 2),
        marginTop: theme.spacing(0.5),
        width: 600,
        textIndent: theme.spacing(0.25),
    });
});
exports.default = MailContentWrapper;
