"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldProcessors = void 0;
var utils_1 = require("../utils");
exports.FieldProcessors = {
    forecastSpend: utils_1.getForecast,
    serviceName: function (sS) { var _a, _b; return ((_a = sS.service) === null || _a === void 0 ? void 0 : _a.niceName) || ((_b = sS.service) === null || _b === void 0 ? void 0 : _b.name); },
    change: function (sS) {
        var _a, _b;
        return ((_a = sS.toolAnalysis) === null || _a === void 0 ? void 0 : _a.last12MonthSpend)
            ? 1 - ((0, utils_1.getForecast)(sS) || 0) / ((_b = sS.toolAnalysis) === null || _b === void 0 ? void 0 : _b.last12MonthSpend)
            : 0;
    },
};
