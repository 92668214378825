"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iconButtonStyles = void 0;
var iconButtonStyles = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var theme = _a.theme, ownerState = _a.ownerState;
    return ({
        '& svg': {
            margin: 0,
        },
        padding: theme.spacing(0.5),
        border: '1px solid transparent',
        background: (_c = (_b = theme.palette[ownerState.color]) === null || _b === void 0 ? void 0 : _b[ownerState.variant]) === null || _c === void 0 ? void 0 : _c.backgroundColor,
        color: (_e = (_d = theme.palette[ownerState.color]) === null || _d === void 0 ? void 0 : _d[ownerState.variant]) === null || _e === void 0 ? void 0 : _e.color,
        ':hover': {
            background: (_g = (_f = theme.palette[ownerState.color]) === null || _f === void 0 ? void 0 : _f.outlined) === null || _g === void 0 ? void 0 : _g.hover.backgroundColor,
            color: (_h = theme.palette[ownerState.color]) === null || _h === void 0 ? void 0 : _h.dark,
            borderColor: (_j = theme.palette[ownerState.color]) === null || _j === void 0 ? void 0 : _j.main,
        },
        ':disabled': {
            background: (_l = (_k = theme.palette[ownerState.color]) === null || _k === void 0 ? void 0 : _k[ownerState.variant]) === null || _l === void 0 ? void 0 : _l.disabled.backgroundColor,
            color: (_o = (_m = theme.palette[ownerState.color]) === null || _m === void 0 ? void 0 : _m[ownerState.variant]) === null || _o === void 0 ? void 0 : _o.disabled.color,
        },
        ':focus-visible': theme.focusVisibleStyles,
    });
};
exports.iconButtonStyles = iconButtonStyles;
