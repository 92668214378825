"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.quoteReportDummy2 = exports.quoteReportDummy = void 0;
exports.quoteReportDummy = {
    id: 'DUMMY_AUDIT',
    // serviceId: 'bLeYfME82Bm9Sayrhpt6',
    serviceData: { name: 'HubSpot', logoUrl: 'https://storage.googleapis.com/hudled-5cfe5.appspot.com/images/logos/hubspot.png' },
    // creatorId: 'NONE',
    quoteReportIds: ['DUMMY_REPORT_1'],
    quoteReportsMap: {
        DUMMY_REPORT_1: {
            reportRowsMap: {
                P1: {
                    name: 'Marketing Hub Professional',
                    description: '3 Core Seats Included;2,000 Marketing Contacts Included',
                    billingCycle: { months: 12 },
                    listPrice: 15352,
                    currency: 'AUD',
                    vendorQuote: 7949,
                },
                P2: {
                    name: '10,000 Additional Marketing Contacts',
                    listPrice: 9000,
                    billingCycle: { months: 12 },
                    currency: 'AUD',
                    vendorQuote: 8089,
                },
                P3: {
                    name: 'Sales Hub Professional',
                    description: '10 users included',
                    listPrice: 20760,
                    billingCycle: { months: 12 },
                    currency: 'AUD',
                    vendorQuote: 20760,
                },
                P4: {
                    name: 'Service Hub Professional',
                    description: '5 users included',
                    listPrice: 8640,
                    billingCycle: { months: 12 },
                    currency: 'AUD',
                    vendorQuote: 4474,
                },
                P5: {
                    name: 'Additional Users Service Hub Professional',
                    description: '3 users included',
                    listPrice: 4860,
                    billingCycle: { months: 12 },
                    currency: 'AUD',
                },
                P6: {
                    name: 'CMS Hub Professional',
                    listPrice: 6900,
                    billingCycle: { months: 12 },
                    currency: 'AUD',
                    vendorQuote: 3573,
                },
                P7: {
                    name: 'Operations Hub Professional',
                    listPrice: 13824,
                    billingCycle: { months: 12 },
                    currency: 'AUD',
                },
            },
            planIds: ['P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7'],
        },
    },
};
exports.quoteReportDummy2 = {
    id: 'DUMMY_AUDIT',
    // serviceId: 'bLeYfME82Bm9Sayrhpt6',
    serviceData: { name: 'HubSpot', logoUrl: 'https://storage.googleapis.com/hudled-5cfe5.appspot.com/images/logos/hubspot.png' },
    // creatorId: 'NONE',
    quoteReportIds: ['DUMMY_REPORT_1'],
    quoteReportsMap: {
        DUMMY_REPORT_1: {
            reportRowsMap: {
                P1: {
                    name: 'Marketing Hub Professional',
                    description: '3 Core Seats Included;2,000 Marketing Contacts Included',
                    billingCycle: { months: 1 },
                    listPrice: 15352 / 12,
                    vendorQuote: 7949 / 12,
                    currency: 'AUD',
                    benchmarks: {
                        min: 0.0, median: 0.55, max: 0.90, count: 199,
                    },
                },
                P2: {
                    name: '10,000 Additional Marketing Contacts',
                    billingCycle: { months: 1 },
                    listPrice: 9000 / 12,
                    vendorQuote: 7089 / 12,
                    currency: 'AUD',
                    benchmarks: {
                        min: 0.0, median: 0.6, max: 0.9, count: 85,
                    },
                },
                P3: {
                    name: 'Sales Hub Professional',
                    description: '10 users included',
                    billingCycle: { months: 1 },
                    listPrice: 20760 / 12,
                    vendorQuote: 16760 / 12,
                    currency: 'AUD',
                    benchmarks: {
                        min: 0, median: 0.44, max: 0.7, count: 85,
                    },
                },
                P4: {
                    name: 'Service Hub Professional',
                    description: '5 users included',
                    billingCycle: { months: 1 },
                    listPrice: 8640 / 12,
                    vendorQuote: 4474 / 12,
                    currency: 'AUD',
                    benchmarks: {
                        min: 0.2, median: 0.44, max: 0.73, count: 85,
                    },
                },
                P5: {
                    name: 'Additional Users Service Hub',
                    description: '3 users included',
                    billingCycle: { months: 1 },
                    listPrice: 4860 / 12,
                    vendorQuote: 3374 / 12,
                    currency: 'AUD',
                    benchmarks: {
                        min: 0.2, median: 0.44, max: 0.73, count: 85,
                    },
                },
                P6: {
                    name: 'CMS Hub Professional',
                    billingCycle: { months: 1 },
                    listPrice: 6900 / 12,
                    vendorQuote: 3573 / 12,
                    currency: 'AUD',
                    benchmarks: {
                        min: 0.15, median: 0.51, max: 0.65, count: 85,
                    },
                },
                P7: {
                    name: 'Operations Hub Professional',
                    billingCycle: { months: 1 },
                    listPrice: 13824 / 12,
                    vendorQuote: 7158 / 12,
                    currency: 'AUD',
                    benchmarks: {
                        min: 0.0, median: 0.25, max: 0.65, count: 23,
                    },
                },
                OTF1: {
                    name: 'Marketing Hub - Onboarding',
                    description: 'We will help you set up Marketing Hub and provide advice on using it to grow your business',
                    listPrice: 4320,
                    vendorQuote: 4320 / 2,
                    currency: 'AUD',
                    benchmarks: {
                        min: 0, median: 0.33, max: 1, count: 9,
                    },
                },
                OTF2: {
                    name: 'Sales Hub - Onboarding',
                    description: 'We will help you set up Sales Hub and provide advice on using it to simplify and scale your sales process.',
                    listPrice: 4320,
                    vendorQuote: 4320 / 2,
                    currency: 'AUD',
                    benchmarks: {
                        min: 0, median: 0.22, max: 1, count: 9,
                    },
                },
                OTF3: {
                    name: 'Operations Hub - Onboarding',
                    description: 'We will help you set up Operations Hub and provide advice on using it.',
                    listPrice: 7500,
                    vendorQuote: 3750,
                    currency: 'AUD',
                    benchmarks: {
                        min: 0, median: 0.22, max: 1, count: 9,
                    },
                },
            },
            planIds: ['P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7'],
            oneTimeFeeIds: ['OTF1', 'OTF2', 'OTF3'],
        },
    },
};
