"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var faSpinnerThird_1 = require("@fortawesome/pro-duotone-svg-icons/faSpinnerThird");
var faCheckCircle_1 = require("@fortawesome/pro-solid-svg-icons/faCheckCircle");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var react_router_dom_1 = require("react-router-dom");
var general_1 = require("utils/general");
var SIZE_MAPPER = {
    small: 12, medium: 16, large: 18,
};
var SpinningIcon = (0, styles_1.styled)(react_fontawesome_1.FontAwesomeIcon, { shouldForwardProp: (0, general_1.omitProps)('btnSize') })(function (_a) {
    var btnSize = _a.btnSize;
    return (__assign({ position: 'absolute', top: 'calc(50% - 0.5em)', left: 'calc(50% - 0.5em)', zIndex: 2, '&.fa-spin': {
            animation: 'fa-spin 0.75s infinite linear',
        } }, (btnSize && SIZE_MAPPER[btnSize] && {
        width: SIZE_MAPPER[btnSize],
        height: SIZE_MAPPER[btnSize],
    })));
});
var Button = function (_a) {
    var children = _a.children, icon = _a.icon, url = _a.url, _b = _a.iconSize, iconSize = _b === void 0 ? 'sm' : _b, _c = _a.spinIcon, spinIcon = _c === void 0 ? false : _c, targetUrl = _a.targetUrl, _d = _a.isSending, isSending = _d === void 0 ? false : _d, _e = _a.isSubmitted, isSubmitted = _e === void 0 ? false : _e, props = __rest(_a, ["children", "icon", "url", "iconSize", "spinIcon", "targetUrl", "isSending", "isSubmitted"]);
    return ((0, jsx_runtime_1.jsxs)(material_1.Button, __assign({ variant: "contained", startIcon: (isSubmitted || icon) && ((0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: (!isSubmitted && icon) || faCheckCircle_1.faCheckCircle, size: iconSize, spin: !isSubmitted && spinIcon })), component: url ? react_router_dom_1.Link : 'button', to: url && { pathname: url }, target: targetUrl !== null && targetUrl !== void 0 ? targetUrl : (props.href ? '_blank' : undefined), href: url, type: "button" }, props, { disabled: isSending || isSubmitted || props.disabled, color: isSubmitted ? 'isSubmitted' : props.color }, { children: [children, isSending && (0, jsx_runtime_1.jsx)(SpinningIcon, { btnSize: props.size, icon: faSpinnerThird_1.faSpinnerThird, spin: true })] })));
};
exports.default = Button;
