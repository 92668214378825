"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var IconButtons_1 = require("atomicComponents/molecules/IconButtons/IconButtons");
var hooks_1 = require("utils/hooks");
var UnlinkTransactionBtn = function (_a) {
    var item = _a.item;
    var _b = __read((0, hooks_1.useSubmitter)('transactions', item.isLineItem ? item.tId : item.id), 2), updateTransaction = _b[0], isSending = _b[1];
    var setIsHidden = function (isHidden) { return updateTransaction({ isHidden: isHidden }); };
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: item.isHidden
            ? (0, jsx_runtime_1.jsx)(IconButtons_1.UndoButton, { onClick: function () { return setIsHidden(!item.isHidden); } })
            : (0, jsx_runtime_1.jsx)(IconButtons_1.RemoveButton, { onClick: function () { return setIsHidden(!item.isHidden); }, disabled: isSending, tooltipTitle: "Unlink transaction" }) }));
};
exports.default = UnlinkTransactionBtn;
