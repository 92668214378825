"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var OrderedListWrapper = (0, material_1.styled)(material_1.Box)(function (_a) {
    var theme = _a.theme;
    return ({
        paddingTop: theme.spacing(2),
        '& ol': {
            counterReset: 'list-counter',
            listStyle: 'none',
            paddingLeft: theme.spacing(5),
            position: 'relative',
        },
        '& ol > li': {
            counterIncrement: 'list-counter',
            position: 'relative',
        },
        '& ol > li::before': {
            content: 'counters(list-counter, ".") "."',
            position: 'absolute',
            left: -24,
            textAlign: 'right',
        },
        '& ol ol': {
            counterReset: 'list-counter',
        },
        '& ol ol > li': {
            counterIncrement: 'list-counter',
        },
        '& ol ol > li::before': {
            content: 'counters(list-counter, ".")',
            position: 'absolute',
            left: -40,
            textAlign: 'right',
        },
        '& ol ol ol': {
            listStyle: 'lower-alpha',
            paddingLeft: theme.spacing(2),
        },
        '& ol ol ol > li::before': {
            content: 'counter(list-item, lower-alpha) "."',
            position: 'absolute',
            left: -16,
            textAlign: 'right',
        },
        '& ol ol ol > li': {
            listStyle: 'none',
            position: 'relative',
        },
    });
});
exports.default = OrderedListWrapper;
