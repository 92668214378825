"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
exports.default = (0, material_1.styled)(material_1.Box)(function (_a) {
    var theme = _a.theme;
    return ({
        margin: theme.spacing(3, 0),
        padding: theme.spacing(3, 3),
        borderRadius: theme.shape.borderRadius,
        boxShadow: '1px 15px 35px 1px rgb(53 57 78 / 10%)',
        textAlign: 'center',
        alignItems: 'center',
        background: 'white',
        width: '100%',
        maxWidth: 400,
        '& > h1, > p': {
            padding: theme.spacing(1, 0),
        },
    });
});
