"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var TextDivider = (0, styles_1.styled)('span')(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({}, theme.typography.caption), { width: '100%', lineHeight: '0.1em', display: 'flex', alignItems: 'center', textAlign: 'center', '&::before, &::after': {
            content: '""',
            flex: 1,
            borderBottom: "1px solid ".concat(theme.palette.grey[600]),
        }, '&:not(:empty)::before': {
            marginRight: theme.spacing(1.25),
        }, '&:not(:empty)::after': {
            marginLeft: theme.spacing(1.25),
        } }));
});
exports.default = TextDivider;
