"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var Wrapper = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(10, 2.5),
        maxWidth: 700,
        margin: 'auto',
        position: 'relative',
    });
});
exports.default = Wrapper;
