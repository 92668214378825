"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var AccordionDetails = (0, styles_1.styled)(material_1.AccordionDetails)(function (_a) {
    var fontSize = _a.fontSize;
    return ({
        paddingBottom: 0,
        borderRadius: 0,
        transition: 'none',
        fontSize: fontSize,
    });
});
exports.default = AccordionDetails;
