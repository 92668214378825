"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Root = exports.classes = void 0;
var styles_1 = require("@mui/material/styles");
var PREFIX = 'Unlock';
exports.classes = {
    wrapper: "".concat(PREFIX, "-wrapper"),
    unlockDeal: "".concat(PREFIX, "-unlockDeal"),
    button: "".concat(PREFIX, "-button"),
    textarea: "".concat(PREFIX, "-textarea"),
    amount: "".concat(PREFIX, "-amount"),
    grayedOut: "".concat(PREFIX, "-grayedOut"),
    code: "".concat(PREFIX, "-code"),
    hidden: "".concat(PREFIX, "-hidden"),
};
exports.Root = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            position: 'sticky',
            top: 0
        },
        _b[".".concat(exports.classes.unlockDeal)] = {
            boxShadow: '0px 3px 8px rgba(0,0,0,0.12)',
            borderRadius: 10,
        },
        _b[".".concat(exports.classes.button)] = {
            width: '100%',
            marginTop: theme.spacing(4),
        },
        _b[".".concat(exports.classes.textarea)] = {
            width: '100%',
            marginTop: theme.spacing(3),
            borderBottom: '1px solid black',
            borderBottomColor: theme.palette.secondary.main,
            '&:focus': {
                outline: 'None',
            },
        },
        _b[".".concat(exports.classes.amount)] = {
            fontWeight: theme.typography.fontWeightMedium,
            '&.success': {
                color: theme.palette.success.dark,
            },
        },
        _b[".".concat(exports.classes.grayedOut)] = {
            color: theme.palette.grey[600],
            '& *': { color: theme.palette.grey[600] },
        },
        _b[".".concat(exports.classes.code)] = {
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.grey.A100,
            color: theme.palette.riverstone,
            padding: theme.spacing(1, 2),
            borderRadius: theme.spacing(1),
        },
        _b[".".concat(exports.classes.hidden)] = { display: 'none' },
        _b);
});
