"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOpenCheckout = exports.useFetchSubscriptionForCompany = exports.chargebeeCheckoutFn = exports.getCbInstanceAsync = void 0;
var end2end_1 = require("@hudled/end2end");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var backend_1 = require("utils/backend");
var constants_1 = require("./constants");
var logging_1 = require("./logging");
var single_1 = require("./selectors/single");
// TODO: This is the real chargebee account -> use this when we deploy/go live.
var getCbInstance = function () { var _a; return (_a = window.Chargebee) === null || _a === void 0 ? void 0 : _a.init({ site: constants_1.FB_ENV_FE === 'prod' ? 'hudled' : 'hudled-test' }); };
var getCbInstanceAsync = function () { return __awaiter(void 0, void 0, void 0, function () {
    var cbInstance, head, script, i;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                cbInstance = getCbInstance();
                if (cbInstance)
                    return [2 /*return*/, cbInstance];
                head = document.head || document.getElementsByTagName('head')[0];
                script = document.createElement('script');
                script.src = 'https://js.chargebee.com/v2/chargebee.js';
                script.setAttribute('data-cb-site', 'hudled');
                head.appendChild(script);
                return [4 /*yield*/, new Promise(function (resolve) {
                        script.addEventListener('load', resolve);
                    })];
            case 1:
                _a.sent();
                i = 0;
                _a.label = 2;
            case 2:
                if (!(i < 50)) return [3 /*break*/, 5];
                cbInstance = getCbInstance();
                if (cbInstance)
                    return [2 /*return*/, cbInstance];
                // eslint-disable-next-line no-await-in-loop
                return [4 /*yield*/, (0, end2end_1.delay)(50)];
            case 3:
                // eslint-disable-next-line no-await-in-loop
                _a.sent();
                _a.label = 4;
            case 4:
                i++;
                return [3 /*break*/, 2];
            case 5: return [2 /*return*/, cbInstance];
        }
    });
}); };
exports.getCbInstanceAsync = getCbInstanceAsync;
var chargebeeCheckoutFn = function (backendFnName, params) { return __awaiter(void 0, void 0, void 0, function () {
    var cbInstance;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, exports.getCbInstanceAsync)()];
            case 1:
                cbInstance = _a.sent();
                cbInstance.openCheckout({
                    hostedPage: function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var result, data;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, (0, backend_1.callBackendFn)(backendFnName, params)];
                                    case 1:
                                        result = _a.sent();
                                        data = result.data;
                                        return [2 /*return*/, data];
                                }
                            });
                        });
                    },
                    success: function (hostedPageId) {
                        (0, logging_1.logInfo)(hostedPageId);
                    },
                    close: function () {
                        (0, logging_1.logInfo)('checkout new closed');
                    },
                    step: function (step) {
                        (0, logging_1.logInfo)('checkout', step);
                    },
                });
                return [2 /*return*/];
        }
    });
}); };
exports.chargebeeCheckoutFn = chargebeeCheckoutFn;
var useFetchSubscriptionForCompany = function () {
    var company = (0, react_redux_1.useSelector)(single_1.selectMyCompany);
    return (0, backend_1.useCall)('payments-retrieveSubscription', { id: company === null || company === void 0 ? void 0 : company.id });
};
exports.useFetchSubscriptionForCompany = useFetchSubscriptionForCompany;
var useOpenCheckout = function () {
    var _a = __read((0, react_1.useState)(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var company = (0, react_redux_1.useSelector)(single_1.selectMyCompany);
    var profile = (0, react_redux_1.useSelector)(single_1.selectProfileWithId);
    var _b = __read((0, exports.useFetchSubscriptionForCompany)(), 2), fetchSubscription = _b[0], isFetchingSubscription = _b[1];
    return [
        function (plan, planFrequency) { return (0, exports.getCbInstanceAsync)().then(function (cbInstance) { return cbInstance.openCheckout({
            hostedPage: function () {
                return __awaiter(this, void 0, void 0, function () {
                    var result;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                setIsLoading(true);
                                return [4 /*yield*/, (0, backend_1.callBackendFn)('payments-generateCheckoutUrl', {
                                        planId: "".concat(plan, "-USD-").concat(planFrequency),
                                        userId: profile.id,
                                        email: profile === null || profile === void 0 ? void 0 : profile.email,
                                        companyName: company === null || company === void 0 ? void 0 : company.name,
                                        companyId: profile === null || profile === void 0 ? void 0 : profile.companyId,
                                        firstName: profile === null || profile === void 0 ? void 0 : profile.firstName,
                                        lastName: profile === null || profile === void 0 ? void 0 : profile.lastName,
                                    })];
                            case 1:
                                result = _a.sent();
                                setIsLoading(false);
                                return [2 /*return*/, result.data];
                        }
                    });
                });
            },
            close: function () {
                fetchSubscription()();
            },
        }); }); },
        isLoading || isFetchingSubscription,
    ];
};
exports.useOpenCheckout = useOpenCheckout;
