"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMinimumUnits = exports.getQuoteReport = exports.getTierNiceName = exports.getFamilyNiceName = exports.getPriceForRow = exports.getRowAddOn = exports.getRowFamilyAndTier = exports.getAddOnPriceEntry = exports.getMonthlyPrice = exports.getPriceEntry = void 0;
var lodash_1 = require("lodash");
var fields = ['family', 'tier', 'billingCycle', 'renewalFrequency'];
var getPriceEntry = function (srvPricePlans) { return function (item) { return item.family && item.tier
    ? srvPricePlans.pricingPlans.find(function (pP) { return (0, lodash_1.isEqual)((0, lodash_1.pick)(pP, fields), (0, lodash_1.pick)(item, fields)); })
    : undefined; }; };
exports.getPriceEntry = getPriceEntry;
var getMonthlyPrice = function (pricingEntry, planTier, units) {
    var e_1, _a;
    var _b, _c;
    if (!pricingEntry || !planTier)
        return undefined;
    var pricingModel = planTier.pricingModel, unitAmount = planTier.unitAmount;
    if (pricingModel === 'flat_fee')
        return ((_c = (_b = pricingEntry.priceTiers[0]) === null || _b === void 0 ? void 0 : _b.price) !== null && _c !== void 0 ? _c : 0) / pricingEntry.billingCycle.months;
    if (!units)
        return undefined;
    var priceTiers = pricingEntry.priceTiers;
    priceTiers.sort(function (tierA, tierB) { return tierA.startUnit - tierB.startUnit; });
    if (pricingModel === 'tiered' || pricingModel === 'per_unit') {
        var total = 0;
        try {
            for (var _d = __values(pricingEntry.priceTiers), _e = _d.next(); !_e.done; _e = _d.next()) {
                var priceTier = _e.value;
                if (priceTier.endUnit && priceTier.endUnit < units) {
                    total += Math.ceil((priceTier.endUnit - (priceTier.startUnit - 1)) / (unitAmount !== null && unitAmount !== void 0 ? unitAmount : 1)) * priceTier.price;
                }
                else {
                    total += Math.ceil((units - (priceTier.startUnit - 1)) / (unitAmount !== null && unitAmount !== void 0 ? unitAmount : 1)) * priceTier.price;
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return total / pricingEntry.billingCycle.months;
    }
    return undefined;
};
exports.getMonthlyPrice = getMonthlyPrice;
var addOnFields = ['addOn', 'billingCycle', 'renewalFrequency'];
var getAddOnPriceEntry = function (srvPricePlans) { return function (item) {
    var _a;
    return item.addOn
        ? (_a = srvPricePlans.addOnPlans) === null || _a === void 0 ? void 0 : _a.find(function (pP) { return (0, lodash_1.isEqual)((0, lodash_1.pick)(pP, addOnFields), (0, lodash_1.pick)(item, addOnFields)); })
        : undefined;
}; };
exports.getAddOnPriceEntry = getAddOnPriceEntry;
var getRowFamilyAndTier = function (srvPricePlans) { return function (item) {
    var family = item.family ? srvPricePlans.familiesMap[item.family] : undefined;
    var tier = family && item.tier ? family.tiersMap[item.tier] : undefined;
    return { family: family, tier: tier };
}; };
exports.getRowFamilyAndTier = getRowFamilyAndTier;
var getRowAddOn = function (srvPricePlans) { return function (item) { var _a; return item.addOn ? (_a = srvPricePlans === null || srvPricePlans === void 0 ? void 0 : srvPricePlans.addOnsMap) === null || _a === void 0 ? void 0 : _a[item.addOn] : undefined; }; };
exports.getRowAddOn = getRowAddOn;
var getPriceForRow = function (srvPricePlans) { return function (item) {
    var _a, _b;
    var planTierOrAddOn;
    var pricingEntry;
    if ('addOn' in item) {
        planTierOrAddOn = (0, exports.getRowAddOn)(srvPricePlans)(item);
        pricingEntry = (0, exports.getAddOnPriceEntry)(srvPricePlans)(item);
    }
    else if ('family' in item) {
        planTierOrAddOn = (_a = (0, exports.getRowFamilyAndTier)(srvPricePlans)(item)) === null || _a === void 0 ? void 0 : _a.tier;
        pricingEntry = (0, exports.getPriceEntry)(srvPricePlans)(item);
    }
    return (_b = (0, exports.getMonthlyPrice)(pricingEntry, planTierOrAddOn, item.units)) !== null && _b !== void 0 ? _b : 0;
}; };
exports.getPriceForRow = getPriceForRow;
var getFamilyNiceName = function (srvPricePlans) { return function (family) { var _a, _b; return (_b = (_a = srvPricePlans.familiesMap[family]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : family; }; };
exports.getFamilyNiceName = getFamilyNiceName;
var getTierNiceName = function (srvPricePlans) { return function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var family = _a.family, tier = _a.tier;
    return (_d = (_c = (_b = srvPricePlans.familiesMap[family]) === null || _b === void 0 ? void 0 : _b.tiersMap[tier]) === null || _c === void 0 ? void 0 : _c.fullName) !== null && _d !== void 0 ? _d : "".concat((0, exports.getFamilyNiceName)(srvPricePlans)(family), " ").concat((_g = (_f = (_e = srvPricePlans.familiesMap[family]) === null || _e === void 0 ? void 0 : _e.tiersMap[tier]) === null || _f === void 0 ? void 0 : _f.name) !== null && _g !== void 0 ? _g : tier);
}; };
exports.getTierNiceName = getTierNiceName;
var getReportRow = function (srvPricePlans) { return function (pricingPlanRow, currency) {
    if (currency === void 0) { currency = 'AUD'; }
    return (__assign(__assign({ listPrice: (0, exports.getPriceForRow)(srvPricePlans)(pricingPlanRow), currency: currency }, (0, lodash_1.pick)(pricingPlanRow, ['billingCycle', 'renewalFrequency', 'vendorQuote'])), (0, lodash_1.pick)((0, exports.getRowFamilyAndTier)(srvPricePlans)(pricingPlanRow).tier, ['description', 'benchmarks'])));
}; };
var getPlanReportRow = function (srvPricePlans) { return function (pricingPlanRow, currency) {
    if (currency === void 0) { currency = 'AUD'; }
    return (__assign({ name: (0, exports.getTierNiceName)(srvPricePlans)(pricingPlanRow) }, getReportRow(srvPricePlans)(pricingPlanRow, currency)));
}; };
var getOneTimeFeeRow = function (srvPricePlans) { return function (pricingPlanRow, currency) {
    var _a, _b;
    if (currency === void 0) { currency = 'AUD'; }
    var oneTimeFee = (_a = (0, exports.getRowFamilyAndTier)(srvPricePlans)(pricingPlanRow).tier) === null || _a === void 0 ? void 0 : _a.oneTimeFee;
    if (!oneTimeFee)
        return null;
    return (0, lodash_1.omitBy)({
        name: (_b = oneTimeFee.name) !== null && _b !== void 0 ? _b : "One time fee for ".concat((0, exports.getTierNiceName)(srvPricePlans)(pricingPlanRow)),
        currency: currency,
        listPrice: oneTimeFee.price,
        benchmarks: oneTimeFee.benchmarks,
        description: oneTimeFee.description,
        vendorQuote: pricingPlanRow.oneTimeQuote,
    }, function (v) { return v === undefined; });
}; };
var getAddOnReportRow = function (srvPricePlans) { return function (addOnRow, currency) {
    var _a, _b, _c;
    if (currency === void 0) { currency = 'AUD'; }
    return (__assign({ name: (_c = (_b = (_a = srvPricePlans.addOnsMap) === null || _a === void 0 ? void 0 : _a[addOnRow.addOn]) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : addOnRow.addOn }, getReportRow(srvPricePlans)(addOnRow, currency)));
}; };
var hasPlanTierIdentifiers = function (pricingPlanRow) { return !!(pricingPlanRow.tier && pricingPlanRow.family); };
var hasAddOnIdentifiers = function (addOnRow) { return !!addOnRow.addOn; };
var getQuoteReport = function (srvPricePlans) { return function (quoteAnaliysis) {
    var reportRowsMap = {};
    var planIds = [];
    var oneTimeFeeIds = [];
    var addOnIds = [];
    /*
      planIds?: string[];
      addOnIds?: string[];
      oneTimeFeeIds?: string[];
      isPublic?: boolean;
    **/
    quoteAnaliysis.pricingPlanRows.forEach(function (pricingPlanRow) {
        if (!hasPlanTierIdentifiers(pricingPlanRow))
            return;
        var planId = "P".concat(pricingPlanRow.id);
        reportRowsMap[planId] = getPlanReportRow(srvPricePlans)(pricingPlanRow);
        planIds.push(planId);
        var oneTimeFeeRow = getOneTimeFeeRow(srvPricePlans)(pricingPlanRow);
        if (oneTimeFeeRow) {
            var oTFId = "OTF".concat(pricingPlanRow.id);
            oneTimeFeeIds.push(oTFId);
            reportRowsMap[oTFId] = oneTimeFeeRow;
        }
    });
    quoteAnaliysis.addOnRows.forEach(function (addOnRow) {
        if (!hasAddOnIdentifiers(addOnRow))
            return;
        var addOnId = "A".concat(addOnRow.id);
        reportRowsMap[addOnId] = getAddOnReportRow(srvPricePlans)(addOnRow);
        addOnIds.push(addOnId);
    });
    return {
        reportRowsMap: reportRowsMap,
        oneTimeFeeIds: oneTimeFeeIds,
        planIds: planIds,
        addOnIds: addOnIds,
        // plansReportRowsMap: Object.fromEntries(quoteAnaliysis.pricingPlanRows.map)
    };
}; };
exports.getQuoteReport = getQuoteReport;
var getMinimumUnits = function (pricingEntry, planTier) {
    var _a;
    if (!planTier || !pricingEntry) {
        return undefined;
    }
    if (planTier.allowsZero) {
        return 0;
    }
    if (planTier.pricingModel === 'per_unit') {
        return (_a = planTier.unitAmount) !== null && _a !== void 0 ? _a : 1;
    }
    var baseTierEnd = pricingEntry.priceTiers[0].endUnit;
    if (planTier.pricingModel === 'stair_step'
        || (planTier.pricingModel === 'tiered' && baseTierEnd && planTier.unitAmount && planTier.unitAmount > baseTierEnd)) {
        return baseTierEnd;
    }
    return undefined;
};
exports.getMinimumUnits = getMinimumUnits;
