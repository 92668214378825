"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetColor = exports.getBgLabelStyleObject = exports.getBgLabelsStyle = exports.getLabelStyleObject = exports.getLabelsStyle = exports.getStyle = exports.COLOR_MAPPER = exports.SIZE_MAPPER = exports.BAR_STYLES_MAPPER = void 0;
var lodash_1 = require("lodash");
exports.BAR_STYLES_MAPPER = {
    bar: { fill: 'main', label: { fill: 'main', background: { padding: 3, fill: 'transparent' } } },
    medianBar: { fill: 'secondary', hasStrokeWidth: true, label: { fill: '#FFF' } },
    markOverlay: { fill: 'neutral', label: { fill: 'main' } },
    mark: { fill: '#FFF', hasStrokeWidth: true, label: { fill: '#FFF', background: { padding: 3, fill: 'main' } } },
    min: {
        fill: 'main',
        label: {
            fill: 'main',
            textAnchor: 'end',
            dx: -6,
            background: { padding: 3, fill: 'transparent' },
        },
    },
};
exports.SIZE_MAPPER = {
    sm: {
        cornerRadius: 3,
        barWidth: 24,
        height: 80,
        width: 300,
        sidePaddings: 50,
        fontSize: 12,
        dotStrokeWidth: 2,
        dotSize: 4,
        strokeWidth: 3,
    },
    lg: {
        width: 810,
        height: 186,
        barWidth: 56,
        sidePaddings: 120,
        cornerRadius: 4,
        fontSize: 24,
        dotStrokeWidth: 3,
        dotSize: 7,
        strokeWidth: 5,
    },
};
exports.COLOR_MAPPER = {
    percent: { main: '#A9B4BA', secondary: '#DBE0E2', neutral: 'rgba(255, 255, 255, 0.55)' },
    dollar: { main: '#23AFEE', secondary: '#B6E5F9', neutral: 'rgba(255, 255, 255, 0.55)' },
};
var getStyle = function (field, fback, postProcess) { return function (_a) {
    var _b;
    var datum = _a.datum;
    var styleProp = (_b = (0, lodash_1.get)(exports.BAR_STYLES_MAPPER[datum === null || datum === void 0 ? void 0 : datum.id], field)) !== null && _b !== void 0 ? _b : fback;
    return postProcess ? postProcess(styleProp) : styleProp;
}; };
exports.getStyle = getStyle;
var getLabelsStyle = function (field, fback) { return (0, exports.getStyle)("label.".concat(field), fback); };
exports.getLabelsStyle = getLabelsStyle;
var getLabelStyleObject = function (fields) { return Object.fromEntries(fields.map(function (f) { return [f, (0, exports.getLabelsStyle)(f)]; })); };
exports.getLabelStyleObject = getLabelStyleObject;
var getBgLabelsStyle = function (field, fback) { return (0, exports.getStyle)("label.background.".concat(field), fback); };
exports.getBgLabelsStyle = getBgLabelsStyle;
var getBgLabelStyleObject = function (fields) { return Object.fromEntries(fields.map(function (f) { return [f, (0, exports.getBgLabelsStyle)(f)]; })); };
exports.getBgLabelStyleObject = getBgLabelStyleObject;
var isMappedColor = function (color) { return ['main', 'secondary', 'neutral'].includes(color); };
var makeGetColor = function (type) { return function (color) { return isMappedColor(color)
    ? exports.COLOR_MAPPER[type][color]
    : color; }; };
exports.makeGetColor = makeGetColor;
