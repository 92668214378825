"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
exports.default = (0, material_1.styled)(material_1.Collapse)(function (_a) {
    var theme = _a.theme;
    return ({
        borderTop: "1px solid ".concat(theme.palette.grey[400]),
        borderBottom: "1px solid ".concat(theme.palette.grey[400]),
        '.MuiCollapse-wrapperInner': {
            padding: theme.spacing(2, 4),
        },
    });
});
