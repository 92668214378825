"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
exports.default = (0, styles_1.styled)('img')(function () { return ({
    height: 'auto',
    width: '100%',
    maxWidth: 750,
    objectFit: 'cover',
    objectPosition: 'center',
}); });
