"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMentionDataForService = exports.getMentionDataForUser = void 0;
var end2end_1 = require("@hudled/end2end");
var getMentionDataForUser = function (u) { return ({
    id: u.id,
    collection: 'users',
    name: (0, end2end_1.getDisplayName)(u),
    avatar: u.avatarUrl,
    link: '#',
}); };
exports.getMentionDataForUser = getMentionDataForUser;
var getMentionDataForService = function (s) { return ({
    id: s.id,
    collection: 'services',
    name: s.niceName || s.name,
    avatar: s.logoUrl,
    link: "/tools/".concat(s.metaCategoryId, "/").concat(s.slug),
}); };
exports.getMentionDataForService = getMentionDataForService;
