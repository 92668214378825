"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NUM_CURR_RE2 = exports.NUM_CURR_RE = exports.DATE_RANGE_PATTERNS = exports.PAIRS_SEPARATORS = exports.PARTS_2 = exports.PARTS = exports.M_WORD = exports.MONTHS = void 0;
exports.MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
exports.M_WORD = "(?<monthWord>".concat(exports.MONTHS.map(function (m) { return m.length === 3 ? m : "".concat(m.substring(0, 3), "(?:").concat(m.substring(3), ")?"); }).join('|'), ")\\.?");
exports.PARTS = {
    d: '(?<day>[0-2]?\\d|3[01])',
    dd: '(?<day>[0-2]\\d|3[01])',
    m: '(?<month>0?\\d|1[0-2])',
    mm: '(?<month>0\\d|1[0-2])',
    y: '(?<year>(20)?[0-3]\\d)',
    yy: '(?<year>20[0-3]\\d)',
    mW: exports.M_WORD,
};
exports.PARTS_2 = {
    mW: exports.M_WORD.replace('<monthWord>', '<monthWord2>'),
    d: exports.PARTS.d.replace('<day>', '<day2>'),
    yy: exports.PARTS.yy.replace('<year>', '<year2>'),
};
exports.PAIRS_SEPARATORS = new Set([
    '', '-', '–', '—', '&ndash;', '/', 'to', 'thru', 'through', 'until', 'till', 'and ends', '&', 'and', 'extends through',
]);
exports.DATE_RANGE_PATTERNS = {
    // eslint-disable-next-line max-len
    'd mW(, yy) - d mW, yy': new RegExp("\\b(?<dateStr>".concat(exports.PARTS.mW, " ?").concat(exports.PARTS.d, "(, ").concat(exports.PARTS.yy, ")? ?(").concat(__spreadArray([], __read(exports.PAIRS_SEPARATORS), false).join('|'), ") ?").concat(exports.PARTS_2.mW, " ?").concat(exports.PARTS_2.d, "[, ]*").concat(exports.PARTS_2.yy, ")(?!\\d)"), 'i'),
};
exports.NUM_CURR_RE = /^-?(?<currency>\D+?)(?<amount>\d+(,\d{3})*(\.\d+)?)$/;
exports.NUM_CURR_RE2 = /^-?(?<amount>\d+(,\d{3})*(\.\d+)?) ?(?<currency>\D+?)$/;
