"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iconButtonSectionDef = void 0;
exports.iconButtonSectionDef = [
    {
        heading: 'IconButton Types',
        description: 'We have a predefined set of IconButton types.',
        variations: [
            { component: 'DownloadInvoiceButton', noChildren: true },
            { component: 'ExpandMenuButton', noChildren: true },
            { component: 'RemoveButton', noChildren: true },
            { component: 'UndoButton', noChildren: true },
            { component: 'CloseButton', noChildren: true },
            { component: 'AddButton', noChildren: true },
            { component: 'DollarSignButton', noChildren: true },
        ],
    },
    {
        heading: 'IconButton Sizes',
        description: "Use the size prop to change the size of the IconButton and iconSize to change the size of the icon. \n    We added the iconSize prop to make it easier to change the size of the icon without changing the size of the IconButton.",
        variations: [
            { component: 'UndoButton', properties: [{ prop: 'size', v: 'small' }, { prop: 'iconSize', v: 14 }], noChildren: true },
            { component: 'UndoButton', noChildren: true },
            { component: 'UndoButton', properties: [{ prop: 'size', v: 'large' }, { prop: 'iconSize', v: 24 }], noChildren: true },
            { component: 'RemoveButton', properties: [{ prop: 'size', v: 'small' }, { prop: 'iconSize', v: 14 }], noChildren: true },
            { component: 'RemoveButton', noChildren: true },
            { component: 'RemoveButton', properties: [{ prop: 'size', v: 'large' }, { prop: 'iconSize', v: 28 }], noChildren: true },
        ],
    },
    {
        heading: 'IconButton Colors',
        description: 'Use the color prop to change the color of the icon.',
        variations: [
            { component: 'UndoButton', properties: [{ prop: 'color', v: 'primary' }], noChildren: true },
            { component: 'UndoButton', properties: [{ prop: 'color', v: 'neutral' }], noChildren: true },
            { component: 'UndoButton', properties: [{ prop: 'color', v: 'green' }], noChildren: true },
            { component: 'UndoButton', properties: [{ prop: 'color', v: 'red' }], noChildren: true },
            { component: 'UndoButton', properties: [{ prop: 'color', v: 'azzurro' }], noChildren: true },
        ],
    },
    {
        heading: 'neutralByDefault option',
        description: 'Use the neutralByDefault option if you want the icon to appear neutral by default but change color on hover.',
        variations: [
            { component: 'UndoButton', properties: [{ prop: 'color', v: 'green' }, { prop: 'neutralByDefault', v: true }], noChildren: true },
        ],
    },
    {
        heading: 'isCircle option',
        description: "By default the IconButton is a square. Use the isCircle option to make it a round.\n    This is noticable when you hover over the IconButton.",
        variations: [
            { component: 'UndoButton', properties: [{ prop: 'isCircle', v: true }], noChildren: true },
        ],
    },
    {
        heading: 'tooltipTitle option',
        description: 'Add the tooltipTitle prop to add content to the IconButton. This is shown on hover.',
        variations: [
            { component: 'UndoButton', properties: [{ prop: 'tooltipTitle', v: 'Undo this action' }], noChildren: true },
        ],
    },
];
