"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var faFileContract_1 = require("@fortawesome/pro-light-svg-icons/faFileContract");
var faFileDashedLine_1 = require("@fortawesome/pro-light-svg-icons/faFileDashedLine");
var IconButtons_1 = require("atomicComponents/molecules/IconButtons/IconButtons");
var DownloadAttachmentsButton = function (_a) {
    var label = _a.label, onClick = _a.onClick, isLoading = _a.isLoading, isHidden = _a.isHidden, size = _a.size, mt = _a.mt;
    return ((0, jsx_runtime_1.jsx)(IconButtons_1.DownloadInvoiceButton, { icon: isHidden ? faFileDashedLine_1.faFileDashedLine : faFileContract_1.faFileContract, onClick: onClick, disabled: isLoading, tooltipTitle: label, iconSize: size === 'extraSmall' ? 12 : 14, size: size, mt: mt }));
};
exports.default = DownloadAttachmentsButton;
