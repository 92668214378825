"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PLAN_DISCOUNT_MODES_MAP = exports.DISCOUNT_MODES_MAP = void 0;
exports.DISCOUNT_MODES_MAP = {
    percent_discount: 'Fixed percent discount',
    percent_profit: 'Fixed percent profit',
    split: 'Define part of discount held by your company',
    per_plan: 'On a plan basis',
};
exports.PLAN_DISCOUNT_MODES_MAP = {
    percent_discount: 'Fixed percent discount',
    fixed_discount: 'Fixed value discount',
    fixed_price: 'Fixed price',
    per_unit: 'Per unit',
};
