"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var hooks_1 = require("utils/hooks");
// https://login.xero.com/identity/connect/authorize
// ?redirect_uri=https%3A%2F%2Fapp.hudled.com%2Foauth%2Fxero
// &response_type=code
// &scope=openid%20profile%20email%20offline_access%20accounting.transactions.read%20accounting.settings.read%20accounting.attachments.read&client_id=41E7A19493AE4D4183B251EAA9B18DF3
// &state=REDIRECT_PATH-_-02NRgp9JojzTn1uyIPa1-_-123-_-ADD_CONNECTION
var RedirectToAcct = function () {
    var qParams = (0, hooks_1.useQParams)();
    var redirectUrl = decodeURIComponent(qParams.oauthUrl);
    var urlIsCorrect = redirectUrl.match(/^https:\/\/login.xero.com\/identity\/connect\/authorize/);
    (0, react_1.useEffect)(function () {
        if (urlIsCorrect) {
            window.location.href = redirectUrl;
        }
    }, []);
    if (!urlIsCorrect) {
        return (0, jsx_runtime_1.jsx)("div", { children: "Error" });
    }
    return (0, jsx_runtime_1.jsx)("div", { children: "Loading..." });
};
exports.default = RedirectToAcct;
