import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const ToggleButton = ({ toggleAction, isSoftDelete }) => (
  <ButtonStyled isSoftDelete={isSoftDelete} onClick={toggleAction} type="button" aria-label="Close">
    <FontAwesomeIcon icon={isSoftDelete ? faEyeSlash : faEye} />
  </ButtonStyled>
);

ToggleButton.propTypes = {
  toggleAction: PropTypes.func,
  isSoftDelete: PropTypes.bool,
};

export default ToggleButton;

const ButtonStyled = styled('button')`
  font-size: .75rem;
  padding: 1rem;
  color: ${({ isSoftDelete }) => (isSoftDelete ? '#BF4562' : '#02AD6B')};

  &:hover {
    cursor: pointer;
    color: ${({ isSoftDelete }) => (isSoftDelete ? '#02AD6B' : '#BF4562')};
  }
`;
