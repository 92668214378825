"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var DropdownWrapper = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(1, 4),
        borderRadius: 6,
        '.MuiTypography-root': {
            lineHeight: '21px',
        },
        '.MuiButton-sizeSmall.MuiButton-textSizeSmall': {
            padding: theme.spacing(0.5),
            fontSize: 11,
            minWidth: 1,
        },
    });
});
exports.default = DropdownWrapper;
