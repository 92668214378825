"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var faCheck_1 = require("@fortawesome/pro-regular-svg-icons/faCheck");
var faTimesCircle_1 = require("@fortawesome/pro-regular-svg-icons/faTimesCircle");
var faCheckCircle_1 = require("@fortawesome/pro-solid-svg-icons/faCheckCircle");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var interfaces_1 = require("@hudled/interfaces");
var COLOR_MAP = (_a = {},
    _a[interfaces_1.RECOMMENDATIONS_STATUS.USED] = 'green',
    _a[interfaces_1.RECOMMENDATIONS_STATUS.REJECTED] = 'red-dark',
    _a[interfaces_1.RECOMMENDATIONS_STATUS.DEFERRED] = 'yellow',
    _a);
var ICON_MAP = (_b = {},
    _b[interfaces_1.RECOMMENDATIONS_STATUS.USED] = faCheckCircle_1.faCheckCircle,
    _b[interfaces_1.RECOMMENDATIONS_STATUS.REJECTED] = faTimesCircle_1.faTimesCircle,
    _b);
var OptimiseStatusIcon = function (_a) {
    var status = _a.status;
    return ((0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: ICON_MAP[status] || faCheck_1.faCheck, className: "color-".concat(status ? COLOR_MAP[status] : 'mute') }));
};
exports.default = OptimiseStatusIcon;
