"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstName = exports.getDisplayName = exports.createUserCompanyData = exports.getMainCompanyData = exports.getCompanyDataFromUser = void 0;
var getCompanyDataFromUser = function (user, cId) { var _a; return (_a = user.companyData) === null || _a === void 0 ? void 0 : _a[cId]; };
exports.getCompanyDataFromUser = getCompanyDataFromUser;
var getMainCompanyData = function (user) { return user.mainCompanyId ? (0, exports.getCompanyDataFromUser)(user, user.mainCompanyId) : undefined; };
exports.getMainCompanyData = getMainCompanyData;
var createUserCompanyData = function (companyId, accessLevel) {
    var _a;
    return ({
        companyIds: [companyId],
        companyId: companyId,
        mainCompanyId: companyId, companyAccessLevel: (_a = {}, _a[companyId] = accessLevel, _a),
        accessLevel: accessLevel,
    });
};
exports.createUserCompanyData = createUserCompanyData;
var getDisplayName = function (user) { return user
    ? user.displayName || [user.firstName, user.lastName].filter(Boolean).join(' ')
    : ''; };
exports.getDisplayName = getDisplayName;
var getFirstName = function (user) { var _a; return (user === null || user === void 0 ? void 0 : user.firstName) || ((_a = user === null || user === void 0 ? void 0 : user.displayName) === null || _a === void 0 ? void 0 : _a.split(' ')[0]) || ''; };
exports.getFirstName = getFirstName;
