"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var CodePreviewWrapper = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        border: "1px solid ".concat(theme.palette.grey[400]),
        marginTop: theme.spacing(2),
        padding: theme.spacing(3, 2),
    });
});
exports.default = CodePreviewWrapper;
