"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var system_1 = require("@mui/system");
exports.default = (0, system_1.styled)(material_1.TextField)(function (_a) {
    var theme = _a.theme;
    return ({
        cursor: 'default',
        borderRadius: 5,
        backgroundColor: theme.palette.common.secondary,
        color: theme.palette.text.secondary,
        margin: theme.spacing(1, 0, 8, 0),
        justifyContent: 'center',
        border: "1px solid ".concat(theme.palette.neutral.main),
        ':hover': {
            borderColor: theme.palette.primary.light,
        },
        input: {
            lineHeight: theme.spacing(3),
            width: theme.spacing(8),
        },
    });
});
