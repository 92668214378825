"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDomainCode = exports.DOMAIN_MAP = exports.getXeroAuthorizeUriTemplate = exports.parseStateString = exports.makeStateString = void 0;
var lodash_1 = require("lodash");
var constants_1 = require("./constants");
var utils_1 = require("./utils");
// REDIRECT_PATH_PLACEHOLDER will be replaced with encodeURIComponent(redirectPath) in the FE
var makeStateString = function (connId, purpose, domainCode) {
    if (domainCode === void 0) { domainCode = 'dldh'; }
    return [
        constants_1.OAUTH_REDIRECT_PATH_PLACEHOLDER, connId, 123, purpose, domainCode
    ].join(constants_1.OAUTH_STATE_DIVIDER);
};
exports.makeStateString = makeStateString;
var parseStateString = function (state) {
    var _a = __read(state.split(constants_1.OAUTH_STATE_DIVIDER), 5), redirectPathEncoded = _a[0], connId = _a[1], _ = _a[2], purpose = _a[3], domainCode = _a[4];
    return {
        redirectPath: decodeURIComponent(redirectPathEncoded), connId: connId === 'NONE' ? undefined : connId,
        purpose: purpose,
        domainCode: domainCode
    };
};
exports.parseStateString = parseStateString;
var SCOPES_MAP = {
    'SIGN-IN': constants_1.xeroSignInScope,
    'SIGN-IN-RECHARGLY': constants_1.xeroSignInScope,
    'RECHRGLY': constants_1.xeroRechargeScope,
    'RECHRGLY_CLIENT': constants_1.xeroRechargeScope,
};
var getScope = function (purpose) {
    var _a;
    return (_a = SCOPES_MAP[purpose]) !== null && _a !== void 0 ? _a : (purpose.includes('RECHARGLY') ? constants_1.xeroRechargeScope : constants_1.xeroDefaultScope);
};
var getXeroAuthorizeUriTemplate = function (connId, clientId, purpose, enableBulk) {
    if (enableBulk === void 0) { enableBulk = false; }
    var state = (0, exports.makeStateString)(connId, purpose, constants_1.OAUTH_DOMAIN_CODE_PLACEHOLDER);
    return "".concat(constants_1.XERO_CONSENT_URL_BASE, "?").concat(new URLSearchParams((0, lodash_1.pickBy)({
        redirect_uri: constants_1.OAUTH_RETURN_URL_PLACEHOLDER,
        response_type: 'code',
        scope: getScope(purpose),
        client_id: clientId,
        acr_values: enableBulk ? undefined : 'bulk_connect:false',
        state: state
    }, utils_1.notFalsy)).toString());
};
exports.getXeroAuthorizeUriTemplate = getXeroAuthorizeUriTemplate;
exports.DOMAIN_MAP = {
    dldh: 'app.hudled.com',
    hcer: 'app.rechargly.com',
    lclh: 'localhost:3000',
};
var getDomainCode = function (domain) { return (0, lodash_1.invert)(exports.DOMAIN_MAP)[domain]; };
exports.getDomainCode = getDomainCode;
