"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var pro_regular_svg_icons_1 = require("@fortawesome/pro-regular-svg-icons");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var material_1 = require("@mui/material");
var CheckboxButton_1 = require("atomicComponents/molecules/Buttons/CheckboxButton");
var CircleCheckSwitch = function (_a) {
    var _b = _a.input, onChange = _b.onChange, checked = _b.checked, value = _b.value, label = _a.label, noLabel = _a.noLabel, noColor = _a.noColor, yesColor = _a.yesColor;
    var isChecked = checked || value;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(CheckboxButton_1.LineButtonWrapper, __assign({ icon: !isChecked ? pro_solid_svg_icons_1.faCircleCheck : pro_regular_svg_icons_1.faCircle, onClick: function () { return onChange(false); }, checked: !isChecked, color: !isChecked ? noColor : 'neutral' }, { children: noLabel })), (0, jsx_runtime_1.jsx)(material_1.Box, __assign({ component: "span", px: 2 }, { children: "or" })), (0, jsx_runtime_1.jsx)(CheckboxButton_1.LineButtonWrapper, __assign({ icon: isChecked ? pro_solid_svg_icons_1.faCircleCheck : pro_regular_svg_icons_1.faCircle, onClick: function () { return onChange(true); }, checked: isChecked, color: isChecked ? yesColor : 'neutral' }, { children: label }))] }));
};
exports.default = CircleCheckSwitch;
