"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetSteps = exports.useStepsSettings = exports.useWizardSettings = void 0;
var end2end_1 = require("@hudled/end2end");
var interfaces_1 = require("@hudled/interfaces");
var firestore_1 = require("firebase/firestore");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var hooks_1 = require("containers/Onboarding/hooks");
var analytics_1 = require("utils/analytics");
var docMethods_1 = require("utils/docMethods");
var hooks_2 = require("utils/hooks");
var other_1 = require("utils/selectors/other");
var single_1 = require("utils/selectors/single");
var constants_1 = require("./constants");
var utils_1 = require("./utils");
var useWizardSettings = function () {
    var profile = (0, react_redux_1.useSelector)(single_1.selectProfileWithId);
    var company = (0, react_redux_1.useSelector)(single_1.selectMyCompany);
    var team = (0, react_redux_1.useSelector)(single_1.selectMyCompanyTeam);
    var mainAccountingConnection = (0, react_redux_1.useSelector)(single_1.selectMainAccountingConnection);
    var _a = (0, exports.useStepsSettings)(profile, company, team), addStep = _a.addStep, initialStep = _a.initialStep, steps = _a.steps;
    var initialValues = useInitialValues(profile, company, team, mainAccountingConnection);
    var _b = __read((0, hooks_2.useSubmitter)('companies', company.id), 1), updateCompany = _b[0];
    var addCompanyOnboardingStep = (0, hooks_1.useAddCompanyOnboardingStep)();
    var addUserOnboardingStep = (0, hooks_1.useAddUserOnboardingStep)();
    var history = (0, react_router_dom_1.useHistory)();
    var canAdmin = (0, react_redux_1.useSelector)((0, other_1.makeSelectUserCan)(interfaces_1.ACCESS_LEVEL.Admin));
    var trackFlowCompleted = (0, analytics_1.usePreparedTracker)('Activation Flow Completed', {
        accessLevel: profile.accessLevel,
        flowUser: company.ownerId === profile.id ? 'Company Creator' : 'Company Members',
        accountingSystem: company.accSoftware,
        resolution: (0, end2end_1.userCan)(interfaces_1.ACCESS_LEVEL.Admin)(profile) ? 'Accounting Connected' : 'Done',
        stepsTotal: steps.length,
    });
    var trackFlowDeferred = (0, analytics_1.usePreparedTracker)('Activation Flow Deferred', {
        accessLevel: profile.accessLevel,
        flowUser: 'Company Creator',
        flowType: 'Shortened',
        resolution: 'Skipped',
        stepsTotal: steps.length,
    });
    (0, react_1.useEffect)(function () {
        var _a;
        if (!((_a = company.times) === null || _a === void 0 ? void 0 : _a.startedSignupFlow)) {
            updateCompany({ times: { startedSignupFlow: firestore_1.Timestamp.now() } });
        }
    }, []);
    return {
        addStep: addStep,
        initialStep: initialStep,
        steps: steps,
        initialValues: initialValues,
        onEnd: function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(canAdmin && (company.hasAccountingConnection || company.hasUploadedCsv) && !company.hasDoneFirstCalculate)) return [3 /*break*/, 2];
                        return [4 /*yield*/, updateCompany({ isQueuedToWriteServiceStats: true })];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        if (company.hasAccountingConnection || !canAdmin) {
                            trackFlowCompleted({
                                flowType: canAdmin && company.hasAccountingConnection
                                    ? 'Return'
                                    : 'Entire Flow',
                            });
                        }
                        else {
                            trackFlowDeferred();
                        }
                        return [4 /*yield*/, Promise.all([
                                canAdmin && addCompanyOnboardingStep('SET_ACCOUNTING'),
                                addUserOnboardingStep('WELCOME_STEP'),
                            ])];
                    case 3:
                        _b.sent();
                        if (!!((_a = company.times) === null || _a === void 0 ? void 0 : _a.completedSignupFlow)) return [3 /*break*/, 5];
                        return [4 /*yield*/, updateCompany({ times: { completedSignupFlow: firestore_1.Timestamp.now() } })];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5: return [4 /*yield*/, (0, end2end_1.delay)(100)];
                    case 6:
                        _b.sent();
                        history.push('/');
                        return [2 /*return*/];
                }
            });
        }); },
    };
};
exports.useWizardSettings = useWizardSettings;
var useStepsSettings = function (profile, company, team) {
    var _a, _b, _c;
    var _d = __read((0, hooks_2.useSubmitter)('companies', company.id), 1), updateCompany = _d[0];
    var _e = __read((0, hooks_2.useSubmitter)('users', profile.id), 1), updateUser = _e[0];
    var _f = __read((0, hooks_2.useSubmitter)('company_teams', team === null || team === void 0 ? void 0 : team.id), 1), updateTeam = _f[0];
    var companyHasRequestedSaasAudit = (0, react_1.useMemo)(function () { return company.hasRequestedSaasAudit; }, []);
    var steps = (0, exports.useGetSteps)(company, profile, companyHasRequestedSaasAudit);
    var initialStep = (0, react_1.useMemo)(function () { return steps
        .find(function (step) {
        var _a, _b, _c;
        return !((_a = profile.completedWelcomeOnbSteps) === null || _a === void 0 ? void 0 : _a.includes(step))
            && !((_b = company.completedWelcomeOnbSteps) === null || _b === void 0 ? void 0 : _b.includes(step))
            && !((_c = team === null || team === void 0 ? void 0 : team.completedWelcomeOnbSteps) === null || _c === void 0 ? void 0 : _c.includes(step));
    }); }, []);
    var addStep = (0, react_1.useCallback)(function (currentStep) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!(interfaces_1.COMPANY_WELCOME_ONB_STEPS.includes(currentStep)
                        && !((_a = company.completedWelcomeOnbSteps) === null || _a === void 0 ? void 0 : _a.includes(currentStep)))) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateCompany({ completedWelcomeOnbSteps: (0, firestore_1.arrayUnion)(currentStep) })];
                case 1:
                    _d.sent();
                    _d.label = 2;
                case 2:
                    if (!(interfaces_1.USER_WELCOME_ONB_STEPS.includes(currentStep)
                        && !((_b = profile.completedWelcomeOnbSteps) === null || _b === void 0 ? void 0 : _b.includes(currentStep)))) return [3 /*break*/, 4];
                    return [4 /*yield*/, updateUser({ completedWelcomeOnbSteps: (0, firestore_1.arrayUnion)(currentStep) })];
                case 3:
                    _d.sent();
                    _d.label = 4;
                case 4:
                    if (!(interfaces_1.TEAM_WELCOME_ONB_STEPS.includes(currentStep)
                        && !((_c = team === null || team === void 0 ? void 0 : team.completedWelcomeOnbSteps) === null || _c === void 0 ? void 0 : _c.includes(currentStep)))) return [3 /*break*/, 6];
                    return [4 /*yield*/, updateTeam({ completedWelcomeOnbSteps: (0, firestore_1.arrayUnion)(currentStep) })];
                case 5:
                    _d.sent();
                    _d.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    }); }, [(_a = company.completedWelcomeOnbSteps) === null || _a === void 0 ? void 0 : _a.join(), (_b = profile.completedWelcomeOnbSteps) === null || _b === void 0 ? void 0 : _b.join(), (_c = team === null || team === void 0 ? void 0 : team.completedWelcomeOnbSteps) === null || _c === void 0 ? void 0 : _c.join()]);
    return { initialStep: initialStep, steps: steps, addStep: addStep };
};
exports.useStepsSettings = useStepsSettings;
var useInitialValues = function (profile, company, team, mainAccConn) { return (0, react_1.useMemo)(function () { return (__assign({ company: company, mainAccountingConnection: mainAccConn || {}, profile: (0, docMethods_1.fillWithNames)(profile), csvUpload: {
        defaultCurrency: company.baseCurrency || 'AUD',
        dateFormat: 'YYYY-MM-DD',
    } }, (0, utils_1.makeTeamFormValues)(profile, team))); }, [company, profile, team, mainAccConn]); };
var useGetSteps = function (company, profile, companyHasReqSaasAudit) {
    var _a;
    var isShort = ((_a = company.completedWelcomeOnbSteps) === null || _a === void 0 ? void 0 : _a.includes('SET_ACCOUNTING'))
        && !company.serviceStatsCount
        && !company.hasAccountingConnection
        && !company.hasUploadedCsv;
    var isAccountant = profile.isAccountant, accessLevel = profile.accessLevel, companyId = profile.companyId, mainCompanyId = profile.mainCompanyId, companyTeamId = profile.companyTeamId, completedOnboardingSteps = profile.completedOnboardingSteps;
    var hasTeam = !!companyTeamId;
    var needsUserIntro = !(completedOnboardingSteps === null || completedOnboardingSteps === void 0 ? void 0 : completedOnboardingSteps.includes('WELCOME_STEP')) || mainCompanyId === companyId;
    var isAccountingFlow = isAccountant && company.isAccountingFirm;
    return (0, react_1.useMemo)(function () {
        var steps = (0, constants_1.getWelcomeOnbSteps)(accessLevel, isShort, companyHasReqSaasAudit, isAccountingFlow);
        steps = hasTeam ? steps : steps.filter(function (s) { return s !== 'SETUP_TEAM'; });
        return needsUserIntro ? steps : steps.filter(function (s) { return s !== 'USER_INTRO'; });
    }, [accessLevel, isShort, companyHasReqSaasAudit, isAccountingFlow, hasTeam, needsUserIntro]);
};
exports.useGetSteps = useGetSteps;
