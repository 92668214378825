"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
exports.default = (0, styles_1.styled)('div')(function (_a) {
    var _b, _c;
    var _d;
    var theme = _a.theme;
    return (_b = {},
        _b[".".concat(material_1.autocompleteClasses.paper)] = {
            width: '100%',
            margin: 0,
            padding: 0,
            paddingBottom: theme.spacing(1),
            boxShadow: '0px 4px 4px rgb(0 0 0 / 2%), 0px 4px 6px rgb(0 0 0 / 6%)',
        },
        _b[".".concat(material_1.autocompleteClasses.listbox)] = (_c = {
                backgroundColor: theme.palette.common.white,
                padding: 0
            },
            _c[".".concat(material_1.autocompleteClasses.option)] = {
                padding: theme.spacing(0.5, 2),
                '&:hover, &.Mui-focusVisible': {
                    backgroundColor: theme.palette.secondary.light,
                },
                '&.Mui-disabled .MuiTypography-root': {
                    color: (_d = theme.palette.neutral) === null || _d === void 0 ? void 0 : _d.main,
                },
            },
            _c),
        _b["&.".concat(material_1.autocompleteClasses.popperDisablePortal)] = {
            position: 'relative',
        },
        _b);
});
