"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var general_1 = require("utils/general");
var StyledAccordion = (0, styles_1.styled)(material_1.Accordion, { shouldForwardProp: (0, general_1.omitProps)('hideBorders', 'offsetHor') })(function (_a) {
    var hideBorders = _a.hideBorders, offsetHor = _a.offsetHor, theme = _a.theme;
    return ({
        color: 'inherit',
        boxShadow: 'none',
        '&.Mui-disabled': {
            background: 'none',
            '& .Mui-disabled': {
                opacity: 1,
            },
        },
        '&::before': {
            background: theme.palette.common.white,
        },
        borderTop: hideBorders ? 'none' : '1px solid transparent',
        borderBottom: hideBorders ? 'none' : '1px solid transparent',
        marginLeft: offsetHor ? theme.spacing(-2) : 0,
        marginRight: offsetHor ? theme.spacing(-2) : 0,
        borderRadius: 0,
        '&.MuiAccordion-root.Mui-expanded': __assign({ borderTop: hideBorders ? 'none' : "1px solid ".concat(theme.palette.grey[600]), borderBottom: hideBorders ? 'none' : "1px solid ".concat(theme.palette.grey[600]), borderRadius: 0, minHeight: 'auto', margin: 0 }, offsetHor && { margin: theme.spacing(0, -2) }),
    });
});
exports.default = StyledAccordion;
