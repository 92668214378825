import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const BasicListWrapper = ({ className, children }) => (
  <div className={classNames('ds-list roundBorders', className)}>
    {children}
  </div>
);

BasicListWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default BasicListWrapper;
