"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.invoiceDetailsListViewTableConf = exports.invoiceDetailsTableConf = void 0;
var end2end_1 = require("@hudled/end2end");
var constants_1 = require("containers/TableMaker/constants");
var formatters_1 = require("utils/formatters");
var // XL, LG, MD, SM, XS,
XL = constants_1.CellSize.XL, LG = constants_1.CellSize.LG, MD = constants_1.CellSize.MD, XS = constants_1.CellSize.XS;
exports.invoiceDetailsTableConf = {
    headersMap: {
        description: 'Description (Charges)',
        date: 'Billing Start - End',
    },
    extractors: {
        date: function (item) {
            var _a, _b, _c;
            return ((_a = item.servicePeriod) === null || _a === void 0 ? void 0 : _a.start) && ((_b = item.servicePeriod) === null || _b === void 0 ? void 0 : _b.end)
                && (0, end2end_1.getDateRange)(item.servicePeriod.start, (_c = item.servicePeriod) === null || _c === void 0 ? void 0 : _c.end);
        },
        discount: function (item) { return item.discount && item.discount < 0 ? undefined : item.discount; },
        listPrice: function (item) { return item.discount && item.discount < 0 ? undefined : item.listPrice; },
    },
    row: { sizing: 'dense' },
    cells: [
        {
            cell: { width: XL + XS + 10, growCell: true }, field: 'description', truncate: {}, noSort: true,
        },
        { cell: { width: LG }, field: 'date', noSort: true },
        {
            cell: { width: MD }, field: 'listPrice', money: {}, fallback: '-', noSort: true,
        },
        {
            cell: { width: MD, textAlign: 'right' }, field: 'discount', noSort: true, fallback: '-', renderField: formatters_1.formatPercentPrecise,
        },
        { cell: { width: MD }, field: 'amount', money: {}, noSort: true },
    ],
};
exports.invoiceDetailsListViewTableConf = __assign(__assign({}, exports.invoiceDetailsTableConf.headersMap), { extractors: {
        date: function (item) {
            var _a, _b, _c;
            return ((_a = item.servicePeriod) === null || _a === void 0 ? void 0 : _a.start) && ((_b = item.servicePeriod) === null || _b === void 0 ? void 0 : _b.end)
                && (0, end2end_1.getDateRange)(item.servicePeriod.start, (_c = item.servicePeriod) === null || _c === void 0 ? void 0 : _c.end);
        },
        discount: function (item) { return item.discount && item.discount < 0 ? undefined : item.discount; },
        listPrice: function (item) { return item.discount && item.discount < 0 ? undefined : item.listPrice; },
    }, row: { sizing: 'dense' }, layout: { fixedColumns: 0 }, cells: [
        {
            field: 'description', cell: { width: XL, growCell: true }, truncate: {}, noSort: true,
        },
        { field: 'date', cell: { width: LG }, noSort: true },
        {
            field: 'listPrice', cell: { width: MD }, money: {}, noSort: true, fallback: '-',
        },
        {
            field: 'discount', cell: { width: MD, textAlign: 'right' }, noSort: true, fallback: '-', renderField: formatters_1.formatPercentPrecise,
        },
        { field: 'amount', cell: { width: MD, growCell: true }, money: {}, noSort: true },
    ] });
