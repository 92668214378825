"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var PREFIX = 'TransactionCountPill';
var classes = {
    pillCountWrapper: "".concat(PREFIX, "-pillCountWrapper"),
    pillCount: "".concat(PREFIX, "-pillCount"),
};
var StyledGrid = (0, styles_1.styled)(material_1.Grid, {
    shouldForwardProp: function (prop) { return prop !== 'count'; },
})(function (_a) {
    var _b;
    var count = _a.count, theme = _a.theme;
    return (_b = {},
        _b["&.".concat(classes.pillCountWrapper)] = __assign(__assign({ padding: theme.spacing(1), transform: 'rotate(180deg)' }, (count <= 3 && {
            transform: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& div': {
                marginTop: theme.spacing(0.5),
            },
        })), (count % 2 !== 0 && {
            '&:nth-child(n)': {
                width: '100%',
                justifyContent: 'flex-end',
            },
        })),
        _b[".".concat(classes.pillCount)] = __assign({ width: theme.spacing(1), height: theme.spacing(1), borderRadius: '50%', background: '#FF9FCA' }, (count >= 10 && {
            '&[data-value="7"],&[data-value="8"]': {
                background: '#FFD6E2',
            },
            '&[data-value="9"],&[data-value="10"]': {
                background: '#FFE9EF',
            },
        })),
        _b);
});
var TransactionCountPill = function (_a) {
    var count = _a.count, spend = _a.spend;
    return ((0, jsx_runtime_1.jsx)(StyledGrid, __assign({ xs: 12, container: true, className: classes.pillCountWrapper, "data-value": "".concat(count), justifyContent: "center", count: count, spend: spend }, { children: __spreadArray([], __read(Array(10).keys()), false).map(function (el) { return ((0, jsx_runtime_1.jsx)(material_1.Box, __assign({ m: 0.25, display: "flex" }, { children: (0, jsx_runtime_1.jsx)("span", { className: classes.pillCount, "data-value": "".concat(el + 1) }) }), el)); }) })));
};
exports.default = TransactionCountPill;
