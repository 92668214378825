"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var styles_1 = require("@mui/material/styles");
var PREFIX = 'ConfirmMessageDetails';
var classes = {
    type: "".concat(PREFIX, "-type"),
    heading: "".concat(PREFIX, "-heading"),
};
var Root = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&.".concat(classes.type)] = {
            fontSize: 16,
            marginBottom: 16,
            letterSpacing: '0.023em',
            color: theme.palette.grey[600],
            fontWeight: 500,
        },
        _b[".".concat(classes.heading)] = {
            color: theme.palette.text.secondary,
            fontWeight: 700,
        },
        _b);
});
var ConfirmMessageDetails = function (_a) {
    var heading = _a.heading, details = _a.details;
    return ((0, jsx_runtime_1.jsxs)(Root, __assign({ className: classes.type }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: classes.heading }, { children: heading })), (0, jsx_runtime_1.jsx)("span", __assign({ className: "mx-4" }, { children: "\u00B7" })), (0, jsx_runtime_1.jsx)("span", { children: details })] })));
};
exports.default = ConfirmMessageDetails;
