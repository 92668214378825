import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const CloseButton = ({ closeAction, size, className }) => (
  <ButtonStyled size={size} onClick={closeAction} className={className} type="button" aria-label="Close">
    <FontAwesomeIcon icon={faTimes} />
  </ButtonStyled>
);

CloseButton.propTypes = {
  closeAction: PropTypes.func,
  size: PropTypes.string,
  className: PropTypes.string,
};

export default CloseButton;

const ButtonStyled = styled('button')`
  font-size: ${(props) => (props.size === 'sm' ? '1rem' : '1.401rem')};
  padding: .282rem .875rem; // $spacer * 2 - 4.205px
  opacity: 1;
  color: #62778C;

  &:hover, &:focus {
    opacity: .75;
  }

  &:hover {
    cursor: pointer;
  }

  .ds-action-buttons & {
    font-size: 1.401rem;
    padding: .282rem .875rem; // $spacer * 2 - 4.205px
  }
`;
