"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
exports.default = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        height: '100%',
        color: theme.palette.riverstone,
        overflow: 'auto',
        '::-webkit-scrollbar': {
            width: 0,
            background: 'transparent',
        },
        padding: theme.spacing(2, 0),
        // marginLeft: -theme.spacing(2),
    });
});
