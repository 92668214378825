"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
exports.default = (0, styles_1.styled)(material_1.TextField)(function (_a) {
    var theme = _a.theme;
    return ({
        '.MuiFilledInput-root': {
            borderRadius: 10,
        },
        '.MuiFilledInput-root:before': {
            display: 'none',
        },
        '.MuiFilledInput-root:after': {
            display: 'none',
        },
        '.MuiInputBase-input': {
            boxShadow: '0px 3px 8px rgba(0,0,0,0.12)',
            height: 40,
            padding: theme.spacing(0, 4),
            background: theme.palette.common.white,
            borderRadius: 10,
            ':focus-visible': theme.focusVisibleStyles,
        },
    });
});
