"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectSlightlyPopulatedServiceStatsWithToolAnalysis = exports.selectSlightlyPopulatedServiceStats = exports.selectLatestAudit = exports.selectShowDummyData = exports.selectBlockSaasAuditParts = exports.selectHasAccessToFullAudit = exports.selectIsPayingCustomer = exports.selectAllAudits = void 0;
var reselect_1 = require("reselect");
var base_1 = require("utils/selectors/base");
var data_1 = require("utils/selectors/data");
var single_1 = require("utils/selectors/single");
var utils_1 = require("utils/selectors/utils");
var dummyData_1 = require("./dummyData");
exports.selectAllAudits = (0, utils_1.makeSelectOrdered)('audits');
var selectLatestActualAudit = (0, reselect_1.createSelector)(exports.selectAllAudits, function (audits) { return audits && audits[0]; });
exports.selectIsPayingCustomer = (0, reselect_1.createSelector)(single_1.selectMyCompany, single_1.selectProfile, function (company, profile) { return ((company === null || company === void 0 ? void 0 : company.plan) && ['Growth', 'Scale'].includes(company.plan) && company.planStatus === 'active')
    || (profile === null || profile === void 0 ? void 0 : profile.isAdmin); });
exports.selectHasAccessToFullAudit = (0, reselect_1.createSelector)(single_1.selectMyCompany, exports.selectIsPayingCustomer, function (company, isPayingCustomer) { return (company === null || company === void 0 ? void 0 : company.hasFreeFullAudit) || isPayingCustomer; });
exports.selectBlockSaasAuditParts = (0, reselect_1.createSelector)(single_1.selectMyCompany, exports.selectHasAccessToFullAudit, function (company, hasAccessToFullAudit) { return !hasAccessToFullAudit && (company === null || company === void 0 ? void 0 : company.saasAuditEnabled); });
exports.selectShowDummyData = (0, reselect_1.createSelector)(single_1.selectMyCompany, exports.selectHasAccessToFullAudit, (0, base_1.makeSelectLocation)(), function (company, hasFullAccess, location) {
    var _a;
    return !(company === null || company === void 0 ? void 0 : company.saasAuditEnabled)
        || (!hasFullAccess && !((_a = location.pathname) === null || _a === void 0 ? void 0 : _a.match(/\/saas-audit\/report/)));
});
exports.selectLatestAudit = (0, reselect_1.createSelector)(selectLatestActualAudit, exports.selectShowDummyData, function (audit, showDummy) { return showDummy ? dummyData_1.dummyAudit : audit; });
exports.selectSlightlyPopulatedServiceStats = (0, reselect_1.createSelector)(exports.selectShowDummyData, data_1.selectServicesMap, (0, utils_1.makePopulate)('service_stats', ['service']), function (showDummyData, srvMap, sStats) { return showDummyData
    ? dummyData_1.dummyServiceStats.map(function (sS, idx) { return (__assign(__assign({ id: "S_STAT_".concat(idx) }, sS), { service: __assign({ id: sS.serviceId }, srvMap === null || srvMap === void 0 ? void 0 : srvMap[sS.serviceId]) })); })
    : sStats && sStats.filter(function (sS) { return !sS.isSoftDelete; }); });
exports.selectSlightlyPopulatedServiceStatsWithToolAnalysis = (0, reselect_1.createSelector)(exports.selectSlightlyPopulatedServiceStats, exports.selectLatestAudit, function (sStats, audit) { return audit && (sStats === null || sStats === void 0 ? void 0 : sStats.map(function (sS) { return (__assign(__assign({}, sS), { toolAnalysis: audit === null || audit === void 0 ? void 0 : audit.stackHealth.analysedTools[sS.serviceId], auditSummary: audit.summary })); }).filter(function (sS) { return sS.toolAnalysis; })); });
