"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var EmptyListWelcomeStyles_1 = require("components/Lists/EmptyListWelcomeStyles");
var Titles_1 = require("components/Texts/Titles");
var EmptyListMessage = function (_a) {
    var title = _a.title, text = _a.text, children = _a.children;
    return ((0, jsx_runtime_1.jsxs)(EmptyListWelcomeStyles_1.MainBox, { children: [(0, jsx_runtime_1.jsx)(Titles_1.Title, { children: title }), (0, jsx_runtime_1.jsx)(Titles_1.SubTitle, __assign({ className: "ds-text-sec" }, { children: text })), children] }));
};
exports.default = EmptyListMessage;
