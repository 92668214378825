"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var TabsContainer = (0, material_1.styled)(material_1.Tabs)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'inline-block',
        position: 'sticky',
        left: 0,
        minHeight: theme.spacing(4),
        height: theme.spacing(4),
        alignItems: 'center',
        '& .MuiTabs-indicator': { display: 'none' },
        '& button:first-of-type, a:first-of-type': { marginLeft: 0 },
    });
});
exports.default = TabsContainer;
