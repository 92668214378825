import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'reactstrap';

const BasicListContent = ({ children, ParentElement = Row }) => (
  <ParentElement className="ds-list__content">{children}</ParentElement>
);

BasicListContent.propTypes = {
  children: PropTypes.any,
  ParentElement: PropTypes.any,
};

export default BasicListContent;
