"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var LogoWrapper = (0, styles_1.styled)('span')(function (_a) {
    var theme = _a.theme;
    return ({
        verticalAlign: 'text-top',
        margin: theme.spacing(0, 0.5),
        display: 'inline-block',
    });
});
exports.default = LogoWrapper;
