"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var EditorWrapper = (0, styles_1.styled)(material_1.Box)(function (_a) {
    var _b;
    var theme = _a.theme, readOnly = _a.readOnly, isEdit = _a.isEdit;
    return ({
        padding: theme.spacing(0.5, 1, 0.5, 0),
        backgroundColor: '#fefefe',
        zIndex: !readOnly && isEdit ? theme.zIndex.drawer + 2 : undefined,
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        ':hover': {
            '.public-DraftEditorPlaceholder-root': {
                backgroundColor: !readOnly ? theme.palette.secondary.light : 'transparent',
            },
        },
        '.public-DraftEditorPlaceholder-root, .DraftEditor-editorContainer': {
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.spacing(2.5),
        },
        '.editor :global(.public-DraftEditor-content) ': {
            minHeight: 140,
        },
        '.public-DraftEditor-content': {
            letterSpacing: theme.typography.body2.letterSpacing,
        },
        '.public-DraftEditorPlaceholder-root': {
            cursor: 'text',
            width: 'calc(100% - 30px)',
            position: 'absolute',
            borderRadius: 5,
            padding: theme.spacing(0.5, 0.5, 0.5, 0),
            marginLeft: theme.spacing(0.5),
            '&.public-DraftEditorPlaceholder-hasFocus': __assign({ backgroundColor: 'transparent' }, theme.focusVisibleStyles),
            ':hover:not(.public-DraftEditorPlaceholder-hasFocus)': {
                backgroundColor: theme.palette.secondary.light,
                '.public-DraftEditorPlaceholder-inner': {
                    color: theme.palette.riverstone,
                },
            },
        },
        '.public-DraftEditorPlaceholder-inner': {
            color: (_b = theme.palette.neutral) === null || _b === void 0 ? void 0 : _b.main,
            paddingLeft: theme.spacing(0.75),
        },
        '.DraftEditor-editorContainer': {
            border: "1px solid ".concat(!readOnly && isEdit ? theme.palette.grey[400] : 'transparent'),
            borderRadius: 5,
            padding: theme.spacing(0.5, 0.875),
            minWidth: 280,
        },
        '.draftjs-linkplugin-link': {
            color: theme.palette.charcoal,
            fontWeight: theme.typography.fontWeightMedium,
            textDecoration: 'underline',
            ':hover': {
                textDecoration: 'none',
            },
        },
        '.draftjs-inlinetoolbar-button-wrapper': {
            display: 'inline-block',
        },
        '.draftjs-inlinetoolbar-button': {
            background: theme.palette.white,
            color: theme.palette.charcoal,
            fontSize: '16px',
            border: 0,
            paddingTop: '5px',
            verticalAlign: 'bottom',
            height: 34,
            width: 36,
            ':hover': {
                backgroundColor: theme.palette.roseQuartz.A100,
            },
        },
        '.draftjs-inlinetoolbar-button-active': {
            backgroundColor: theme.palette.roseQuartz.A100,
        },
        '.draftjs-inlinetoolbar-toolbar': {
            '> div': {
                display: 'inline-flex',
            },
            background: theme.palette.white,
            position: 'absolute',
            border: '1px solid #ddd',
            borderRadius: '2px',
            boxShadow: '0px 1px 3px 0px rgb(220 220 220)',
            zIndex: 2,
            boxSizing: 'border-box',
        },
    });
});
exports.default = EditorWrapper;
