"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var TeamPreviewContainer = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        marginLeft: theme.spacing(2),
        width: '100%',
        minHeight: '50vh',
    });
});
exports.default = TeamPreviewContainer;
