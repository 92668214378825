"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyserFormContext = void 0;
var react_1 = require("react");
// needs the onClick function to have the hover styles, do we still hover styles when it's not clickable?
// Subtotal uses getPriceEntry, getRowFamilyAndTier, and getPrice
// <PlansRow
//   item={row}
//   updateRow={makeUpdateRow(row.id)}
//   key={row.id}
//   removeRow={() => setRows(rows.filter((r) => r.id !== row.id))}
//   availableFamilies={getAvailableFamilies(rows)}
// />
exports.AnalyserFormContext = (0, react_1.createContext)({
    updateRow: function () { return null; },
    removeRow: function () { return null; },
    addRow: function () { return null; },
});
// <AddOnsRow
//   item={row}
//   updateRow={(newRow) => setAddOnRows(addOnRows.map((r) => r.id === row.id ? newRow : r))}
//   key={row.id}
//   removeRow={() => setAddOnRows(addOnRows.filter((r) => r.id !== row.id))}
//   availableAddOns={HUBSPOT_SERVICE_PLANS.addOns?.filter((aO) => !addOnRows.find((r) => r.addOn === aO)) ?? []}
// />
