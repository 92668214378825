"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var AddInCCWrapper = (0, material_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        width: '100%',
        backgroundColor: theme.palette.common.white,
        color: theme.palette.riverstone,
        lineHeight: '20px',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0.625, 1),
        height: theme.spacing(4),
        fontSize: theme.typography.body1.fontSize,
    });
});
exports.default = AddInCCWrapper;
