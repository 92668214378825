import { styled } from '@mui/material';
import Button from '@mui/material/Button';

export default styled(Button)(({ theme }) => ({
  borderRadius: 6,
  marginTop: 16,
  marginBottom: 16,
  fontWeight: theme.typography.fontWeightMedium,
  fontFamily: theme.typography.fontFamily,
  padding: theme.spacing(2, 4),
  textTransform: 'initial',
  fontSize: theme.typography.pxToRem(14),
  boxShadow: 'none',
  '&:active, &:focus': {
    boxShadow: 'none',
    backgroundColor: '#ff87af !important',
  },
  sizeSmall: {
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.pxToRem(13),
  },
  sizeLarge: {
    padding: theme.spacing(1.5, 3),
    fontSize: theme.typography.pxToRem(18),
  },
  containedSecondary: {
    backgroundColor: '#FF9FBF',
    height: 42,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#ff87af',
    },
  },
}));
