"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMessageAndStatus = void 0;
var constants_1 = require("./constants");
var getFullCustomerStatus = function (rechCust) {
    var _a, _b, _c;
    return [
        (_a = rechCust.agreementStatus) !== null && _a !== void 0 ? _a : 'sent',
        (_b = rechCust.paymentMethodStatus) !== null && _b !== void 0 ? _b : (((_c = rechCust.agreementInfo) === null || _c === void 0 ? void 0 : _c.confirmedByAccFirmUsr) ? 'added' : 'pending'),
        rechCust.migratedAgreement ? 'migrated' : 'normal',
    ].join('-');
};
var getMessageAndStatus = function (rechCust) {
    var _a, _b, _c, _d;
    return ({
        message: (_b = constants_1.MESSAGE_MAPPER[(_a = rechCust.agreementStatus) !== null && _a !== void 0 ? _a : 'sent']) !== null && _b !== void 0 ? _b : constants_1.MESSAGE_MAPPER[getFullCustomerStatus(rechCust)],
        status: (_d = constants_1.STATUS_MAPPER[(_c = rechCust.agreementStatus) !== null && _c !== void 0 ? _c : 'sent']) !== null && _d !== void 0 ? _d : constants_1.STATUS_MAPPER[getFullCustomerStatus(rechCust)],
    });
};
exports.getMessageAndStatus = getMessageAndStatus;
