"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var PREFIX = 'DefaultToolItem';
var classes = {
    addTool: "".concat(PREFIX, "-addTool"),
    addToolHeading: "".concat(PREFIX, "-addToolHeading"),
    toolDescription: "".concat(PREFIX, "-toolDescription"),
    btnColor: "".concat(PREFIX, "-btnColor"),
    description: "".concat(PREFIX, "-description"),
};
var StyledBox = (0, styles_1.styled)(material_1.Box)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&.".concat(classes.addTool)] = {
            position: 'relative',
            border: "1px solid ".concat(theme.palette.grey[400]),
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            maxWidth: 275,
        },
        _b[".".concat(classes.addToolHeading)] = {
            color: theme.palette.grey[600],
            whiteSpace: 'nowrap',
        },
        _b[".".concat(classes.toolDescription)] = {
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.grey[700],
        },
        _b[".".concat(classes.btnColor)] = {
            color: theme.palette.success.main,
        },
        _b[".".concat(classes.description)] = {
            borderRadius: 12,
            backgroundColor: theme.palette.grey[500],
            marginLeft: theme.spacing(0.50),
        },
        _b);
});
var DefaultToolItem = function (_a) {
    var icon = _a.icon;
    return ((0, jsx_runtime_1.jsxs)(StyledBox, __assign({ className: classes.addTool, p: 2, m: 1 }, { children: [(0, jsx_runtime_1.jsx)(material_1.Skeleton, { variant: "circular", width: 32, height: 32 }), (0, jsx_runtime_1.jsxs)(material_1.Box, __assign({ ml: 1.5, mr: 1 }, { children: [(0, jsx_runtime_1.jsx)(material_1.Box, { className: classes.description, width: 100, p: 0.75 }), (0, jsx_runtime_1.jsxs)(material_1.Box, __assign({ display: "flex", alignItems: "center", mt: 0.5, ml: 0.5 }, { children: [(0, jsx_runtime_1.jsx)(material_1.Typography, __assign({ variant: "body2", className: classes.toolDescription }, { children: "$" })), (0, jsx_runtime_1.jsx)(material_1.Box, { className: classes.description, width: 45, p: 0.5 })] }))] })), icon && ((0, jsx_runtime_1.jsx)(material_1.Box, __assign({ position: "absolute", right: -16 }, { children: icon })))] })));
};
exports.default = DefaultToolItem;
