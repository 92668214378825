"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var UserReviewBlock = (0, material_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        marginBottom: theme.spacing(1),
        '& p': {
            width: 160,
        },
    });
});
exports.default = UserReviewBlock;
