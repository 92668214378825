import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as ReactstrapTooltip } from 'reactstrap';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = { tooltipOpen: false };
  }

  componentDidMount() {
    if (this.props.targetRef) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState(({ hasRefreshed }) => ({ hasRefreshed: !hasRefreshed }));
    }
  }

  toggle() {
    this.setState(({ tooltipOpen }) => ({ tooltipOpen: !tooltipOpen }));
  }

  render() {
    const { target, targetRef, ...props } = this.props;
    if (!(target || (targetRef && targetRef.current))) return null;
    return (
      <ReactstrapTooltip
        {...props}
        isOpen={this.state.tooltipOpen}
        toggle={this.toggle}
        target={target || (targetRef && targetRef.current)}
        boundariesElement="viewport"
        modifiers={{ preventOverflow: { escapeWithReference: true } }}
      />
    );
  }
}

Tooltip.propTypes = {
  ...ReactstrapTooltip.propTypes,
  target: PropTypes.any,
};

export default Tooltip;
