"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handlePlural = exports.pluralize = exports.plurifier = exports.isVowel = exports.getCommaOrAnd = exports.lc = exports.kebabToProper = exports.kebabToCamel = exports.snakeToCamel = void 0;
var snakeToCamel = function (str) { return str === null || str === void 0 ? void 0 : str.toLowerCase().replace(/_([a-z])/g, function (g0, g1) { return g1.toUpperCase(); }); };
exports.snakeToCamel = snakeToCamel;
var kebabToCamel = function (str) { return str === null || str === void 0 ? void 0 : str.toLowerCase().replace(/-([a-z])/g, function (g0, g1) { return g1.toUpperCase(); }); };
exports.kebabToCamel = kebabToCamel;
var kebabToProper = function (str) { return str.toLowerCase().replace(/(-|^)([a-z])/g, function (g0, g1, g2) { return "".concat(g1 === '-' ? ' ' : '').concat(g2.toUpperCase()); }); };
exports.kebabToProper = kebabToProper;
var lc = function (str) { return str.toLowerCase(); };
exports.lc = lc;
var getCommaOrAnd = function (idx, len) { return idx === len - 2 && ' and ' || idx !== len - 1 && ', ' || ''; };
exports.getCommaOrAnd = getCommaOrAnd;
var isVowel = function (letter) { return ['a', 'e', 'i', 'o', 'u'].indexOf(letter.toLowerCase()) !== -1; };
exports.isVowel = isVowel;
var plurifier = function (singularStr) { return singularStr.endsWith('y') && !(0, exports.isVowel)(singularStr.charAt(singularStr.length - 2))
    ? singularStr.replace(/y$/, 'ies')
    : "".concat(singularStr, "s"); };
exports.plurifier = plurifier;
var pluralize = function (amnt, word, wordMany) { return amnt !== 1 ? wordMany || (0, exports.plurifier)(word) : word; };
exports.pluralize = pluralize;
var handlePlural = function (word1, wordPlural) { return function (amount, textFor0) {
    if (textFor0 === void 0) { textFor0 = '0'; }
    return "".concat(amount || textFor0, " ").concat((0, exports.pluralize)(amount, word1, wordPlural));
}; };
exports.handlePlural = handlePlural;
