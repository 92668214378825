"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var styles_1 = require("@mui/material/styles");
var calculateKeyInsights_1 = require("utils/calculateKeyInsights");
var fieldProcessors_1 = require("../../../utils/fieldProcessors");
var PREFIX = 'RatingVerb';
var classes = {
    green: "".concat(PREFIX, "-green"),
    red: "".concat(PREFIX, "-red"),
};
var Root = (0, styles_1.styled)('strong')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b[".".concat(classes.green)] = {
            color: theme.palette.success.dark,
        },
        _b[".".concat(classes.red)] = {
            color: theme.palette.secondary.dark,
        },
        _b);
});
var RatingVerb = function (_a) {
    var rating = _a.rating, isCapitalized = _a.isCapitalized;
    return ((0, jsx_runtime_1.jsx)(Root, __assign({ className: rating > 3.7 ? classes.green : classes.red }, { children: isCapitalized ? (0, fieldProcessors_1.capitalize)((0, calculateKeyInsights_1.getDisplayKeywordByAvgRating)(rating)) : (0, calculateKeyInsights_1.getDisplayKeywordByAvgRating)(rating) })));
};
exports.default = RatingVerb;
