"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var end2end_1 = require("@hudled/end2end");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var HoCs_1 = require("utils/HoCs");
var single_1 = require("utils/selectors/single");
var selectors_1 = require("../Onboarding/selectors");
var RedirectFromHome = function () {
    var _a, _b;
    var profile = (0, react_redux_1.useSelector)(single_1.selectProfile);
    var company = (0, react_redux_1.useSelector)(single_1.selectMyCompany);
    var onboardingProgress = (0, react_redux_1.useSelector)(selectors_1.selectOnboardingProgress);
    var companyHasSetAccOnbStep = (_a = company.completedOnboardingSteps) === null || _a === void 0 ? void 0 : _a.includes('SET_ACCOUNTING');
    var profileHasWelcomeStep = (_b = profile.completedOnboardingSteps) === null || _b === void 0 ? void 0 : _b.includes('WELCOME_STEP');
    var companyHasSupportedAccSoft = company.hasAccountingConnection || company.hasUploadedCsv;
    if (company.hasRecharglyAccess) {
        return (0, jsx_runtime_1.jsx)(react_router_dom_1.Redirect, { to: "/recharges/setup" });
    }
    if (end2end_1.IS_RECHARGLY) {
        return (0, jsx_runtime_1.jsx)(react_router_dom_1.Redirect, { to: "/not-found" });
    }
    if (!companyHasSetAccOnbStep || !profileHasWelcomeStep) {
        return (0, jsx_runtime_1.jsx)(react_router_dom_1.Redirect, { to: "/welcome" });
    }
    if (profile.isAccountant && company.isAccountingFirm) {
        return (0, jsx_runtime_1.jsx)(react_router_dom_1.Redirect, { to: onboardingProgress.stepsDone >= onboardingProgress.totalSteps ? '/accountant/my-customers' : '/onboarding' });
    }
    if (!companyHasSupportedAccSoft && !company.serviceStatsCount) {
        return (0, jsx_runtime_1.jsx)(react_router_dom_1.Redirect, { to: "/onboarding" });
    }
    return (0, jsx_runtime_1.jsx)(react_router_dom_1.Redirect, { to: "/dashboard" });
};
exports.default = (0, HoCs_1.HasCompanyHoC)(RedirectFromHome);
