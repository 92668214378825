"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_FONT_SIZE_SM = void 0;
var material_1 = require("@mui/material");
var system_1 = require("@mui/system");
var general_1 = require("utils/general");
exports.DEFAULT_FONT_SIZE_SM = 1.75;
var Table = (0, system_1.styled)(material_1.Box, {
    shouldForwardProp: (0, general_1.omitProps)('sizing', 'hasActionCell', 'pr', 'overflow'),
})(function (_a) {
    var theme = _a.theme, sizing = _a.sizing, hasActionCell = _a.hasActionCell, pr = _a.pr, overflow = _a.overflow;
    return (__assign(__assign({ height: '100%', color: theme.palette.riverstone, overflow: overflow || 'auto', fontSize: sizing === 'dense' ? theme.spacing(exports.DEFAULT_FONT_SIZE_SM) : undefined }, (hasActionCell && { marginRight: theme.spacing(-6) })), { position: 'relative', paddingRight: theme.spacing(hasActionCell ? 0 : (pr !== null && pr !== void 0 ? pr : 2)), '::-webkit-scrollbar': {
            width: 0,
            background: 'transparent',
        } }));
});
exports.default = Table;
