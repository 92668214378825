"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var end2end_1 = require("@hudled/end2end");
var constants_1 = require("containers/TableMaker/constants");
var XL = constants_1.CellSize.XL, LG = constants_1.CellSize.LG;
var tableConf = {
    headersMap: {
        addedOn: 'Added',
    },
    extractors: {
        addedOn: function (item) { var _a; return (_a = item.addedOn) === null || _a === void 0 ? void 0 : _a.toDate(); },
    },
    cells: [
        { field: 'method', cell: { width: XL }, truncate: {} },
        { field: 'addedOn', cell: { width: LG, growCell: true }, renderField: function (v) { return (0, end2end_1.getISODate)(v); } },
        { field: 'status', cell: { width: LG, textAlign: 'right' } },
    ],
};
exports.default = tableConf;
