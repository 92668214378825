"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var PREFIX = 'ProgressBar';
var classes = {
    customProgress: "".concat(PREFIX, "-customProgress"),
    customBar: "".concat(PREFIX, "-customBar"),
    customFill: "".concat(PREFIX, "-customFill"),
};
var Root = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b[".".concat(classes.customProgress)] = {
            height: theme.spacing(1),
            borderRadius: theme.spacing(1),
        },
        _b[".".concat(classes.customBar)] = {
            background: theme.palette.grey.A100,
        },
        _b[".".concat(classes.customFill)] = function (_a) {
            var fill = _a.fill;
            return ({
                background: fill || theme.palette.primary.main,
                borderRadius: theme.spacing(1),
            });
        },
        _b);
});
var ProgressBar = function (_a) {
    var progress = _a.progress, rest = __rest(_a, ["progress"]);
    return ((0, jsx_runtime_1.jsx)(Root, { children: (0, jsx_runtime_1.jsx)(material_1.LinearProgress, __assign({}, rest, { classes: {
                root: classes.customProgress,
                bar1Determinate: classes.customFill,
                determinate: classes.customBar,
            }, value: progress, variant: "determinate" })) }));
};
exports.default = ProgressBar;
