"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonStyles = void 0;
var buttonStyles = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    var theme = _a.theme, ownerState = _a.ownerState;
    return ({
        textTransform: 'none',
        borderRadius: theme.button.borderRadius,
        lineHeight: '24px',
        position: 'relative',
        fontWeight: theme.typography.fontWeightRegular,
        background: (_c = (_b = theme.palette[ownerState.color]) === null || _b === void 0 ? void 0 : _b[ownerState.variant]) === null || _c === void 0 ? void 0 : _c.backgroundColor,
        color: (_e = (_d = theme.palette[ownerState.color]) === null || _d === void 0 ? void 0 : _d[ownerState.variant]) === null || _e === void 0 ? void 0 : _e.color,
        border: ownerState.variant === 'outlined'
            && "".concat(theme.button.borderWidth, "px solid ").concat((_g = (_f = theme.palette[ownerState.color]) === null || _f === void 0 ? void 0 : _f[ownerState.variant]) === null || _g === void 0 ? void 0 : _g.color),
        ':hover': {
            background: (_j = (_h = theme.palette[ownerState.color]) === null || _h === void 0 ? void 0 : _h[ownerState.variant]) === null || _j === void 0 ? void 0 : _j.hover.backgroundColor,
            color: (_l = (_k = theme.palette[ownerState.color]) === null || _k === void 0 ? void 0 : _k[ownerState.variant]) === null || _l === void 0 ? void 0 : _l.hover.color,
        },
        ':active': {
            background: (_o = (_m = theme.palette[ownerState.color]) === null || _m === void 0 ? void 0 : _m[ownerState.variant]) === null || _o === void 0 ? void 0 : _o.pressed.backgroundColor,
            color: (_q = (_p = theme.palette[ownerState.color]) === null || _p === void 0 ? void 0 : _p[ownerState.variant]) === null || _q === void 0 ? void 0 : _q.pressed.color,
        },
        ':disabled': {
            background: (_s = (_r = theme.palette[ownerState.color]) === null || _r === void 0 ? void 0 : _r[ownerState.variant]) === null || _s === void 0 ? void 0 : _s.disabled.backgroundColor,
            color: (_u = (_t = theme.palette[ownerState.color]) === null || _t === void 0 ? void 0 : _t[ownerState.variant]) === null || _u === void 0 ? void 0 : _u.disabled.color,
            border: ownerState.variant === 'outlined'
                && "".concat(theme.button.borderWidth, "px solid ").concat((_w = (_v = theme.palette[ownerState.color]) === null || _v === void 0 ? void 0 : _v[ownerState.variant]) === null || _w === void 0 ? void 0 : _w.disabled.borderColor),
            cursor: 'not-allowed',
            pointerEvents: 'all',
            '& > .svg-inline--fa.fa-spinner-third': {
                color: (_x = theme.palette[ownerState.color]) === null || _x === void 0 ? void 0 : _x.dark,
            },
        },
        ':focus-visible': __assign({}, theme.focusVisibleStyles),
        '.MuiButton-iconSizeSmall': {
            '&.MuiButton-startIcon>*:nth-of-type(1)': {
                fontSize: 12,
            },
        },
        '.MuiButton-iconSizeMedium, .MuiButton-iconSizeLarge': {
            '&.MuiButton-startIcon>*:nth-of-type(1)': {
                fontSize: theme.typography.body1.fontSize,
            },
        },
        '& .MuiButton-startIcon': {
            '&.MuiButton-iconSizeSmall > *:nth-of-type(1)': {
                fontSize: theme.typography.body2.fontSize,
            },
            '&.MuiButton-iconSizeMedium > *:nth-of-type(1),&.MuiButton-iconSizeLarge > *:nth-of-type(1)': {
                fontSize: theme.typography.body1.fontSize,
            },
        },
        '&.MuiButton-blend, &.MuiButton-recharglyBlend': {
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.common.white,
            transition: 'background 300ms linear',
        },
        '&.MuiButton-blend': {
            background: theme.blend[400],
            ':hover': { background: theme.blend[500] },
            ':focus': { background: theme.blend[300] },
            ':disabled': {
                background: "linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), ".concat(theme.blend[300]),
            },
        },
        // To-do: consider refactoring when new theme is defined for Rechargly
        '&.MuiButton-recharglyBlend': {
            background: theme.recharglyBlend[500],
            opacity: '0.9',
            ':hover': { opacity: '1' },
            ':focus': { opacity: '0.8' },
            ':disabled': { opacity: '0.15' },
        },
    });
};
exports.buttonStyles = buttonStyles;
