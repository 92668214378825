"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fillWithNames = void 0;
var end2end_1 = require("@hudled/end2end");
var fillWithNames = function (profile) {
    var _a;
    if (!profile)
        return profile;
    var _b = __read(((_a = profile.displayName) === null || _a === void 0 ? void 0 : _a.split(' ')) || []), firstNameFromDisplayName = _b[0], lastNameArr = _b.slice(1);
    // a temporary solution for the last name until we have lastName field
    return __assign(__assign({}, profile), { firstName: profile.firstName || firstNameFromDisplayName || '', lastName: profile.lastName || lastNameArr.join(' '), displayName: (0, end2end_1.getDisplayName)(profile) });
};
exports.fillWithNames = fillWithNames;
