"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tooltipSectionDefs = void 0;
var utils_1 = require("../utils");
exports.tooltipSectionDefs = [
    {
        heading: 'Tooltip Variants',
        description: 'The Tooltip comes with two variants: light and dark. It uses light as default',
        variations: (0, utils_1.makePropVariations)('Tooltip', 'variant', ['light', 'dark'], [{ prop: 'title', v: 'Test' }]),
    },
    {
        heading: 'Tooltip placements',
        description: 'The Tooltip comes with four placements: top, bottom, left and right. It uses top as default',
        variations: (0, utils_1.makePropVariations)('Tooltip', 'placement', ['top', 'bottom', 'left', 'right'], [{ prop: 'title', v: 'Test' }]),
    },
    {
        heading: 'Arrow options',
        description: 'You can use the arrow prop to give your tooltip an arrow indicating which element it refers to.',
        variations: [
            { component: 'Tooltip', properties: [{ prop: 'arrow', v: true }, { prop: 'title', v: 'Test' }] },
            { component: 'Tooltip', properties: [{ prop: 'arrow', v: true }, { prop: 'title', v: 'Test' }, { prop: 'variant', v: 'dark' }] },
        ],
    },
];
