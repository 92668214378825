"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACC_PLATFORMS_LOGO = exports.getWelcomeOnbSteps = exports.WELCOME_ONB_ACC_FIRM_FLOW = exports.WELCOME_ONB_STEPS_ACCOUNTANTS_CUSTOMER = exports.WELCOME_ONB_STEPS_SHORTENED = exports.WELCOME_ONB_STEPS_MEMBER_NO_TEAM = exports.WELCOME_ONB_STEPS_MEMBER = void 0;
var end2end_1 = require("@hudled/end2end");
var interfaces_1 = require("@hudled/interfaces");
exports.WELCOME_ONB_STEPS_MEMBER = [
    'USER_INTRO',
    'SETUP_TEAM',
    // 'HUDLED_EXPECTATIONS',
    'SAAS_QUIZ',
];
exports.WELCOME_ONB_STEPS_MEMBER_NO_TEAM = exports.WELCOME_ONB_STEPS_MEMBER.filter(function (s) { return s !== 'SETUP_TEAM'; });
exports.WELCOME_ONB_STEPS_SHORTENED = [
    'USER_INTRO',
    'SET_ACCOUNTING',
    'COMPANY_INTRO',
    // 'HUDLED_EXPECTATIONS',
];
exports.WELCOME_ONB_STEPS_ACCOUNTANTS_CUSTOMER = [
    'SET_ACCOUNTING',
    'COMPANY_INTRO',
    'ACC_INVITE_MEMBERS',
];
exports.WELCOME_ONB_ACC_FIRM_FLOW = [
    'USER_INTRO',
    'COMPANY_INTRO',
    'ACC_CUSTOMERS_INFO',
];
var getWelcomeOnbSteps = function (accLvl, isShort, hasAudit, isAccountantFlow) {
    if (!(0, end2end_1.accessLevelCan)(interfaces_1.ACCESS_LEVEL.Admin)(accLvl)) {
        return exports.WELCOME_ONB_STEPS_MEMBER;
    }
    if (interfaces_1.ACCESS_LEVEL.Accountant === accLvl) {
        return exports.WELCOME_ONB_STEPS_ACCOUNTANTS_CUSTOMER;
    }
    if (isAccountantFlow) {
        return accLvl === interfaces_1.ACCESS_LEVEL.Owner ? exports.WELCOME_ONB_ACC_FIRM_FLOW : exports.WELCOME_ONB_ACC_FIRM_FLOW.filter(function (s) { return s !== 'COMPANY_INTRO'; });
    }
    return __spreadArray(__spreadArray([], __read(exports.WELCOME_ONB_STEPS_SHORTENED), false), __read((!isShort ? ['TRACK_SOFTWARE', 'SAAS_QUIZ'] : [])), false).filter(end2end_1.notFalsy);
};
exports.getWelcomeOnbSteps = getWelcomeOnbSteps;
exports.ACC_PLATFORMS_LOGO = {
    Xero: {
        logo: '/xero.svg',
    },
    QuickBooks: {
        logo: '/quickbooks.svg',
    },
    Salesforce: {
        logo: '/finacialForce.svg',
    },
    MYOB: {
        logo: '/myob.svg',
    },
    NetSuite: {
        logo: '/netsuiteOracle.svg',
    },
    Sage: {
        logo: '/sage.svg',
    },
};
