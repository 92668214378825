"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Separator = (0, material_1.styled)(material_1.Divider)(function (_a) {
    var theme = _a.theme;
    return ({
        borderTop: "0px solid ".concat(theme.palette.grey.A100),
        margin: theme.spacing(2, 0),
        color: theme.palette.grey.A100,
    });
});
exports.default = Separator;
