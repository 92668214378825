"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var interfaces_1 = require("@hudled/interfaces");
var styles_1 = require("@mui/styles");
var flatFileButtonStyles_1 = require("common/flatFileButtonStyles");
var general_1 = require("utils/general");
var successButton = {
    backgroundColor: '#FFFFFF',
    color: '#EF5048',
    border: '1px solid #F9B9B6',
    borderRadius: '5px',
    boxShadow: 'none',
    fontWeight: '400',
    ':hover': {
        backgroundColor: 'transparent',
        color: '#EF5048',
    },
};
var greenButton = {
    backgroundColor: '#02AD6B',
    color: '#FFFFFF',
    border: '1px solid #FFFFFF',
    borderRadius: '5px',
    boxShadow: 'none',
    fontWeight: '400',
    ':hover': {
        backgroundColor: '#02AD6B',
        color: '#FFFFFF',
    },
};
var goBackButton = {
    backgroundColor: 'transparent',
    color: '#011627',
    border: 'none',
    borderRadius: '5px',
    boxShadow: 'none',
    fontWeight: '400',
    ':hover': {
        backgroundColor: 'rgba(1, 22, 39, 0.04)',
        color: '#011627',
    },
};
var useDefaultFlatFileSettings = function () {
    var theme = (0, styles_1.useTheme)();
    var primaryButton = (0, flatFileButtonStyles_1.flatFileButtonStyles)({ theme: theme, ownerState: { color: 'primary', variant: 'contained' } });
    var neutralButton = (0, flatFileButtonStyles_1.flatFileButtonStyles)({ theme: theme, ownerState: { color: 'neutral', variant: 'outlined' } });
    return {
        type: 'Transactions',
        title: 'Upload transactions manually',
        disableManualInput: true,
        fields: [
            {
                label: 'Date',
                key: 'date',
                validators: [
                    { validate: 'required' },
                ],
            },
            { label: 'Reference', key: 'reference' },
            {
                label: 'Contact Name',
                key: 'contactName',
                validators: [
                    { validate: 'required' },
                ],
            },
            {
                label: 'Amount',
                key: 'amount',
                validators: [
                    { validate: 'required' },
                ],
            },
            {
                label: 'Currency',
                key: 'currency',
                type: 'select',
                options: (0, general_1.makeValueLabelArray)(interfaces_1.CURRENCY_CODES),
            },
        ],
        managed: true,
        theme: {
            global: {
                textColor: '#011627',
                primaryTextColor: '#011627',
                secondaryTextColor: '#3A4449',
                successColor: '#02AD6B',
                warningColor: '#BD9727',
                fontFamily: 'Fellix',
            },
            buttons: {
                primary: greenButton,
                secondary: goBackButton,
                success: successButton,
                tertiary: primaryButton,
                dropzoneUpload: primaryButton,
                headerMatchYes: successButton,
                headerMatchNo: neutralButton,
                columnMatchConfirm: primaryButton,
                columnMatchConfirmWithDupes: primaryButton,
                columnMatchIgnore: neutralButton,
                columnMatchInclude: successButton,
                columnMatchIncludeDropdown: successButton,
                columnMatchEdit: neutralButton,
                dialogConfirmYes: successButton,
                dialogConfirmNo: neutralButton,
                dialogFinalYes: successButton,
                dialogFinalNo: neutralButton,
                dialogFinalSuccess: successButton,
                dialogFinalError: neutralButton,
                dataSourceCancel: neutralButton,
                dataSourceContinue: successButton,
            },
            header: {
                title: {
                    fontSize: '24px',
                    color: '#3A4449',
                    fontWeight: (600).toString(),
                },
                closeButton: {
                    color: '#011627',
                },
            },
            dialog: {
                root: {
                    backgroundColor: '#FFFFFF',
                    borderRadius: '12px',
                    border: 'none',
                },
                content: {
                    color: '#011627',
                    fontSize: '14px',
                    fontWeight: (400).toString(),
                    border: 'none',
                    padding: '16px 8px',
                },
                footer: {
                    backgroundColor: '#FFFFFF',
                    border: 'none',
                    padding: '16px 8px',
                },
                overlayColor: 'rgba(0, 0, 0, 0.5)',
            },
        },
    };
};
exports.default = useDefaultFlatFileSettings;
