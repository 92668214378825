"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRequestAudit = void 0;
var react_redux_1 = require("react-redux");
var analytics_1 = require("../analytics");
var hooks_1 = require("../hooks");
var single_1 = require("../selectors/single");
var useRequestAudit = function (source) {
    var company = (0, react_redux_1.useSelector)(single_1.selectMyCompany);
    var profile = (0, react_redux_1.useSelector)(single_1.selectProfileWithId);
    var track = (0, analytics_1.usePreparedTracker)('SaaS Audit Requested', { source: source });
    var _a = __read((0, hooks_1.useSubmitter)('companies', company === null || company === void 0 ? void 0 : company.id, { hasRequestedSaasAudit: true }), 2), requestAudit = _a[0], isRequestingAudit = _a[1];
    var _b = __read((0, hooks_1.useSubmitter)('users', profile === null || profile === void 0 ? void 0 : profile.id, { hasRequestedSaasAudit: true }), 2), userRequestAudit = _b[0], isUserRequestingAudit = _b[1];
    return [
        !(company === null || company === void 0 ? void 0 : company.hasRequestedSaasAudit)
            ? function () {
                track();
                requestAudit();
                userRequestAudit();
            }
            : undefined,
        isRequestingAudit || isUserRequestingAudit,
        !!(company === null || company === void 0 ? void 0 : company.hasRequestedSaasAudit),
    ];
};
exports.useRequestAudit = useRequestAudit;
