"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Banner = (0, material_1.styled)(material_1.Box)(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: '#edf0f2',
        borderRadius: theme.shape.borderRadius,
        minHeight: 240,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        zIndex: 1,
        textAlign: 'center',
        margin: theme.spacing(2, 0),
    });
});
exports.default = Banner;
