"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spendRangeOptions = exports.totalSpendGroupCheck = exports.ownersCheck = exports.makeTeamOption = exports.teamsCheck = exports.makeServiceOption = exports.toolGroupCheck = void 0;
// group: Tools
exports.toolGroupCheck = { group: 'Tools', checkFn: function (sS) { return function (filter) { return sS.serviceId === filter.value; }; } };
var makeServiceOption = function (stat) {
    var _a, _b;
    return ({ value: stat.serviceId, label: ((_a = stat.service) === null || _a === void 0 ? void 0 : _a.niceName) || ((_b = stat.service) === null || _b === void 0 ? void 0 : _b.name), category: 'Tools' });
};
exports.makeServiceOption = makeServiceOption;
// group: Teams
exports.teamsCheck = {
    group: 'Teams', checkFn: function (sS) { return function (f) { var _a; return !!((_a = sS.companyTeamIds) === null || _a === void 0 ? void 0 : _a.includes(f.value)); }; },
};
var makeTeamOption = function (_a) {
    var id = _a.id, name = _a.name;
    return ({ value: id, label: name, category: 'Teams' });
};
exports.makeTeamOption = makeTeamOption;
// group: Owners
exports.ownersCheck = { group: 'Owners', checkFn: function (sS) { return function (f) { return sS.ownerId === f.value; }; } };
// group: Total Spend Range
exports.totalSpendGroupCheck = {
    group: 'Total Spend Range',
    checkFn: function (sS) { return function (filter) {
        var _a, _b;
        return ((sS.calculatedAnnualSpend || 0) <= ((_a = filter.value) === null || _a === void 0 ? void 0 : _a.max) && (sS.calculatedAnnualSpend || 0) >= ((_b = filter.value) === null || _b === void 0 ? void 0 : _b.min));
    }; },
};
exports.spendRangeOptions = [
    { value: { min: 0, max: 100 }, label: '$0 to $100', category: 'Total Spend Range' },
    { value: { min: 101, max: 1000 }, label: '$101 to $1,000', category: 'Total Spend Range' },
    { value: { min: 1001, max: 10000 }, label: '$1,001 to $10,000', category: 'Total Spend Range' },
    { value: { min: 10001, max: 100000 }, label: '> $10,001', category: 'Total Spend Range' },
];
