"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeAuthorizeUri = void 0;
var end2end_1 = require("@hudled/end2end");
var _a = window.location, origin = _a.origin, host = _a.host;
var makeAuthorizeUri = function (authorizeUriTemplate, redirectPath, app, toOrigin) {
    var _a, _b;
    if (toOrigin === void 0) { toOrigin = origin; }
    var returnUrl = "".concat(toOrigin, "/oauth").concat(toOrigin !== origin ? '-origin' : '', "/").concat(app);
    return ((_b = (_a = authorizeUriTemplate === null || authorizeUriTemplate === void 0 ? void 0 : authorizeUriTemplate.replace(end2end_1.OAUTH_DOMAIN_CODE_PLACEHOLDER, (0, end2end_1.getDomainCode)(host))) === null || _a === void 0 ? void 0 : _a.replace(end2end_1.OAUTH_RETURN_URL_PLACEHOLDER, encodeURIComponent(returnUrl))) === null || _b === void 0 ? void 0 : _b.replace(end2end_1.OAUTH_REDIRECT_PATH_PLACEHOLDER, encodeURIComponent(redirectPath || ''))) || '';
};
exports.makeAuthorizeUri = makeAuthorizeUri;
