"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.tabStyles = void 0;
var tabStyles = function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: theme.typography.body1.fontSize,
        textTransform: 'none',
        minWidth: 'auto',
        height: theme.spacing(4),
        minHeight: theme.spacing(4),
        margin: theme.spacing(0, 2),
        padding: theme.spacing(1.5, 0.25),
        color: '#83949C',
        fontWeight: theme.typography.fontWeightRegular,
        border: 'none',
        span: { borderBottom: '1px solid transparent' },
        'span, svg': { paddingBottom: theme.spacing(0.625) },
        position: 'relative',
        ':hover': {
            color: 'inherit',
            textDecoration: 'none',
            span: {
                textShadow: "0 0 1px ".concat(theme.palette.charcoal),
                color: theme.palette.charcoal,
            },
        },
        ':focus-visible': __assign(__assign({}, theme.focusVisibleStyles), { minHeight: theme.spacing(2), height: theme.spacing(3), padding: theme.spacing(1, 0.25), marginTop: theme.spacing(0.5), borderRadius: 2 }),
        '&.active span': {
            color: theme.palette.charcoal,
            textShadow: "0 0 1px ".concat(theme.palette.charcoal),
            borderBottomColor: theme.palette.primary.main,
        },
        svg: { marginRight: theme.spacing(1.5) },
    });
};
exports.tabStyles = tabStyles;
