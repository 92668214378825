"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var end2end_1 = require("@hudled/end2end");
var hooks_1 = require("utils/hooks");
var ConnectionSuccessMsg = function () {
    var _a;
    var provider = (0, hooks_1.useQParam)('provider');
    var accountingSystem = (_a = (0, end2end_1.getProviderAsNice)(provider)) !== null && _a !== void 0 ? _a : 'accounting platform';
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["We have securely connected Hudled with your ", accountingSystem, " account.", ' ', "We\u2019re processing your transactions and detecting software spend."] }));
};
exports.default = ConnectionSuccessMsg;
