"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dropzoneSectionDef = void 0;
exports.dropzoneSectionDef = [
    {
        heading: 'Dropzone',
        description: 'A dropzone is a box that allows users to upload files by dragging and dropping them onto the box.',
        vertical: true,
        variations: [
            { component: 'FieldDropzone', properties: [{ prop: 'name', v: 'sample' }], noChildren: true },
        ],
    },
    {
        heading: 'accept',
        description: 'Set accepted file type. See showOpenFilePicker on MDN for the types',
        vertical: true,
        variations: [
            {
                component: 'FieldDropzone',
                properties: [{ prop: 'name', v: 'sample' }, { prop: 'accept', v: { 'image/*': ['.png', '.jpg'] } }],
                noChildren: true,
            },
        ],
    },
    {
        heading: 'maxFiles',
        description: 'Set maximum number of files that can be uploaded',
        vertical: true,
        variations: [
            { component: 'FieldDropzone', properties: [{ prop: 'name', v: 'sample' }, { prop: 'maxFiles', v: 3 }], noChildren: true },
        ],
    },
    {
        heading: 'disabled',
        description: 'Disables the dropzone. It will not accept any files.',
        vertical: true,
        variations: [
            { component: 'FieldDropzone', properties: [{ prop: 'name', v: 'sample' }, { prop: 'disabled', v: true }], noChildren: true },
        ],
    },
    {
        heading: 'boxStyles',
        description: 'Set styles for the dropzone box',
        vertical: true,
        variations: [
            {
                component: 'FieldDropzone',
                properties: [{ prop: 'name', v: 'sample' }, { prop: 'boxStyles', v: { minHeight: 75, minWidth: 300 } }],
                noChildren: true,
            },
        ],
    },
];
