"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var loadable = function (importFunc, _a) {
    var _b = _a === void 0 ? { fallback: null } : _a, _c = _b.fallback, fallback = _c === void 0 ? null : _c;
    var LazyComponent = (0, react_1.lazy)(importFunc);
    return function (props) { return ((0, jsx_runtime_1.jsx)(react_1.Suspense, __assign({ fallback: fallback }, { children: (0, jsx_runtime_1.jsx)(LazyComponent, __assign({}, props)) }))); };
};
exports.default = loadable;
