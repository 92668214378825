"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var styles_1 = require("@mui/material/styles");
var Cell_1 = require("atomicComponents/molecules/Table/Cell");
var general_1 = require("utils/general");
var Wrapper = (0, styles_1.styled)('div', { shouldForwardProp: (0, general_1.omitProps)('sizing') })(function (_a) {
    var theme = _a.theme, _b = _a.sizing, sizing = _b === void 0 ? 'regular' : _b;
    return (__assign({ color: theme.palette.riverstone, fontStyle: 'italic', position: 'sticky', left: 0, zIndex: 1 }, sizing && Cell_1.CELL_SIZE_MAPPER[sizing]));
});
var NoItemsFound = function (_a) {
    var _b = _a.sizing, sizing = _b === void 0 ? 'regular' : _b;
    return ((0, jsx_runtime_1.jsx)(Wrapper, __assign({ sizing: sizing }, { children: "No items found" })));
};
exports.default = NoItemsFound;
