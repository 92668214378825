"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var styles_1 = require("@mui/material/styles");
var IntroWrap = (0, styles_1.styled)('iframe')(function (_a) {
    var theme = _a.theme;
    return ({
        marginBottom: theme.spacing(6),
    });
});
var ServiceIntro = function (_a) {
    var videoData = _a.videoData, serviceName = _a.serviceName;
    return ((0, jsx_runtime_1.jsx)(IntroWrap, { title: "Intro to ".concat(serviceName), width: "100%", height: "360", src: videoData === null || videoData === void 0 ? void 0 : videoData.embed_url, frameBorder: "0", allow: "encrypted-media; picture-in-picture", allowFullScreen: true }));
};
exports.default = ServiceIntro;
