"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var system_1 = require("@mui/system");
var NavTabsContainer = (0, system_1.styled)(material_1.Box)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(2, 4, 0, 4.5),
        backgroundColor: theme.palette.common.white,
        minHeight: 109,
    });
});
exports.default = NavTabsContainer;
