"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
exports.default = (0, styles_1.styled)('span')(function (_a) {
    var theme = _a.theme;
    return ({
        width: theme.spacing(1),
        height: theme.spacing(1),
        borderRadius: '50%',
        background: theme.palette.primary.main,
        alignSelf: 'center',
        marginLeft: theme.spacing(1),
    });
});
