"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dummyServiceStats = exports.dummyAudit = void 0;
var date_fns_1 = require("date-fns");
var firestore_1 = require("firebase/firestore");
exports.dummyAudit = {
    id: 'DUMMY_AUDIT',
    companyId: 'NONE',
    isPublished: true,
    isActive: true,
    isDummy: true,
    summary: {
        createdDate: firestore_1.Timestamp.now(),
        auditStartDate: firestore_1.Timestamp.fromDate((0, date_fns_1.sub)((0, date_fns_1.startOfMonth)(new Date()), { years: 1, days: 1 })),
        auditEndDate: firestore_1.Timestamp.fromDate((0, date_fns_1.sub)((0, date_fns_1.startOfMonth)(new Date()), { days: 1 })),
        plansOnAnnual: 7,
        totalToolsCount: 75,
        growthEstimationY1: 0.3,
        baseCurrency: 'NZD',
        costPerEmployee: 3365.29299363057,
        analysedTotalSaasSpend: 528351,
        creditsUsed: 0,
        missedSavings: 45179.5105141168,
        analysedToolsCount: 75,
        costPerTool: 7044.68,
    },
    spendOverview: {
        last12Months: {
            missedSavings: 45179.5105141168,
            costPerTool: 6951.98684210526,
            costPerEmployee: 3365.29299363057,
            spend: 528351,
        },
        next12Months: {
            potentialSavings: 66783.0816158479,
            forecast: 607603.65,
        },
    },
    benchmarks: {
        spendLast12Months: { min: 511029, max: 1125456 },
        costPerTool: { max: 9856, min: 4524 },
        missedSavings: { min: 28458, max: 98547 },
        costPerEmployee: { min: 1255, max: 5231 },
    },
    stackHealth: {
        score: 68,
        analysedTools: {
            dIhoD2lFdrBGJKKOPvPJ: {
                missedOpportunityAmount: 0,
                recommendations: [],
                forecastSavings: 0,
                overspendType: 'optimalPrice',
                activeAnnualSpend: 1399.2165,
                last12MonthSpend: 1216.71,
            },
            '1EIHfpU0Oe2w1fwqOnwe': {
                recommendations: [],
                forecastSavings: 0,
                last12MonthSpend: 131.02,
                missedOpportunityAmount: 0,
                activeAnnualSpend: 150.673,
                overspendType: 'optimalPrice',
            },
            '9BioNhuLULapquuRnrhQ': {
                recommendations: [],
                missedOpportunityAmount: 0,
                overspendType: 'optimalPrice',
                forecastSavings: 0,
                last12MonthSpend: 136.27,
                activeAnnualSpend: 156.7105,
            },
            '92KtL4V5vjEFom8IBUh2': {
                overspendType: 'optimalPrice',
                missedOpportunityAmount: 0,
                last12MonthSpend: 0,
                recommendations: [],
                forecastSavings: 0,
                activeAnnualSpend: 0,
            },
            qN5mXdstox5v57nKNXaA: {
                last12MonthSpend: 43.48,
                missedOpportunityAmount: 0,
                activeAnnualSpend: 50.002,
                overspendType: 'optimalPrice',
                recommendations: [],
                forecastSavings: 0,
            },
            UdR2c4J6k5wSQ9AWyCjr: {
                forecastSavings: 0,
                missedOpportunityAmount: 0,
                activeAnnualSpend: 1606.6200720875,
                overspendType: 'optimalPrice',
                last12MonthSpend: 1397.06093225,
                recommendations: [],
            },
            bC8ZiCKrRh5yI5f4l2uS: {
                overspendType: 'optimalPrice',
                activeAnnualSpend: 17.25,
                last12MonthSpend: 15,
                recommendations: [],
                missedOpportunityAmount: 0,
                forecastSavings: 0,
            },
            chsfd7aWw2oEiacFFQRB: {
                last12MonthSpend: 11959.62,
                recommendations: [
                    {
                        actionUrl: 'https://www.notion.so/hudled/Zoom-Overlap-functionality-d5b5ee662c364dbc8ff80697c48ddac7',
                        type: 'Overlap functionality',
                        frequency: 'Recurring',
                        confidence: 'Low',
                        savings: 5511.01135002388,
                    },
                ],
                missedOpportunityAmount: 0,
                overspendType: 'optimalPrice',
                forecastSavings: 5511.01135002388,
                activeAnnualSpend: 29869.9802169316,
            },
            ymEz0WhoZIFSWbNbb54x: {
                recommendations: [],
                last12MonthSpend: 13831.2171764219,
                missedOpportunityAmount: 0,
                overspendType: 'optimalPrice',
                activeAnnualSpend: 13324.9273380065,
                forecastSavings: 0,
            },
            PHdkGySz8M5V7ArW9vpK: {
                activeAnnualSpend: 17852.478340951,
                missedOpportunityAmount: 3244,
                forecastSavings: 5989.63708984242,
                overspendType: 'overspending',
                last12MonthSpend: 11901.55,
                recommendations: [
                    {
                        confidence: 'High',
                        savings: 2744.81854492121,
                        type: 'Monthly to annual',
                        actionUrl: 'https://hudled.notion.site/Airtable-Monthly-to-Annual-b9cc05f91f0e4d01829456f36a97bbf8',
                        frequency: 'Recurring',
                    },
                    {
                        confidence: 'High',
                        type: 'Price hacking',
                        savings: 2744.81854492121,
                        frequency: 'Recurring',
                        actionUrl: 'https://hudled.notion.site/Airtable-Price-hacking-3d5bd48344ae4b6eb4a51a2d0e66772c',
                    },
                    {
                        frequency: 'Recurring',
                        actionUrl: 'https://hudled.notion.site/Airtable-Startup-credits-e3f23f6db4ad49308c36c3ce61f63507',
                        type: 'Free credits',
                        confidence: 'High',
                        savings: 500,
                    },
                ],
            },
            Ui4NUrBTRxj0gwHOGUuI: {
                forecastSavings: 0,
                activeAnnualSpend: 1817.87047525,
                overspendType: 'optimalPrice',
                recommendations: [],
                missedOpportunityAmount: 0,
                last12MonthSpend: 1580.756935,
            },
            cuNaOsuwHpG3DdmUt0OQ: {
                activeAnnualSpend: 156.998,
                last12MonthSpend: 136.52,
                forecastSavings: 0,
                recommendations: [],
                overspendType: 'optimalPrice',
                missedOpportunityAmount: 0,
            },
            XMtVQRv9NUzaenYjZusB: {
                forecastSavings: 0,
                missedOpportunityAmount: 0,
                overspendType: 'optimalPrice',
                activeAnnualSpend: 149.845,
                recommendations: [],
                last12MonthSpend: 130.3,
            },
            '5OERumKHEt9Sy7XWXc3C': {
                overspendType: 'optimalPrice',
                forecastSavings: 0,
                last12MonthSpend: 1568.97,
                recommendations: [],
                missedOpportunityAmount: 0,
                activeAnnualSpend: 1804.3155,
            },
            KYOou7UOQqXc1IrMAoEA: {
                missedOpportunityAmount: 587.98,
                overspendType: 'overspending',
                recommendations: [
                    {
                        type: 'Monthly to annual',
                        actionUrl: 'https://www.notion.so/hudled/HotJar-Monthly-to-Annual-a213d8b973a5452b8a638d50df517b57',
                        confidence: 'High',
                        frequency: 'Recurring',
                        savings: 616.918964458694,
                    },
                ],
                last12MonthSpend: 2939.9,
                activeAnnualSpend: 3009.36080223753,
                forecastSavings: 616.918964458694,
            },
            TGm76gV4zZL8MuenvEdH: {
                forecastSavings: 1406.74744080185,
                overspendType: 'overspending',
                last12MonthSpend: 19538.8189898956,
                missedOpportunityAmount: 976.940949494782,
                activeAnnualSpend: 27448.7305522312,
                recommendations: [
                    {
                        confidence: 'High',
                        frequency: 'Recurring',
                        actionUrl: 'https://www.notion.so/hudled/Adobe-Reseller-Deal-b62d06c2d9604823b2850891f99b9409',
                        savings: 1406.74744080185,
                        type: 'Hudled reseller deal',
                    },
                ],
            },
            UsJB6bduEqcn24evxPWD: {
                forecastSavings: 1711.86313527526,
                recommendations: [
                    {
                        type: 'Monthly to annual',
                        frequency: 'Recurring',
                        savings: 1711.86313527526,
                        confidence: 'High',
                        actionUrl: 'https://hudled.notion.site/Figma-Monthly-to-annual-670231871cfc46469335237c608890fd',
                    },
                ],
                overspendType: 'overspending',
                missedOpportunityAmount: 1201.072,
                last12MonthSpend: 6005.36,
                activeAnnualSpend: 8350.5518793915,
            },
            BBFBtMsDl9vWqddMTLby: {
                overspendType: 'overspending',
                activeAnnualSpend: 8463.79016303977,
                recommendations: [],
                forecastSavings: 0,
                missedOpportunityAmount: 256.0935,
                last12MonthSpend: 3414.58,
            },
            '5e4KQ83Nl5IL8MWw9kwM': {
                forecastSavings: 0,
                overspendType: 'optimalPrice',
                last12MonthSpend: 77.52646,
                activeAnnualSpend: 89.155429,
                missedOpportunityAmount: 0,
                recommendations: [],
            },
            Bv7JNWKsp9e48MuZSf3k: {
                overspendType: 'overspending',
                forecastSavings: 2693.174175,
                recommendations: [
                    {
                        type: 'Hudled reseller deal',
                        savings: 2693.174175,
                        confidence: 'High',
                        frequency: 'Recurring',
                        actionUrl: 'https://hudled.notion.site/Cloudflare-partner-8b2e88bd87cb472d83f19722b8f37925',
                    },
                ],
                activeAnnualSpend: 17516.58,
                missedOpportunityAmount: 1482.84345638,
                last12MonthSpend: 14828.4345638,
            },
            Dm5WG4zNuMaXgj72ACSB: {
                activeAnnualSpend: 1063.0945,
                forecastSavings: 0,
                recommendations: [],
                missedOpportunityAmount: 0,
                last12MonthSpend: 924.43,
                overspendType: 'optimalPrice',
            },
            '9JRbrloOi5JPDZtCw5QB': {
                missedOpportunityAmount: 0,
                forecastSavings: 0,
                recommendations: [],
                activeAnnualSpend: 389.6775,
                last12MonthSpend: 338.85,
                overspendType: 'optimalPrice',
            },
            QAgjyauPKpGwFhvxELR9: {
                overspendType: 'overspending',
                missedOpportunityAmount: 273.387,
                last12MonthSpend: 2733.87,
                forecastSavings: 0,
                activeAnnualSpend: 3143.9505,
                recommendations: [],
            },
            noEKgsJLJHDPHORuEi9i: {
                activeAnnualSpend: 0,
                missedOpportunityAmount: 0,
                last12MonthSpend: 0,
                forecastSavings: 0,
                overspendType: 'optimalPrice',
                recommendations: [],
            },
            nd6gIG3MgehrZ0D6erOh: {
                last12MonthSpend: 0,
                overspendType: 'optimalPrice',
                missedOpportunityAmount: 0,
                activeAnnualSpend: 0,
                forecastSavings: 0,
                recommendations: [],
            },
            VboLMzzd1UZj2AcixCgM: {
                last12MonthSpend: 1296.92,
                forecastSavings: 0,
                activeAnnualSpend: 1491.458,
                missedOpportunityAmount: 0,
                recommendations: [],
                overspendType: 'optimalPrice',
            },
            avuV3DM6PJjnmXzcpHYU: {
                activeAnnualSpend: 1506.5,
                recommendations: [],
                forecastSavings: 0,
                last12MonthSpend: 1310,
                overspendType: 'overspending',
                missedOpportunityAmount: 131,
            },
            p9kEwws6uOv0wUBbfZ4f: {
                last12MonthSpend: 57.64,
                recommendations: [],
                activeAnnualSpend: 66.286,
                missedOpportunityAmount: 0,
                overspendType: 'optimalPrice',
                forecastSavings: 0,
            },
            dpfOIElpPRn2nURfmnbq: {
                forecastSavings: 0,
                overspendType: 'optimalPrice',
                recommendations: [],
                activeAnnualSpend: 0,
                last12MonthSpend: 0,
                missedOpportunityAmount: 0,
            },
            VKkVTP5djcMLfpGlQXyZ: {
                last12MonthSpend: 804,
                recommendations: [],
                activeAnnualSpend: 924.6,
                missedOpportunityAmount: 0,
                overspendType: 'optimalPrice',
                forecastSavings: 0,
            },
            LPR3j3xQkwv7fNZd51Bk: {
                overspendType: 'optimalPrice',
                recommendations: [],
                activeAnnualSpend: 1485.9955,
                last12MonthSpend: 1292.17,
                missedOpportunityAmount: 0,
                forecastSavings: 0,
            },
            CsdGHWx42Vygh3rqB6SE: {
                overspendType: 'optimalPrice',
                activeAnnualSpend: 1486.168,
                missedOpportunityAmount: 0,
                forecastSavings: 0,
                last12MonthSpend: 1292.32,
                recommendations: [],
            },
            Ux7jN61ItR0myv6lUfil: {
                forecastSavings: 0,
                recommendations: [],
                last12MonthSpend: 1506.647782,
                missedOpportunityAmount: 0,
                activeAnnualSpend: 1732.6449493,
                overspendType: 'optimalPrice',
            },
            CcbhW94gpsAG85QuvZPD: {
                overspendType: 'overspending',
                last12MonthSpend: 273.23,
                missedOpportunityAmount: 13.6615,
                forecastSavings: 0,
                recommendations: [],
                activeAnnualSpend: 314.2145,
            },
            Yzqpk8XNm59BW3M6sZFu: {
                forecastSavings: 0,
                last12MonthSpend: 1371.77,
                missedOpportunityAmount: 68.5885,
                overspendType: 'overspending',
                recommendations: [],
                activeAnnualSpend: 1577.5355,
            },
            ChBwat6G7bUNf3xXE2gv: {
                forecastSavings: 0,
                recommendations: [],
                overspendType: 'optimalPrice',
                last12MonthSpend: 2812.38,
                activeAnnualSpend: 3264.44368647247,
                missedOpportunityAmount: 0,
            },
            pKyViiqa3QUMJEayUwlj: {
                missedOpportunityAmount: 0,
                last12MonthSpend: 0,
                forecastSavings: 0,
                activeAnnualSpend: 0,
                overspendType: 'optimalPrice',
                recommendations: [],
            },
            juSxmRhMDVqZrHPp3atL: {
                last12MonthSpend: 6702.89,
                missedOpportunityAmount: 1340.578,
                recommendations: [
                    {
                        confidence: 'High',
                        frequency: 'Recurring',
                        actionUrl: 'https://www.notion.so/hudled/Semrush-Monthly-to-Annual-7f2f8e7546d54fa4ad79f54df477355b',
                        savings: 1714.89018214066,
                        type: 'Monthly to annual',
                    },
                ],
                forecastSavings: 1714.89018214066,
                overspendType: 'overspending',
                activeAnnualSpend: 9294.79773517975,
            },
            eHOUVJbE1nPWowof873e: {
                last12MonthSpend: 72,
                recommendations: [],
                activeAnnualSpend: 82.8,
                overspendType: 'optimalPrice',
                missedOpportunityAmount: 0,
                forecastSavings: 0,
            },
            PCkBVHLKAedmuzabc4GD: {
                overspendType: 'optimalPrice',
                forecastSavings: 0,
                last12MonthSpend: 128.0990616,
                activeAnnualSpend: 147.31392084,
                missedOpportunityAmount: 0,
                recommendations: [],
            },
            '6wCISzc8Lwtyq3jN1J53': {
                forecastSavings: 0,
                recommendations: [],
                missedOpportunityAmount: 0,
                last12MonthSpend: 1980,
                activeAnnualSpend: 2277,
                overspendType: 'optimalPrice',
            },
            jtHNLxzN3efwbRJhauil: {
                missedOpportunityAmount: 0,
                activeAnnualSpend: 119.9105,
                last12MonthSpend: 104.27,
                overspendType: 'optimalPrice',
                forecastSavings: 0,
                recommendations: [],
            },
            bLeYfME82Bm9Sayrhpt6: {
                forecastSavings: 20244.5013217136,
                overspendType: 'overspending',
                last12MonthSpend: 110505.861397731,
                recommendations: [
                    {
                        actionUrl: 'https://hudled.notion.site/Hubspot-renegotiate-contract-0316de4fcda84c788d52e6d2454083a8',
                        frequency: 'Recurring',
                        type: 'Negotiate better price',
                        savings: 20244.5013217136,
                        confidence: 'Medium',
                    },
                ],
                activeAnnualSpend: 131671.553311959,
                missedOpportunityAmount: 20224,
            },
            KIG5nsJqk0dQ3RhVWDnV: {
                overspendType: 'optimalPrice',
                recommendations: [],
                last12MonthSpend: 1043.873409,
                forecastSavings: 0,
                activeAnnualSpend: 1200.45442035,
                missedOpportunityAmount: 0,
            },
            uZvLTtvcD8q4h1hwvGfY: {
                last12MonthSpend: 2769.63,
                recommendations: [],
                overspendType: 'optimalPrice',
                missedOpportunityAmount: 0,
                activeAnnualSpend: 3185.0745,
                forecastSavings: 0,
            },
            Za7zYXwQNfvnVtLbFP43: {
                last12MonthSpend: 7426.48,
                forecastSavings: 8565.39410328126,
                overspendType: 'overspending',
                recommendations: [
                    {
                        frequency: 'One-off',
                        savings: 8080.56047479364,
                        confidence: 'Low',
                        actionUrl: 'https://hudled.notion.site/Storage-Overlap-f23c46bfca7846bda3aaad5b2ba3f14b',
                        type: 'Overlap functionality',
                    },
                    {
                        savings: 484.833628487618,
                        type: 'Hudled reseller deal',
                        actionUrl: 'https://www.notion.so/hudled/Dropbox-Hudled-reseller-deal-1abe826fb66b4b16a5f1c16be8bc4746',
                        frequency: 'One-off',
                        confidence: 'Low',
                    },
                ],
                missedOpportunityAmount: 1485.296,
                activeAnnualSpend: 7883.47363394502,
            },
            Ssqvm4q31UoJqVO2ju2t: {
                overspendType: 'optimalPrice',
                forecastSavings: 0,
                last12MonthSpend: 450.83,
                missedOpportunityAmount: 0,
                activeAnnualSpend: 518.4545,
                recommendations: [],
            },
            xBGTPEVMy4LeSaq5hgHn: {
                forecastSavings: 5368.335,
                missedOpportunityAmount: 3867.509355111,
                recommendations: [
                    {
                        savings: 5368.335,
                        actionUrl: 'https://hudled.notion.site/Google-Workspace-partner-e8b94d86732c4dc3b22512f8a8c2034b',
                        type: 'Hudled reseller deal',
                        confidence: 'High',
                        frequency: 'Recurring',
                    },
                ],
                overspendType: 'overspending',
                last12MonthSpend: 38675.09355111,
                activeAnnualSpend: 52374,
            },
            zQHuKk4g2mvshStJDGdj: {
                missedOpportunityAmount: 0,
                recommendations: [],
                forecastSavings: 0,
                activeAnnualSpend: 230085.42,
                overspendType: 'optimalPrice',
                last12MonthSpend: 73465.24063542,
            },
            NkZXniMwzYbKhAvuNDK7: {
                last12MonthSpend: 0,
                recommendations: [],
                overspendType: 'optimalPrice',
                missedOpportunityAmount: 0,
                forecastSavings: 0,
                activeAnnualSpend: 0,
            },
            '8XCdB0FKDeot5FMGdg82': {
                last12MonthSpend: 156.54,
                overspendType: 'optimalPrice',
                activeAnnualSpend: 180.021,
                missedOpportunityAmount: 0,
                recommendations: [],
                forecastSavings: 0,
            },
            LFaEZE1QoHMkzcoqLcPm: {
                activeAnnualSpend: 2020.0095,
                overspendType: 'optimalPrice',
                forecastSavings: 0,
                last12MonthSpend: 1756.53,
                recommendations: [],
                missedOpportunityAmount: 0,
            },
            Cc4KaSbzMUWJnfAYgk6p: {
                last12MonthSpend: 155949.87681972,
                missedOpportunityAmount: 7797.493840986,
                overspendType: 'overspending',
                activeAnnualSpend: 185050.822020602,
                forecastSavings: 9483.85462855584,
                recommendations: [
                    {
                        confidence: 'Medium',
                        frequency: 'Recurring',
                        savings: 9483.85462855584,
                        type: 'Exclusive Hudled deal',
                        actionUrl: 'https://hudled.notion.site/AWS-optimize-contract-66bfe441502a44378d78eb905225fff6',
                    },
                ],
            },
            pllTZTNhrCOaNG97n7l9: {
                missedOpportunityAmount: 0,
                forecastSavings: 0,
                recommendations: [],
                activeAnnualSpend: 483.667,
                overspendType: 'optimalPrice',
                last12MonthSpend: 420.58,
            },
            lGQV9gfOY9KLoT9fRtIE: {
                activeAnnualSpend: 540.0055,
                missedOpportunityAmount: 0,
                last12MonthSpend: 469.57,
                forecastSavings: 0,
                overspendType: 'optimalPrice',
                recommendations: [],
            },
            '4qaDdfG5YTzFmNLwUgMR': {
                last12MonthSpend: 744.53,
                recommendations: [],
                activeAnnualSpend: 856.2095,
                missedOpportunityAmount: 0,
                forecastSavings: 0,
                overspendType: 'optimalPrice',
            },
            Zj3ZlkucJNCR28lCscNe: {
                recommendations: [],
                missedOpportunityAmount: 0,
                overspendType: 'optimalPrice',
                activeAnnualSpend: 32.0505,
                last12MonthSpend: 27.87,
                forecastSavings: 0,
            },
            R8MQFO8iyv5rFjsOimH5: {
                recommendations: [],
                overspendType: 'optimalPrice',
                activeAnnualSpend: 187.5765,
                forecastSavings: 0,
                last12MonthSpend: 163.11,
                missedOpportunityAmount: 0,
            },
            XT7kbzWtB5QH43Dhsa9m: {
                recommendations: [],
                forecastSavings: 0,
                overspendType: 'optimalPrice',
                activeAnnualSpend: 38.7205,
                missedOpportunityAmount: 0,
                last12MonthSpend: 33.67,
            },
            IHhUlpDe8w0q9S5EJnpv: {
                last12MonthSpend: 4744.33,
                overspendType: 'optimalPrice',
                missedOpportunityAmount: 0,
                recommendations: [],
                activeAnnualSpend: 5371.34047342929,
                forecastSavings: 0,
            },
            '1IoJFAK1h3dEWSmlOerT': {
                forecastSavings: 0,
                missedOpportunityAmount: 0,
                activeAnnualSpend: 959.1,
                overspendType: 'optimalPrice',
                last12MonthSpend: 834,
                recommendations: [],
            },
            MP6Hdn9SGFYTk3vJQpgC: {
                missedOpportunityAmount: 0,
                last12MonthSpend: 113.83,
                forecastSavings: 0,
                activeAnnualSpend: 130.9045,
                overspendType: 'optimalPrice',
                recommendations: [],
            },
            tn3TlRTNBUwSHrDhkqH0: {
                overspendType: 'optimalPrice',
                recommendations: [],
                activeAnnualSpend: 974.9815,
                missedOpportunityAmount: 0,
                forecastSavings: 0,
                last12MonthSpend: 847.81,
            },
            p4FhPlf435lzjdfqmahQ: {
                last12MonthSpend: 147.83,
                recommendations: [],
                forecastSavings: 0,
                overspendType: 'optimalPrice',
                activeAnnualSpend: 170.0045,
                missedOpportunityAmount: 0,
            },
            VwUbStfkZ43aHLzjuEmC: {
                last12MonthSpend: 8408.75904,
                overspendType: 'overspending',
                recommendations: [],
                forecastSavings: 0,
                missedOpportunityAmount: 840.875904,
                activeAnnualSpend: 8352,
            },
            GnpGMM5BIsaPYglMYkfn: {
                overspendType: 'optimalPrice',
                activeAnnualSpend: 51.6005,
                recommendations: [],
                last12MonthSpend: 44.87,
                missedOpportunityAmount: 0,
                forecastSavings: 0,
            },
            T4HgUJbXV89zL2GtqDcp: {
                last12MonthSpend: 0,
                recommendations: [],
                missedOpportunityAmount: 0,
                forecastSavings: 0,
                overspendType: 'optimalPrice',
                activeAnnualSpend: 0,
            },
            ANfkBUorihvuUnYkG0RK: {
                missedOpportunityAmount: 1053.7872,
                overspendType: 'overspending',
                last12MonthSpend: 6586.17,
                activeAnnualSpend: 19264.5146326489,
                forecastSavings: 3159.38039975442,
                recommendations: [
                    {
                        type: 'Monthly to annual',
                        actionUrl: 'https://hudled.notion.site/Slack-monthly-to-annual-8e41acd5fb794cb3a3387a21889bdf30',
                        savings: 3159.38039975442,
                        frequency: 'Recurring',
                        confidence: 'High',
                    },
                ],
            },
            '7AyuV6rcnPJGdHjUhEtg': {
                forecastSavings: 317.373825,
                missedOpportunityAmount: 334.403308145,
                recommendations: [
                    {
                        savings: 317.373825,
                        frequency: 'Recurring',
                        actionUrl: 'https://www.notion.so/hudled/New-Relic-Reseller-Deal-f6b253ed512748099fefafb19c4b96fa',
                        confidence: 'High',
                        type: 'Monthly to annual',
                    },
                ],
                activeAnnualSpend: 3096.33,
                overspendType: 'overspending',
                last12MonthSpend: 3344.03308145,
            },
            hPvCE54lvUvpahFOc98S: {
                forecastSavings: 0,
                last12MonthSpend: 2303.17,
                missedOpportunityAmount: 0,
                activeAnnualSpend: 716.938399617982,
                recommendations: [],
                overspendType: 'optimalPrice',
            },
            foTBb9kUdR2EnNQ10l25: {
                forecastSavings: 0,
                missedOpportunityAmount: 0,
                overspendType: 'optimalPrice',
                activeAnnualSpend: 0,
                recommendations: [],
                last12MonthSpend: 0,
            },
            Orl2v1SAhpSHXvmqvgd3: {
                recommendations: [],
                missedOpportunityAmount: 0,
                last12MonthSpend: 618.69,
                overspendType: 'optimalPrice',
                activeAnnualSpend: 711.4935,
                forecastSavings: 0,
            },
            Fo455TMkC7XpBk0mSiaZ: {
                missedOpportunityAmount: 0,
                activeAnnualSpend: 346.491548326625,
                forecastSavings: 0,
                overspendType: 'optimalPrice',
                last12MonthSpend: 301.296998544892,
                recommendations: [],
            },
            OaXqdbgJx35zk3Y5VqG0: {
                last12MonthSpend: 259.06,
                recommendations: [],
                forecastSavings: 0,
                missedOpportunityAmount: 0,
                overspendType: 'optimalPrice',
                activeAnnualSpend: 297.919,
            },
        },
    },
};
exports.dummyServiceStats = Object.keys(exports.dummyAudit.stackHealth.analysedTools)
    .map(function (serviceId) { return ({ serviceId: serviceId }); });
