"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var styles_1 = require("@mui/material/styles");
var StyledDropDown = (0, styles_1.styled)('div')({
    position: 'absolute',
    inset: '0px auto auto 0px',
    transform: 'translate(36px, 28px)',
    border: '1px solid #eee',
    minWidth: 220,
    maxWidth: 440,
    background: '#FFFFFF',
    borderRadius: 5,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 2%), 0px 4px 6px rgb(0 0 0 / 6%), 0px 7px 16px rgb(15 15 15 / 8%)',
    cursor: 'pointer',
    padding: '8px 0',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    '& span': {
        cursor: 'default',
        fontFamily: 'Fellix',
        color: 'rgb(182, 197, 205)',
        paddingLeft: 8,
    },
});
var MentionSuggestionsComponent = function (props) {
    var menuItemServices = props.children.filter(function (item) { return item.props.mention.collection === 'services'; });
    var menuItemUsers = props.children.filter(function (item) { return item.props.mention.collection === 'users'; });
    return ((0, jsx_runtime_1.jsxs)(StyledDropDown, { children: [(0, jsx_runtime_1.jsx)("span", { children: "Users" }), menuItemUsers.map(function (item) { return item; }), (0, jsx_runtime_1.jsx)("span", { children: "Tools" }), menuItemServices.map(function (item) { return item; })] }));
};
exports.default = MentionSuggestionsComponent;
