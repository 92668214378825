"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var faSortDown_1 = require("@fortawesome/pro-solid-svg-icons/faSortDown");
var faSortUp_1 = require("@fortawesome/pro-solid-svg-icons/faSortUp");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var styles_1 = require("@mui/material/styles");
var general_1 = require("utils/general");
var SortableArrowsWrapper = (0, styles_1.styled)('span', { shouldForwardProp: (0, general_1.omitProps)('sortOrder') })(function (_a) {
    var theme = _a.theme, sortOrder = _a.sortOrder;
    return ({
        opacity: sortOrder ? 1 : 0,
        transition: 'opacity 250ms ease 0s',
        marginLeft: theme.spacing(0.5),
        zIndex: 1,
    });
});
var SortableArrows = function (_a) {
    var sortOrder = _a.sortOrder;
    var theme = (0, styles_1.useTheme)();
    return ((0, jsx_runtime_1.jsxs)(SortableArrowsWrapper, __assign({ className: "fa-layers SortableArrows", sortOrder: sortOrder }, { children: [sortOrder !== 1
                && ((0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { color: sortOrder === -1 ? theme.palette.primary.main : theme.palette.grey[300], icon: faSortDown_1.faSortDown })), sortOrder !== -1 && ((0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { color: sortOrder === 1 ? theme.palette.primary.main : theme.palette.grey[300], icon: faSortUp_1.faSortUp }))] })));
};
exports.default = SortableArrows;
