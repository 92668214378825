"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonGroupStyles = void 0;
var buttonGroupStyles = function (_a) {
    var _b, _c, _d, _e, _f;
    var theme = _a.theme;
    return ({
        button: {
            color: (_b = theme.palette) === null || _b === void 0 ? void 0 : _b.neutral.contrastText,
            borderColor: (_c = theme.palette) === null || _c === void 0 ? void 0 : _c.grey[300],
            fontWeight: theme.typography.fontWeightMedium,
            '&.MuiButton-sizeSmall': {
                lineHeight: '20px',
                padding: theme.spacing(0.5, 2),
                fontSize: theme.typography.body1.fontSize,
                height: theme.spacing(3.5),
            },
            '&.MuiButton-outlinedInherit': {
                color: (_d = theme.palette) === null || _d === void 0 ? void 0 : _d.charcoal,
                borderColor: (_e = theme.palette) === null || _e === void 0 ? void 0 : _e.obsidian,
                backgroundColor: (_f = theme.palette) === null || _f === void 0 ? void 0 : _f.white,
            },
            '&.MuiButtonGroup-grouped:first-of-type': { borderRightColor: 'inherit' },
            '&.MuiButtonGroup-grouped:not(:first-of-type)': { marginLeft: 0 },
        },
        '.MuiButtonGroup-grouped:hover': { borderColor: 'inherit' },
    });
};
exports.buttonGroupStyles = buttonGroupStyles;
