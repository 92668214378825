"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.skeletonStyles = void 0;
var skeletonStyles = function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: theme.palette.grey[500],
        border: "2px solid ".concat(theme.palette.common.white),
        boxSizing: 'content-box',
    });
};
exports.skeletonStyles = skeletonStyles;
