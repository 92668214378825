"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeCodeOptions = exports.getXeroAccountDescription = void 0;
var getXeroAccountDescription = function (xeroAccount) { return xeroAccount && (xeroAccount.Code ? "".concat(xeroAccount.Code, " - ").concat(xeroAccount.Name) : xeroAccount.Name); };
exports.getXeroAccountDescription = getXeroAccountDescription;
var makeCodeOptions = function (xeroAccount) {
    var _a;
    return ({ value: xeroAccount.AccountID, label: (_a = (0, exports.getXeroAccountDescription)(xeroAccount)) !== null && _a !== void 0 ? _a : '' });
};
exports.makeCodeOptions = makeCodeOptions;
