"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.METHOD_NICE_MAPPER = exports.TRANSACTION_SOURCE_PLATFORM_MAPPER = exports.TRANSACTION_SOURCE_TYPE_MAPPER = exports.CURRENCY_SYMBOLS = exports.ACC_LVL_MAPPER = void 0;
var interfaces_1 = require("@hudled/interfaces");
exports.ACC_LVL_MAPPER = (_a = {},
    _a[interfaces_1.ACCESS_LEVEL.Owner] = 'Owner',
    _a[interfaces_1.ACCESS_LEVEL.Admin] = 'Admin',
    _a[interfaces_1.ACCESS_LEVEL.User] = 'User',
    _a[interfaces_1.ACCESS_LEVEL.Accountant] = 'Accountant',
    _a[interfaces_1.ACCESS_LEVEL.UnauthUser] = 'Unauth User',
    _a);
exports.CURRENCY_SYMBOLS = {
    USD: '$',
    CAD: '$',
    EUR: '€',
    AED: 'د.إ',
    AFN: '؋',
    ALL: 'L',
    AMD: '֏',
    ARS: '$',
    AUD: '$',
    AZN: '₼',
    BAM: 'KM',
    BDT: '৳',
    BGN: 'лв',
    BHD: '.د.ب',
    BIF: 'FBu',
    BND: '$',
    BOB: 'Bs',
    BRL: 'R$',
    BWP: 'P',
    BYR: 'p.',
    BZD: 'BZ$',
    CDF: 'FC',
    CHF: 'Fr',
    CLP: '$',
    CNY: '¥',
    COP: '$',
    CRC: '₡',
    CVE: '$',
    CZK: 'Kč',
    DJF: 'Fdj',
    DKK: 'kr',
    DOP: '$',
    DZD: 'د.ج',
    EGP: '£',
    ERN: 'Nfk',
    ETB: 'Br',
    GBP: '£',
    GEL: '₾',
    GHS: '₵',
    GNF: 'FG',
    GTQ: 'Q',
    HKD: '$',
    HNL: 'L',
    HRK: 'kn',
    HUF: 'Ft',
    IDR: 'Rp',
    ILS: '₪',
    INR: '₹',
    IQD: 'ع.د',
    IRR: '﷼',
    ISK: 'kr',
    JMD: 'J$',
    JOD: 'د.ا',
    JPY: '¥',
    KES: 'KSh',
    KHR: '៛',
    KMF: 'CF',
    KRW: '₩',
    KWD: 'د.ك',
    KZT: 'лв',
    LBP: '£',
    LKR: 'Rs',
    LTL: 'Lt',
    LYD: 'د.ل',
    MAD: 'د.م.',
    MDL: 'L',
    MGA: 'Ar',
    MKD: 'ден',
    MMK: 'K',
    MOP: 'MOP$',
    MUR: 'Rs',
    MXN: '$',
    MYR: 'RM',
    MZN: 'MT',
    NAD: '$',
    NGN: '₦',
    NIO: 'C$',
    NOK: 'kr',
    NPR: 'Rs',
    NZD: '$',
    OMR: 'ر.ع.',
    PAB: 'B/.',
    PEN: 'S/.',
    PHP: '₱',
    PKR: '₨',
    PLN: 'zł',
    PYG: 'Gs',
    QAR: 'ر.ق',
    RON: 'lei',
    RSD: 'Дин.',
    RUB: '₽',
    RWF: 'RF',
    SAR: 'ر.س',
    SDG: 'ج.س.',
    SEK: 'kr',
    SGD: '$',
    SOS: 'S',
    SYP: '£',
    THB: '฿',
    TND: 'د.ت',
    TOP: 'T$',
    TRY: '₺',
    TTD: 'TT$',
    TWD: 'NT$',
    TZS: 'TSh',
    UAH: '₴',
    UGX: 'USh',
    UYU: '$',
    UZS: 'лв',
    VEF: 'Bs',
    VND: '₫',
    XAF: 'FCFA',
    XOF: 'CFA',
    YER: '﷼',
    ZAR: 'R',
    ZMK: 'ZK',
};
exports.TRANSACTION_SOURCE_TYPE_MAPPER = {
    quickBooksBillsApi: 'Bill',
    quickBooksPurchasesApi: 'Purchase',
    xeroBankTransactionApi: 'Bank Transaction',
    xeroInvoiceApi: 'Invoice',
    salesforceJournalTransactionLineItemsApi: 'Journal Line Item',
    salesforcePurchaseInvoiceApi: 'Purchase Invoice',
    CSVUpload: 'Transaction',
};
exports.TRANSACTION_SOURCE_PLATFORM_MAPPER = {
    quickBooksBillsApi: 'Quickbooks',
    quickBooksPurchasesApi: 'Quickbooks',
    xeroBankTransactionApi: 'Xero',
    xeroInvoiceApi: 'Xero',
    salesforceJournalTransactionLineItemsApi: 'FinancialForce',
    salesforcePurchaseInvoiceApi: 'FinancialForce',
    CSVUpload: 'CSV Import',
};
exports.METHOD_NICE_MAPPER = {
    email: 'email forward',
    url_input: 'manual URL input',
    upload: 'file upload',
};
