"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HUBSPOT_SERVICE_PLANS = exports.FREQUENCY_OPTIONS = void 0;
exports.FREQUENCY_OPTIONS = [
    { value: { months: 12 }, label: 'Yearly' },
    { value: { months: 6 }, label: 'Half-Yearly' },
    { value: { months: 3 }, label: 'Quarterly' },
    { value: { months: 1 }, label: 'Monthly' },
];
exports.HUBSPOT_SERVICE_PLANS = {
    serviceId: 'bLeYfME82Bm9Sayrhpt6',
    families: ['Marketing Hub', 'Sales Hub', 'Service Hub', 'CMS Hub', 'Operations Hub'],
    globalTiers: ['Starter', 'Professional', 'Enterprise'],
    addOns: [
        // 'Additional Core Seats',
        'Ads Limit Increase',
        'API Limit Increase',
        'Custom SSL Add-On',
        'Reporting Limit Increase',
        'Business Units Add-on',
        'Transcription Limit Increase',
        'Marketing SMS Limit Increase',
        'Marketing SMS Add-On',
        'Video Limit Increase',
        'WhatsApp Limit Increase',
        'Teams Limit Increase',
        'Phone Number Limit Increase',
        'Calling Minutes Limit Increase',
        'Lists Limit Increase',
        'Sandbox Limit Increase',
        'Transactional Email Add-On',
        'Dedicated IP Add-On',
        'Domains Limit Increase',
        'Business Units Add-on',
        'Reporting Limit Increase',
        'Custom SSL Add-On',
        'API Limit Increase',
        'Ads Limit Increase',
    ],
    addOnsMap: {
        'Additional Core Seats Starter': {
            name: 'Additional Core Seats',
            unitsName: 'Core Seats',
            benchmarks: {
                min: 0.1, median: 0.5, max: 0.65, count: 99,
            },
            pricingModel: 'per_unit',
            conditionForAddOn: [{ tier: 'Starter' }],
        },
        'Ads Limit Increase': {
            pricingModel: 'per_unit',
            conditionForAddOn: [{ family: 'Marketing Hub' }],
        },
        'API Limit Increase': {
            pricingModel: 'flat_fee',
            conditionForAddOn: [{ family: 'Marketing Hub' }],
        },
        'Custom SSL Add-On ': {
            pricingModel: 'flat_fee',
            conditionForAddOn: [{ family: 'Marketing Hub' }],
        },
        'Reporting Limit Increase': {
            pricingModel: 'per_unit',
            // conditionForAddOn: [{ family: 'Marketing Hub' }],
        },
        'Business Units Add-on': {
            pricingModel: 'per_unit',
            conditionForAddOn: [{ family: 'Marketing Hub' }],
        },
    },
    addOnPlans: [
        {
            addOn: 'Additional Core Seats Starter',
            currency: 'AUD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 22 }],
        },
        {
            addOn: 'Additional Core Seats Professional',
            currency: 'AUD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 220 }],
        },
        {
            addOn: 'Ads Limit Increase',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 140 }],
        },
        {
            addOn: 'API Limit Increase',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 720 }],
        },
        {
            addOn: 'Custom SSL Add-On',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 140 }],
        },
        {
            addOn: 'Reporting Limit Increase',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 290 }],
        },
        {
            addOn: 'Business Units Add-on',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 1440 }],
        },
        {
            addOn: 'Domains Limit Increase',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 144 }],
        },
        {
            addOn: 'Dedicated IP Add-On',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 432 }],
        },
        {
            addOn: 'Transactional Email Add-On',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 864 }],
        },
        {
            addOn: 'Sandbox Limit Increase',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 1080 }],
        },
        {
            addOn: 'Lists Limit Increase',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 290 }],
        },
        {
            addOn: 'Calling Minutes Limit Increase',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 290 }],
        },
        {
            addOn: 'Phone Number Limit Increase',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 38 }],
        },
        {
            addOn: 'Teams Limit Increase',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 290 }],
        },
        {
            addOn: 'Video Limit Increase',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 72 }],
        },
        {
            addOn: 'WhatsApp Limit Increase',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 180 }],
        },
        {
            addOn: 'Marketing SMS Add-On',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 110 }],
        },
        {
            addOn: 'Marketing SMS Limit Increase',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 22 }],
        },
        {
            addOn: 'Transcription Limit Increase',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 360 }],
        },
    ],
    familiesMap: {
        'Extra Core Seats': {
            description: '',
            tiersMap: {
                Starter: {
                    unitsName: 'Core Seats',
                    allowsZero: true,
                    benchmarks: {
                        min: 0.1, median: 0.5, max: 0.65, count: 99,
                    },
                    pricingModel: 'per_unit',
                },
                Professional: {
                    unitsName: 'Core Seats',
                    allowsZero: true,
                    benchmarks: {
                        min: 0.1, median: 0.5, max: 0.65, count: 99,
                    },
                    pricingModel: 'per_unit',
                },
                Enterprise: {
                    unitsName: 'Core Seats',
                    allowsZero: true,
                    benchmarks: {
                        min: 0.1, median: 0.5, max: 0.65, count: 99,
                    },
                    pricingModel: 'per_unit',
                },
            },
        },
        'Marketing Hub': {
            description: '',
            tiersMap: {
                Starter: {
                    unitAmount: 1000,
                    unitsName: 'Marketing Contacts',
                    additionalUnitsName: 'Additional Marketing Contacts',
                    benchmarks: {
                        min: 0.0, median: 0.6, max: 0.9, count: 85,
                    },
                    pricingModel: 'tiered',
                },
                Professional: {
                    unitAmount: 5000,
                    unitsName: 'Marketing Contacts',
                    additionalUnitsName: 'Additional Marketing Contacts',
                    benchmarks: {
                        min: 0.0, median: 0.55, max: 0.90, count: 199,
                    },
                    pricingModel: 'tiered',
                    oneTimeFee: {
                        name: 'Marketing Hub Professional Onboarding',
                        description: 'We will help you set up Marketing Hub and provide advice on using it to grow your business',
                        price: 4320,
                        benchmarks: {
                            min: 0.0, median: 0.25, max: 0.25, count: 4,
                        },
                    },
                },
                Enterprise: {
                    unitAmount: 10000,
                    unitsName: 'Marketing Contacts',
                    additionalUnitsName: 'Additional Marketing Contacts',
                    benchmarks: {
                        min: 0.0, median: 0.377, max: 0.90, count: 61,
                    },
                    pricingModel: 'tiered',
                    oneTimeFee: {
                        name: 'Marketing Hub Enterprise Onboarding',
                        description: 'We will help you set up Marketing Hub and provide advice on using it to grow your business',
                        price: 8650,
                        benchmarks: {
                            min: 0.75, median: 0.75, max: 0.75, count: 3,
                        },
                    },
                },
            },
        },
        'Sales Hub': {
            description: '',
            tiersMap: {
                Starter: {
                    unitAmount: 1,
                    description: 'Includes 1 Core Seat',
                    benchmarks: {
                        min: 0.0, median: 0.0, max: 0.88, count: 17,
                    },
                    pricingModel: 'flat_fee',
                },
                Professional: {
                    unitAmount: 1,
                    unitsName: 'Sales Seats',
                    benchmarks: {
                        min: 0.0, median: 0.463, max: 0.90, count: 322,
                    },
                    pricingModel: 'per_unit',
                    oneTimeFee: {
                        name: 'Sales Hub Professional Onboarding',
                        description: 'We will help you set up Sales Hub and provide advice on using it to simplify and scale your sales process.',
                        price: 1440,
                        benchmarks: {
                            min: 0.25, median: 0.25, max: 0.75, count: 9,
                        },
                    },
                },
                Enterprise: {
                    unitAmount: 1,
                    unitsName: 'Sales Seats',
                    benchmarks: {
                        min: 0.0, median: 0.3, max: 0.4, count: 140,
                    },
                    pricingModel: 'per_unit',
                    oneTimeFee: {
                        name: 'Sales Hub Enterprise Onboarding',
                        description: 'We will help you set up Sales Hub and provide advice on using it to simplify and scale your sales process.',
                        price: 4320,
                    },
                },
            },
        },
        'Service Hub': {
            description: '',
            tiersMap: {
                Starter: {
                    unitAmount: 1,
                    description: 'Includes 1 Core Seat',
                    benchmarks: {
                        min: 0.0, median: 0.867, max: 0.88, count: 23,
                    },
                    pricingModel: 'flat_fee',
                },
                Professional: {
                    unitAmount: 1,
                    unitsName: 'Service Seats',
                    benchmarks: {
                        min: 0.0, median: 0.5, max: 0.9, count: 99,
                    },
                    pricingModel: 'per_unit',
                    oneTimeFee: {
                        name: 'Service Hub Professional Onboarding',
                        description: 'We will help you set up Service Hub and provide advice on using it to grow your business',
                        price: 1440,
                        benchmarks: {
                            min: 0.25, median: 0.25, max: 0.25, count: 1,
                        },
                    },
                },
                Enterprise: {
                    unitAmount: 1,
                    unitsName: 'Service Seats',
                    benchmarks: {
                        min: 0.24, median: 0.42, max: 0.60, count: 54,
                    },
                    pricingModel: 'per_unit',
                    oneTimeFee: {
                        name: 'Service Hub Enterprise Onboarding',
                        description: 'We will help you set up Service Hub and provide advice on using it to grow your business',
                        price: 4320,
                    },
                },
            },
        },
        'CMS Hub': {
            description: '',
            tiersMap: {
                Starter: {
                    unitAmount: 1,
                    description: 'Includes 1 Core Seat',
                    benchmarks: {
                        min: 0.0, median: 0.867, max: 0.90, count: 25,
                    },
                    pricingModel: 'flat_fee',
                },
                Professional: {
                    unitAmount: 1,
                    description: 'Includes 3 Core Seats',
                    benchmarks: {
                        min: 0.0, median: 0.432, max: 0.75, count: 44,
                    },
                    pricingModel: 'flat_fee',
                },
                Enterprise: {
                    unitAmount: 1,
                    description: 'Includes 5 Core Seats',
                    benchmarks: {
                        min: 0.377, median: 0.545, max: 0.90, count: 19,
                    },
                    pricingModel: 'flat_fee',
                },
            },
        },
        'Operations Hub': {
            description: '',
            tiersMap: {
                Starter: {
                    unitAmount: 1,
                    description: 'Includes 1 Core Seat',
                    benchmarks: {
                        min: 0.0, median: 0.778, max: 0.867, count: 27,
                    },
                    pricingModel: 'flat_fee',
                },
                Professional: {
                    unitAmount: 1,
                    description: 'Includes 1 Core Seat',
                    benchmarks: {
                        min: 0.375, median: 0.5, max: 0.92, count: 45,
                    },
                    pricingModel: 'flat_fee',
                },
                Enterprise: {
                    unitAmount: 1,
                    description: 'Includes 1 Core Seat',
                    benchmarks: {
                        min: 0.435, median: 0.545, max: 0.60, count: 16,
                    },
                    pricingModel: 'flat_fee',
                },
            },
        },
    },
    pricingPlans: [
        {
            family: 'Extra Core Seats',
            tier: 'Starter',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 240 }],
        },
        {
            family: 'Extra Core Seats',
            tier: 'Starter',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 1 },
            priceTiers: [{ startUnit: 1, price: 22 }],
        },
        {
            family: 'Extra Core Seats',
            tier: 'Enterprise',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 1320 }],
        },
        {
            family: 'Extra Core Seats',
            tier: 'Professional',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 75 }],
        },
        {
            family: 'Extra Core Seats',
            tier: 'Professional',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 816 }],
        },
        {
            family: 'Marketing Hub',
            tier: 'Starter',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, endUnit: 1000, price: 240 }, {
                    startUnit: 1001,
                    endUnit: 3000,
                    price: 810,
                }, { startUnit: 3001, endUnit: 5000, price: 702 }, { startUnit: 5001, price: 626.4 }],
        },
        {
            family: 'Marketing Hub',
            tier: 'Starter',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 1 },
            priceTiers: [{ startUnit: 1, endUnit: 1000, price: 22 }, {
                    startUnit: 1001,
                    endUnit: 3000,
                    price: 75,
                }, { startUnit: 3001, endUnit: 5000, price: 65 }, { startUnit: 5001, price: 58 }],
        },
        {
            family: 'Marketing Hub',
            tier: 'Professional',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, endUnit: 2000, price: 1279 }, {
                    startUnit: 2001,
                    endUnit: 22000,
                    price: 375,
                }, { startUnit: 22001, endUnit: 42000, price: 325 }, {
                    startUnit: 42001,
                    endUnit: 62000,
                    price: 290,
                }, { startUnit: 62001, endUnit: 82000, price: 255 }, { startUnit: 82001, price: 220 }],
        },
        {
            family: 'Marketing Hub',
            tier: 'Professional',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, endUnit: 2000, price: 13800 }, {
                    startUnit: 2001,
                    endUnit: 22000,
                    price: 4044.96,
                }, { startUnit: 22001, endUnit: 42000, price: 3505.56 }, {
                    startUnit: 42001,
                    endUnit: 62000,
                    price: 3128.04,
                }, { startUnit: 62001, endUnit: 82000, price: 2750.52 }, { startUnit: 82001, price: 2373 }],
        },
        {
            family: 'Marketing Hub',
            tier: 'Enterprise',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, endUnit: 10000, price: 62400 }, {
                    startUnit: 10001,
                    endUnit: 50000,
                    price: 1800,
                }, { startUnit: 50001, endUnit: 100000, price: 1560 }, {
                    startUnit: 100001,
                    endUnit: 200000,
                    price: 1440,
                }, { startUnit: 200001, endUnit: 500000, price: 1200 }, { startUnit: 500001, price: 1080 }],
        },
        {
            family: 'CMS Hub',
            tier: 'Starter',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 1 },
            priceTiers: [{ startUnit: 1, price: 22 }],
        },
        {
            family: 'CMS Hub',
            tier: 'Starter',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 240 }],
        },
        {
            family: 'CMS Hub',
            tier: 'Professional',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 575 }],
        },
        {
            family: 'CMS Hub',
            tier: 'Professional',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 6216 }],
        },
        {
            family: 'CMS Hub',
            tier: 'Enterprise',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 20760 }],
        },
        {
            family: 'Sales Hub',
            tier: 'Starter',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 1 },
            priceTiers: [{ startUnit: 1, price: 22 }],
        },
        {
            family: 'Sales Hub',
            tier: 'Starter',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 240 }],
        },
        {
            family: 'Sales Hub',
            tier: 'Professional',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 140 }],
        },
        {
            family: 'Sales Hub',
            tier: 'Professional',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 1512 }],
        },
        {
            family: 'Sales Hub',
            tier: 'Enterprise',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 2280 }],
        },
        {
            family: 'Service Hub',
            tier: 'Starter',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 1 },
            priceTiers: [{ startUnit: 1, price: 22 }],
        },
        {
            family: 'Service Hub',
            tier: 'Starter',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 240 }],
        },
        {
            family: 'Service Hub',
            tier: 'Professional',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 140 }],
        },
        {
            family: 'Service Hub',
            tier: 'Professional',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 1512 }],
        },
        {
            family: 'Service Hub',
            tier: 'Enterprise',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 2280 }],
        },
        {
            family: 'Operations Hub',
            tier: 'Starter',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 1 },
            priceTiers: [{ startUnit: 1, price: 22 }],
        },
        {
            family: 'Operations Hub',
            tier: 'Starter',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 240 }],
        },
        {
            family: 'Operations Hub',
            tier: 'Professional',
            currency: 'USD',
            billingCycle: { months: 1 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 1152 }],
        },
        {
            family: 'Operations Hub',
            tier: 'Professional',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 12444 }],
        },
        {
            family: 'Operations Hub',
            tier: 'Enterprise',
            currency: 'USD',
            billingCycle: { months: 12 },
            renewalFrequency: { months: 12 },
            priceTiers: [{ startUnit: 1, price: 34560 }],
        },
    ],
};
