"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAIL_CONTENT_TEMPLATE_VENDOR_MAPPER = exports.SUBJECT_TEMPLATE_VENDOR_MAPPER = exports.FROM_SEARCH_VENDOR_MAPPER = exports.SUBJECT_SEARCH_VENDOR_MAPPER = void 0;
exports.SUBJECT_SEARCH_VENDOR_MAPPER = {
    Xero: '"Your Xero Invoice"',
    Sharesight: '"Sharesight: Invoice"',
    Dext: '"from Dext for"',
    ApprovalMax: '"Invoice from subscription ApprovalMax"',
};
exports.FROM_SEARCH_VENDOR_MAPPER = {
    Xero: 'subscription.notifications@post.xero.com',
    Sharesight: 'do-not-reply@sharesight.com',
    Dext: 'accounts.receivables@receipt-bank.com',
    ApprovalMax: 'operations@approvalmax.com',
};
exports.SUBJECT_TEMPLATE_VENDOR_MAPPER = {
    Xero: 'Your Xero Invoice for COMPANY_NAME',
    Sharesight: 'Sharesight: Invoice 123456',
    Dext: 'Invoice 654321 from Dext for COMPANY_NAME',
    ApprovalMax: 'Invoice from subscription ApprovalMax for XERO (monthly subscription) (AMS-7637)',
};
exports.MAIL_CONTENT_TEMPLATE_VENDOR_MAPPER = {
    Xero: 'View your bill online:',
    Sharesight: 'You have a new Sharesight invoice due for payment on 12 Jan 2024',
    Dext: 'Please find enclosed invoice number 654321 for $399.77',
    ApprovalMax: 'Thank you for choosing ApprovalMax. We have received your renewal payment...',
};
