"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.omitProps = exports.prioritizeOwnProps = exports.isShallowEqual = exports.trueOr0 = exports.getStepByOffset = exports.clamp = exports.makeValueLabelArray = exports.loadedLoadingEmpty = exports.makeTwoDigits = exports.formatStr = void 0;
var react_redux_firebase_1 = require("react-redux-firebase");
function formatStr(str) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    var formattedStr = str;
    // eslint-disable-next-line no-plusplus
    for (var i = 0; i < args.length; i++) {
        formattedStr = formattedStr.replace("{".concat(i, "}"), args[i]);
    }
    return formattedStr;
}
exports.formatStr = formatStr;
var makeTwoDigits = function (num) { return (num < 10 ? "0".concat(num) : num); };
exports.makeTwoDigits = makeTwoDigits;
var loadedLoadingEmpty = function (items, loadedFunction, loading, empty) {
    if (!(0, react_redux_firebase_1.isLoaded)(items))
        return loading;
    if ((0, react_redux_firebase_1.isEmpty)(items))
        return empty;
    return loadedFunction(items);
};
exports.loadedLoadingEmpty = loadedLoadingEmpty;
var makeValueLabelArray = function (arr, labelMapper) { return (arr === null || arr === void 0 ? void 0 : arr.map(function (item) { var _a; return ({ value: item, label: (_a = labelMapper === null || labelMapper === void 0 ? void 0 : labelMapper[item]) !== null && _a !== void 0 ? _a : item }); })) || []; };
exports.makeValueLabelArray = makeValueLabelArray;
var clamp = function (min, max) { return function (num) { return Math.min(Math.max(num, min), max); }; };
exports.clamp = clamp;
var getStepByOffset = function (stepArr) {
    var idxInArray = (0, exports.clamp)(0, stepArr.length);
    return function (step, offset) { return stepArr[idxInArray(stepArr.indexOf(step) + offset)]; };
};
exports.getStepByOffset = getStepByOffset;
var trueOr0 = function (v) { return !!v || v === 0; };
exports.trueOr0 = trueOr0;
function isShallowEqual(obj1, obj2) {
    if (obj1 === obj2) {
        return true;
    }
    if (!obj1 || !obj2) {
        return false;
    }
    var keys1 = Object.keys(obj1);
    var keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    return keys1.every(function (k) { return k in obj2 && obj1[k] === obj2[k]; });
}
exports.isShallowEqual = isShallowEqual;
var prioritizeOwnProps = function (stateProps, dispatchProps, ownProps) { return (__assign(__assign(__assign({}, (stateProps || {})), (dispatchProps || {})), ownProps)); };
exports.prioritizeOwnProps = prioritizeOwnProps;
var omitProps = function () {
    var propsToOmit = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        propsToOmit[_i] = arguments[_i];
    }
    return function (prop) { return !propsToOmit.includes(prop); };
};
exports.omitProps = omitProps;
