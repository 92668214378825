"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCallCallback = void 0;
var end2end_1 = require("@hudled/end2end");
var firestore_1 = require("firebase/firestore");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_redux_firebase_1 = require("react-redux-firebase");
var analytics_1 = require("utils/analytics");
var backend_1 = require("utils/backend");
var hooks_1 = require("utils/hooks");
var single_1 = require("utils/selectors/single");
var purposeIsAuth = function (purpose) { return purpose === null || purpose === void 0 ? void 0 : purpose.startsWith('SIGN-'); };
var useCallCallback = function (app, location) {
    var _a = __read((0, react_1.useState)(''), 2), redirectUrl = _a[0], setRedirectUrl = _a[1];
    var _b = __read((0, react_1.useState)(false), 2), calledOnce = _b[0], setCalledOnce = _b[1];
    var _c = __read((0, react_1.useState)(null), 2), tenantsSettings = _c[0], setTenantsSettings = _c[1];
    var company = (0, react_redux_1.useSelector)(single_1.selectMyCompany);
    var firebaseInstance = (0, react_redux_firebase_1.useFirebase)();
    var qParams = Object.fromEntries(new URLSearchParams(location.search.substring(1)).entries());
    var _d = __read((0, hooks_1.useSubmitter)('companies', company === null || company === void 0 ? void 0 : company.id), 1), companySubmitter = _d[0];
    var markReturnFromOauth = function () {
        var _a;
        return ((_a = company === null || company === void 0 ? void 0 : company.times) === null || _a === void 0 ? void 0 : _a.returnFromOAuth) && companySubmitter({
            times: { returnFromOAuth: firestore_1.Timestamp.now() },
        });
    };
    var trackOAuthComplete = (0, analytics_1.usePreparedTracker)('OAuth Completed');
    (0, react_1.useEffect)(function () {
        var stateParams = qParams.state ? (0, end2end_1.parseStateString)(qParams.state) : { redirectPath: '' };
        var isAuth = purposeIsAuth(stateParams.purpose);
        if ((company || isAuth) && !calledOnce) {
            setCalledOnce(calledOnce);
            // AppCallbacks(qParams, setRedirectUrl, setError, setTenantsSettings, markReturnFromOauth)[app]();
            AppCallbacks(qParams, stateParams.connId, setTenantsSettings, markReturnFromOauth)[app](stateParams)
                .then(function (_a) {
                var error = _a.error, success = _a.success, authToken = _a.authToken, redirectPath = _a.redirectPath;
                return __awaiter(void 0, void 0, void 0, function () {
                    var qParamAttachChar, basePath;
                    var _b, _c;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0:
                                trackOAuthComplete({ error: error, resolution: success ? 'Success' : 'Error' });
                                qParamAttachChar = (0, end2end_1.getQParamAttachChar)((_b = stateParams.redirectPath) !== null && _b !== void 0 ? _b : '');
                                basePath = "".concat((_c = stateParams.redirectPath) !== null && _c !== void 0 ? _c : redirectPath).concat(qParamAttachChar, "hl=").concat(stateParams.connId);
                                if (error)
                                    setRedirectUrl("".concat(basePath, "&error=").concat(error));
                                if (!success) return [3 /*break*/, 4];
                                if (!authToken) return [3 /*break*/, 3];
                                return [4 /*yield*/, firebaseInstance.auth().signInWithCustomToken(authToken)];
                            case 1:
                                _d.sent();
                                return [4 /*yield*/, (0, end2end_1.delay)(500)];
                            case 2:
                                _d.sent();
                                _d.label = 3;
                            case 3:
                                setRedirectUrl(isAuth ? basePath : "".concat(basePath, "&connected=yes"));
                                _d.label = 4;
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            });
        }
    }, [!!company]);
    return [redirectUrl, tenantsSettings];
};
exports.useCallCallback = useCallCallback;
// const xeroCallback = callBackendFn('transactions-xero-callback');
var quickBooksCallback = (0, backend_1.callBackendFn)('transactions-quickbooks-callback');
var stripeCallback = (0, backend_1.callBackendFn)('stripe-checkstripeaccount');
var salesforceCallback = (0, backend_1.callBackendFn)('transactions-salesforce-callback');
var xeroCallback = (0, backend_1.callBackendFn)('transactions-xero-callback');
var xeroSignInUpCallback = function (reqBody) { return __awaiter(void 0, void 0, void 0, function () {
    var resp;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, backend_1.callBackendFn)('transactions-xero-signInCallback', __assign(__assign({}, reqBody), { referrerInfo: localStorage.referredBy && { referredBy: localStorage.referredBy, lastReferredAt: +localStorage.lastReferredAt }, creationTrackData: localStorage.accountCreationTracking ? JSON.parse(localStorage.accountCreationTracking) : undefined }))];
            case 1:
                resp = _a.sent();
                if (localStorage.accountCreationTracking)
                    delete localStorage.accountCreationTracking;
                return [2 /*return*/, resp];
        }
    });
}); };
var setTenantIdAndSync = (0, backend_1.callBackendFn)('transactions-xero-setTenantId');
var AppCallbacks = function (qParams, connId, 
// setError: Dispatch<string>,
setTenantsSettings, markReturnFromOauth) { return ({
    // ToDo: handle xeroCallBack here
    xero: function (stateParams) { return __awaiter(void 0, void 0, void 0, function () {
        var isAuth, callBackFn, response, _a, data, error, newConnId, authToken, tenantId;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (qParams.error || !qParams.code) {
                        return [2 /*return*/, { error: qParams.error || 'unknown_error' }];
                    }
                    isAuth = purposeIsAuth(stateParams.purpose);
                    return [4 /*yield*/, markReturnFromOauth()];
                case 1:
                    _c.sent();
                    callBackFn = isAuth ? xeroSignInUpCallback : xeroCallback;
                    return [4 /*yield*/, callBackFn({
                            code: qParams.code,
                            connId: connId,
                            purpose: stateParams.purpose,
                            redirectUri: qParams.redirectUrl ? decodeURIComponent(qParams.redirectUrl) : (window.location.origin + window.location.pathname),
                        })];
                case 2:
                    response = _c.sent();
                    _a = response.data, data = _a.data, error = _a.error, newConnId = _a.connId, authToken = _a.authToken;
                    if (error) {
                        return [2 /*return*/, { error: error }];
                    }
                    tenantId = (_b = data === null || data === void 0 ? void 0 : data[0]) === null || _b === void 0 ? void 0 : _b.tenantId;
                    if (!(data && data.length > 1)) return [3 /*break*/, 4];
                    return [4 /*yield*/, new Promise(function (resolve) {
                            setTenantsSettings({ setTenant: resolve, tenantsToChose: data });
                        })];
                case 3:
                    tenantId = _c.sent();
                    setTenantsSettings(null);
                    _c.label = 4;
                case 4:
                    if (!tenantId) return [3 /*break*/, 6];
                    return [4 /*yield*/, setTenantIdAndSync({ connId: newConnId || connId, tenantId: tenantId })];
                case 5:
                    _c.sent();
                    _c.label = 6;
                case 6:
                    if (isAuth && !authToken) {
                        return [2 /*return*/, { error: 'missing_token' }];
                    }
                    return [2 /*return*/, { success: true, authToken: authToken }];
            }
        });
    }); },
    quickbooks: function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    if (qParams.error) {
                        return [2 /*return*/, { error: qParams.error }];
                    }
                    return [4 /*yield*/, quickBooksCallback({ oAuthCbUrl: window.location.href, connId: connId })];
                case 1:
                    resp = _b.sent();
                    if (resp.data.success) {
                        return [2 /*return*/, { success: true }];
                    }
                    return [2 /*return*/, { error: resp.data.error || 'unknown_error' }];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, { error: 'unknown_error' }];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    stripe: function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, stripeCallback({ oAuthCbUrl: window.location.href, connId: connId })];
                case 1:
                    resp = _b.sent();
                    if (resp.data.success) {
                        return [2 /*return*/, { success: true, redirectUrl: '/settings/integrations/payments' }];
                    }
                    return [2 /*return*/, { error: resp.data.error || 'unknown_error', redirectPath: '/settings/integrations/payments' }];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, { error: 'unknown_error' }];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    salesforce: function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, salesforceCallback({
                            code: qParams.code,
                            connId: connId,
                            redirectUri: window.location.origin + window.location.pathname,
                        })];
                case 1:
                    resp = _b.sent();
                    if (resp.data.success) {
                        return [2 /*return*/, { success: true }];
                    }
                    return [2 /*return*/, { error: resp.data.error || 'unknown_error' }];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, { error: 'unknown_error' }];
                case 3: return [2 /*return*/];
            }
        });
    }); },
}); };
