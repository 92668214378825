import classNames from 'classnames';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

// ToDo: make into tsx and probably merge with shared/components/ScrollDiv.js
class ScrollDiv extends Component {
  constructor(props) {
    super(props);
    this.renderThumbVer = this.renderThumbVer.bind(this);
    this.renderView = this.renderView.bind(this);
  }

  renderThumbVer = ({ style, darkBg = this.props.darkBg, viewStyle, ...props }) => {
    const thumbStyle = {
      backgroundColor: darkBg
        ? 'rgba(255, 255, 255, 0.4)'
        : 'rgba(3, 39, 59, 0.3)',
      width: '.4375rem',
      borderRadius: '.21875rem',
      right: '.125rem',
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  renderView = ({ style, viewStyle = this.props.viewStyle, ...props }) => (
    <div
      {...props}
      style={{ ...style, ...viewStyle }}
      className={classNames('ds-scrollbars-view', this.props.scrollDivClass, {
        '_block-scrolling_': this.props.blockScroll,
      })}
    />
  );

  render() {
    const { innerRef, electOldSafariFix, ...props } = this.props;
    let style;
    if (electOldSafariFix) {
      style = { position: 'relative;-webkit-transform:translateZ(0)' };
    }
    return (
      <Scrollbars
        style={style}
        renderThumbVertical={this.renderThumbVer}
        renderView={this.renderView}
        autoHide
        autoHideTimeout={1500}
        autoHideDuration={200}
        ref={innerRef}
        {...omit(props, ['darkBg', 'scrollDivClass', 'viewStyle'])}
      />
    );
  }
}

ScrollDiv.propTypes = {
  darkBg: PropTypes.bool,
  blockScroll: PropTypes.bool,
  scrollDivClass: PropTypes.string,
  innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  electOldSafariFix: PropTypes.bool,
  viewStyle: PropTypes.object,
};

export default ScrollDiv;
