import { Row } from 'reactstrap';

import ClassNameExtendableHoC from 'components/HoC/ClassNameExtendableHoC';

const BasicListHeader = ClassNameExtendableHoC(
  Row,
  'ds-list__header fs-sm',
  ({ isHalf, isSticky }) => [{ '_is-half_': isHalf, isSticky }],
  ['isHalf', 'isSticky'],
);

export default BasicListHeader;
