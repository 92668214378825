"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProperBestAddress = exports.chooseBestAddress = void 0;
var lodash_1 = require("lodash");
var utils_1 = require("./utils");
var intl_1 = require("./intl");
var chooseBestAddress = function (priorityAddress, secondAddress) {
    if (!priorityAddress || !secondAddress) {
        return [priorityAddress, secondAddress].find(Boolean);
    }
    if (priorityAddress.country && priorityAddress.postalCode) {
        if (priorityAddress.postalCode === secondAddress.postalCode
            && (!priorityAddress.line1 && secondAddress.line1 || secondAddress.line1 === priorityAddress.line1)) {
            return __assign(__assign({}, secondAddress), (0, lodash_1.pickBy)(priorityAddress, utils_1.notFalsy));
        }
        return priorityAddress;
    }
    if (secondAddress.country && secondAddress.postalCode) {
        return secondAddress;
    }
    return priorityAddress;
};
exports.chooseBestAddress = chooseBestAddress;
var getProperBestAddress = function (priorityAddress, secondAddress) {
    var bestAddress = (0, exports.chooseBestAddress)(priorityAddress, secondAddress);
    return __assign(__assign({}, bestAddress), { country: (0, intl_1.getCountryCode)(bestAddress === null || bestAddress === void 0 ? void 0 : bestAddress.country) });
};
exports.getProperBestAddress = getProperBestAddress;
