"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRechargeAf = exports.getNiceProvider = exports.getProviderAsNice = exports.ACCOUNTING_PLATFORMS_MAPPER = exports.getIntConnStatus = exports.isIntConnActive = exports.isIntConnUnusable = exports.isIntConnRequestLimited = exports.isIntConnVisible = void 0;
var isIntConnVisible = function (c) { return !c.deletedOn && !c.isBooking && !c.isDraft; };
exports.isIntConnVisible = isIntConnVisible;
var DAY_IN_MS = 24 * 3600 * 1000;
var isIntConnRequestLimited = function (c) { return c.requestLimitReachedOn && Date.now() - c.requestLimitReachedOn.toMillis() < DAY_IN_MS; };
exports.isIntConnRequestLimited = isIntConnRequestLimited;
var isIntConnUnusable = function (c) { return !(0, exports.isIntConnVisible)(c) || !c.hasActiveRefreshToken || (0, exports.isIntConnRequestLimited)(c); };
exports.isIntConnUnusable = isIntConnUnusable;
var isIntConnActive = function (c) { return (0, exports.isIntConnVisible)(c) && c.hasActiveRefreshToken && !c.isSuspended && !c.isDisconnected
    && (c.contactsLastSync || c.lastSync || c.firstSync); };
exports.isIntConnActive = isIntConnActive;
var getIntConnStatus = function (c) { return (0, exports.isIntConnActive)(c) ? 'Active' : 'Inactive'; };
exports.getIntConnStatus = getIntConnStatus;
exports.ACCOUNTING_PLATFORMS_MAPPER = {
    Salesforce: 'FinancialForce',
};
var getProviderAsNice = function (provider) { var _a; return (_a = (provider && exports.ACCOUNTING_PLATFORMS_MAPPER[provider])) !== null && _a !== void 0 ? _a : provider; };
exports.getProviderAsNice = getProviderAsNice;
var getNiceProvider = function (c) { return (0, exports.getProviderAsNice)(c.provider); };
exports.getNiceProvider = getNiceProvider;
var isRechargeAf = function (iC) { return iC.specialConnectionType === 'RECHARGE_AF'; };
exports.isRechargeAf = isRechargeAf;
