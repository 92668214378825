"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wrapper = void 0;
var styles_1 = require("@mui/material/styles");
exports.Wrapper = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    });
});
