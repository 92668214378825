"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PrimaryCloudMetric;
(function (PrimaryCloudMetric) {
    PrimaryCloudMetric[PrimaryCloudMetric["OTHER"] = 0] = "OTHER";
    PrimaryCloudMetric[PrimaryCloudMetric["REVENUE"] = 1] = "REVENUE";
    PrimaryCloudMetric[PrimaryCloudMetric["MRR_ARR"] = 2] = "MRR_ARR";
    PrimaryCloudMetric[PrimaryCloudMetric["CUSTOMERS"] = 3] = "CUSTOMERS";
    PrimaryCloudMetric[PrimaryCloudMetric["USER_SESSIONS"] = 4] = "USER_SESSIONS";
    PrimaryCloudMetric[PrimaryCloudMetric["MONTHLY_ACTIVE_USERS"] = 5] = "MONTHLY_ACTIVE_USERS";
    PrimaryCloudMetric[PrimaryCloudMetric["WEEKLY_ACTIVE_USERS"] = 6] = "WEEKLY_ACTIVE_USERS";
    PrimaryCloudMetric[PrimaryCloudMetric["DAILY_ACTIVE_USERS"] = 7] = "DAILY_ACTIVE_USERS";
})(PrimaryCloudMetric || (PrimaryCloudMetric = {}));
