"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.avatarGroupSectionDefs = exports.avatarSectionDefs = exports.profileAvatarSectionDefs = void 0;
var constants_1 = require("../constants");
exports.profileAvatarSectionDefs = [
    {
        heading: 'ProfileAvatar',
        description: 'An Avatar is a visual representation of a user. If the user has a profile, use ProfileAvatar to set the image.',
        variations: [
            {
                component: 'ProfileAvatar', properties: [{ prop: 'profile', v: constants_1.sampleProfile }], noChildren: true,
            },
        ],
    },
];
exports.avatarSectionDefs = [
    {
        heading: 'Avatar',
        description: 'If the user has no image, use the Avatar component. Use the label prop to set the initials.',
        variations: [
            {
                component: 'Avatar', properties: [{ prop: 'label', v: 'Hudled' }], noChildren: true,
            },
        ],
    },
    {
        heading: 'Avatar sizes',
        description: 'The Avatar & ProfileAvatar comes with three sizes: sm, md and lg. It uses md as default',
        variations: [
            { component: 'Avatar', properties: [{ prop: 'label', v: 'Hudled' }, { prop: 'size', v: 'sm' }], noChildren: true },
            { component: 'Avatar', properties: [{ prop: 'label', v: 'Hudled' }, { prop: 'size', v: 'md' }], noChildren: true },
            { component: 'Avatar', properties: [{ prop: 'label', v: 'Hudled' }, { prop: 'size', v: 'lg' }], noChildren: true },
        ],
    },
];
var avatarChildren = [
    { component: 'Avatar', properties: [{ prop: 'label', v: 'Hudled' }], noChildren: true },
    {
        component: 'Avatar',
        properties: [{ prop: 'src', v: constants_1.sampleProfile === null || constants_1.sampleProfile === void 0 ? void 0 : constants_1.sampleProfile.avatarUrl }, { prop: 'alt', v: constants_1.sampleProfile.displayName }],
        noChildren: true,
    },
    { component: 'Avatar', properties: [{ prop: 'label', v: 'Hudled 2' }], noChildren: true },
    {
        component: 'Avatar',
        properties: [{ prop: 'src', v: constants_1.sampleProfile === null || constants_1.sampleProfile === void 0 ? void 0 : constants_1.sampleProfile.avatarUrl }, { prop: 'alt', v: constants_1.sampleProfile.displayName }],
        noChildren: true,
    },
];
var avatarChildrenGeneric = avatarChildren;
exports.avatarGroupSectionDefs = [
    {
        heading: 'AvatarGroup',
        description: "An AvatarGroup is a visual representation of a group of users. \n    You can use Avatar, ProfileAvatar, or Logo as the children",
        variations: [{ component: 'AvatarGroup', children: avatarChildrenGeneric }],
    },
    {
        heading: 'max prop',
        description: "The AvatarGroup comes with a max prop to set the maximum number of avatars to show. \n    If the group has more avatars than the max, it will show a +{remaining} label.",
        variations: [
            { component: 'AvatarGroup', properties: [{ prop: 'max', v: 3 }], children: avatarChildrenGeneric },
        ],
    },
    {
        heading: 'AvatarGroup sizes',
        description: 'The AvatarGroup comes with three sizes: sm, md and lg. This resizes the avatars inside the group.',
        variations: [
            { component: 'AvatarGroup', properties: [{ prop: 'size', v: 'sm' }], children: avatarChildrenGeneric },
            { component: 'AvatarGroup', properties: [{ prop: 'size', v: 'md' }], children: avatarChildrenGeneric },
            { component: 'AvatarGroup', properties: [{ prop: 'size', v: 'lg' }], children: avatarChildrenGeneric },
        ],
    },
];
