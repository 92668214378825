"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var IconButtons_1 = require("atomicComponents/molecules/IconButtons/IconButtons");
var ModalCloseButton = (0, styles_1.styled)(IconButtons_1.CloseButton)(function () { return ({
    position: 'absolute',
    right: 4,
    top: 4,
    transition: 'opacity 0.3s ease-in-out',
    opacity: 0,
    ':focus-visible, :hover': {
        opacity: 1,
        background: 'transparent',
    },
}); });
exports.default = ModalCloseButton;
