"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTitleDescForSignUp = void 0;
var getTitleDescForSignUp = function () {
    if (!window.location.pathname.match(/(\/activate|\/signup(\/accounting-firm)?)/)) {
        return null;
    }
    if (window.location.pathname === '/signup/accounting-firm') {
        return {
            isAccountingSignUp: true,
            headerMeta: {
                title: 'Rechargly - Signup Accounting Firm',
                desc: 'Get Rechargly today and help your customers never overpay on your SaaS again',
            },
            welcomeMsg: 'Get started as an accounting firm',
            descriptionPoints: [
                'Connect your customers directly to their accounting system',
                'Tailored savings recommendations to reduce spend of your customers',
                'Give your customers access to exclusive discounts',
            ],
        };
    }
    return {
        headerMeta: {
            title: 'Rechargly - Signup',
            desc: 'Manage all your software recharges in one place. Turn tedious work into automated workflows with a few clicks.',
        },
        welcomeMsg: 'Sign up to Rechargly',
        descriptionPoints: [
            'Connect your accounting system',
            'Manage all your software recharges in one place',
            'Turn tedious work into automated workflows with a few clicks',
        ],
    };
};
exports.getTitleDescForSignUp = getTitleDescForSignUp;
