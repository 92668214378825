"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
exports.default = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.common.white,
        zIndex: 1,
        marginRight: theme.spacing(-2),
        marginBottom: 0,
        padding: theme.spacing(3, 0, 2, 2),
    });
});
