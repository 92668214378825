"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FILE_UPLOADER_ERROR_MAPPER = exports.VENDOR_LOGOS = exports.VENDORS_LIST = exports.RECHARGES_MAIL_STATUS_MAPPER = exports.EMAIL_PROVIDERS_LOGO = exports.EMAIL_PROVIDERS = exports.RECHARGE_STATUS_MAPPER = exports.MATCH_LEVEL_MAPPER = exports.CONNECTIONS_TABS = exports.AUTO_RECHARGING_TABS = exports.CRAWLED_CUSTOMERS_MIGRATION_TABS = exports.CUSTOMERS_MIGRATION_TABS = exports.MAP_CUSTOMERS_TABS = void 0;
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var faCirclesOverlap_1 = require("@fortawesome/pro-light-svg-icons/faCirclesOverlap");
var faCircleCheck_1 = require("@fortawesome/pro-regular-svg-icons/faCircleCheck");
var faCircleDot_1 = require("@fortawesome/pro-regular-svg-icons/faCircleDot");
var faCircleXmark_1 = require("@fortawesome/pro-regular-svg-icons/faCircleXmark");
var faPlusCircle_1 = require("@fortawesome/pro-regular-svg-icons/faPlusCircle");
var constants_1 = require("common/constants");
var constants_2 = require("containers/AssistedOnboarding/WelcomeFlow/constants");
exports.MAP_CUSTOMERS_TABS = [
    { label: 'For Review', href: '/recharges/mapping/for-review' },
    { label: 'Confirmed', href: '/recharges/mapping/confirmed' },
    { label: 'Skipped', href: '/recharges/mapping/skipped' },
    { label: 'All', href: '/recharges/mapping/all' },
];
exports.CUSTOMERS_MIGRATION_TABS = [
    { label: 'For Review', href: '/recharges/migration/for-review' },
    { label: 'Confirmed', href: '/recharges/migration/confirmed' },
    { label: 'All', href: '/recharges/migration/all' },
];
exports.CRAWLED_CUSTOMERS_MIGRATION_TABS = [
    { label: 'For Review', href: '/recharges/non-au-migration/for-review' },
    { label: 'Confirmed', href: '/recharges/non-au-migration/confirmed' },
    { label: 'All', href: '/recharges/non-au-migration/all' },
];
exports.AUTO_RECHARGING_TABS = [
    { label: 'Customers', href: '/recharges/billing/customers' },
    // { label: 'New', href: '/recharges/billing/new' },
    // { label: 'Charges', href: '/recharges/billing/charges' },
    // { label: 'Recharging', href: '/recharges/billing/recharging' },
];
exports.CONNECTIONS_TABS = [
    { label: 'Xero Connections', href: '/recharges/connections/xero' },
];
exports.MATCH_LEVEL_MAPPER = {
    exact: {
        text: 'Exact',
        iconColor: '#30BC86',
        icon: faCircleCheck_1.faCircleCheck,
    },
    strong: {
        text: 'Strong',
        iconColor: constants_1.COLORS.riverstone,
        icon: faCircleCheck_1.faCircleCheck,
    },
    weak: {
        text: 'Similar',
        iconColor: '#9CA9B0',
        icon: faCircleCheck_1.faCircleCheck,
    },
    notFound: {
        text: 'Not Found',
        iconColor: '#EC7069',
        icon: faCircleXmark_1.faCircleXmark,
    },
    multiple: {
        text: 'Multiple',
        iconColor: '#23AFEE',
        icon: faCirclesOverlap_1.faCirclesOverlap,
    },
};
exports.RECHARGE_STATUS_MAPPER = {
    new: {
        text: 'New customer',
        color: '#9CA9B0',
        icon: faPlusCircle_1.faPlusCircle,
    },
    recharging: {
        text: 'Recharging',
        color: '#02AD6B',
        icon: faCircleDot_1.faCircleDot,
    },
};
exports.EMAIL_PROVIDERS = ['Gmail', 'Outlook', 'Other'];
exports.EMAIL_PROVIDERS_LOGO = {
    Gmail: {
        label: 'Gmail/Workspace',
        logo: '/Gmail.png',
    },
    Outlook: {
        label: 'Microsoft Outlook',
        logo: '/Outlook.png',
    },
    Other: {
        label: 'Other email providers',
        icon: pro_light_svg_icons_1.faEnvelopesBulk,
    },
};
exports.RECHARGES_MAIL_STATUS_MAPPER = {
    pendingReception: {
        status: 'pending',
        text: '(Pending email reception)',
    },
    processed: {
        status: 'success',
        text: '(Received correctly)',
    },
    incorrectEmail: {
        status: 'error',
        text: '(Incorrect email, try again)',
    },
    errorFetchingData: {
        status: 'error',
        text: '(Error fetching data, contact support)',
    },
};
exports.VENDORS_LIST = ['Xero', 'QuickBooks', 'A2X', 'Gusto', 'Employment Hero', 'Dext', 'Lightyear', 'ApprovalMax',
    'Docusign', 'Bill.com', 'AccountKit', 'NetSuite', 'Other'];
exports.VENDOR_LOGOS = {
    Xero: {
        label: 'Xero',
        logo: constants_2.ACC_PLATFORMS_LOGO.Xero,
    },
    QuickBooks: {
        label: 'QuickBooks',
        logo: constants_2.ACC_PLATFORMS_LOGO.QuickBooks,
    },
    NetSuite: {
        label: 'NetSuite',
        logo: constants_2.ACC_PLATFORMS_LOGO.NetSuite,
    },
    Other: {
        label: 'Other or not sure...',
        icon: pro_light_svg_icons_1.faQuestionCircle,
        iconColor: '#200384',
    },
};
exports.FILE_UPLOADER_ERROR_MAPPER = {
    ALREADY_EXTRACTED: 'File has been uploaded before.',
};
