import classNames from 'classnames';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';

// ToDO remove and use app/components/Generic/ClassNameExtendableHoC.tsx
const ClassNameExtendableHoC = (
  Component,
  defaultClassName,
  classMapper,
  blockProps = [],
) => {
  const Wrapped = ({ ...props }) => (
    <Component
      className={classNames(
        defaultClassName,
        classMapper && classMapper(props),
        props.className,
      )}
      {...omit(props, [...blockProps, 'className'])}
    />
  );

  Wrapped.propTypes = {
    className: PropTypes.string,
  };
  return Wrapped;
};

export default ClassNameExtendableHoC;
