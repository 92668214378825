"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var StyledGrid = (0, material_1.styled)(material_1.Grid)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            background: theme.palette.common.white,
            boxShadow: '1px 1px 5px 0 rgb(0 0 0 / 20%)',
            minHeight: 388
        },
        _b[theme.breakpoints.down('lg')] = {
            '& > div:first-child, & > div:last-child': {
                maxWidth: 350,
            },
        },
        _b[theme.breakpoints.up('md')] = {
            '& > div:first-child, & > div:last-child': {
                maxWidth: 450,
                padding: theme.spacing(2),
            },
        },
        _b);
});
exports.default = StyledGrid;
