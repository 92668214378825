"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userPublicFields = exports.companyMinFields = exports.ACCESS_LEVEL = exports.REVIEWS_STEP = void 0;
var REVIEWS_STEP;
(function (REVIEWS_STEP) {
    REVIEWS_STEP["AfterChooseDepartment"] = "AFTER_CHOOSE_DEPARTMENT";
    REVIEWS_STEP["ReviewsDone"] = "REVIEWS_DONE";
})(REVIEWS_STEP = exports.REVIEWS_STEP || (exports.REVIEWS_STEP = {}));
// See ACCESS_LEVELS_HIERARCHY @ shared/end2end/constants.ts
var ACCESS_LEVEL;
(function (ACCESS_LEVEL) {
    ACCESS_LEVEL["Owner"] = "OWNER";
    ACCESS_LEVEL["Accountant"] = "ACCOUNTANT";
    ACCESS_LEVEL["Admin"] = "ADMIN";
    ACCESS_LEVEL["User"] = "USER";
    ACCESS_LEVEL["UnauthUser"] = "UNAUTH_USER";
})(ACCESS_LEVEL = exports.ACCESS_LEVEL || (exports.ACCESS_LEVEL = {}));
exports.companyMinFields = ['name', 'logoUrl', 'baseCurrency', 'totalLTMSaasSpend'];
// ToDo: remove department from here
exports.userPublicFields = ['avatarUrl', 'firstName', 'lastName', 'displayName', 'companyId', 'email',
    'mainCompanyId', 'companyTeamId', 'accessLevel', 'isAccountant', 'isSuspended', 'isUnauthUser', 'reviewsStep', 'companyAccessLevel',
    'companyIds'];
