"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.alertStyles = void 0;
var alertStyles = function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: '16px',
        color: theme.palette.text.secondary,
        lineHeight: 1.2,
    });
};
exports.alertStyles = alertStyles;
