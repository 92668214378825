"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var general_1 = require("utils/general");
var ButtonGroup = (0, styles_1.styled)(material_1.ButtonGroup, { shouldForwardProp: (0, general_1.omitProps)('boxShadow') })(function (_a) {
    var _b = _a.boxShadow, boxShadow = _b === void 0 ? false : _b;
    return (__assign({}, boxShadow && { boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.06)' }));
});
exports.default = ButtonGroup;
