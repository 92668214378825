"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONFIRM_MESSAGE_DETAILS = exports.CONFIRM_MESSAGE_HEADING = void 0;
// import { formatNumber } from 'utils/fieldProcessors';
var interfaces_1 = require("@hudled/interfaces");
exports.CONFIRM_MESSAGE_HEADING = (_a = {},
    // [RECOMMENDATIONS_TYPE.M2Y]: { unused: 'Change to annual billing', used: 'Coming soon' },
    _a[interfaces_1.RECOMMENDATIONS_TYPE.FX] = { unused: 'Activate Airwallex Offer', used: 'How to activate our deal' },
    // [RECOMMENDATIONS_TYPE.PAPR]: { unused: 'Activate Partnership Deal', used: 'We’ll be in touch soon' },
    // [RECOMMENDATIONS_TYPE.DORM]: { unused: 'Review dormant users', used: 'Coming soon' },
    // [RECOMMENDATIONS_TYPE.USUB]: { unused: 'Cancel redundant software', used: 'Coming soon' },
    // [RECOMMENDATIONS_TYPE.ALT]: { unused: 'Change to annual billing', used: 'Coming soon' },
    _a[interfaces_1.RECOMMENDATIONS_TYPE.NEGOT] = { unused: 'Vendor negotiation', used: 'We’ll be in touch soon' },
    _a);
exports.CONFIRM_MESSAGE_DETAILS = (_b = {},
    // [RECOMMENDATIONS_TYPE.M2Y]: {
    //   unused: (recommendation) => `Follow the link below. You'll make a single annual payment and reduce your spend by
    //    ${formatNumber(recommendation.potentialSavings)} / yr.`,
    //   used: (recommendation) => `We're working on a feature to make this change from Hudled. Meanwhile, you'll need to do
    //    this directly from ${recommendation?.serviceStat?.service?.name}'s website following this link:`,
    // },
    _b[interfaces_1.RECOMMENDATIONS_TYPE.FX] = {
        unused: function () { return 'New customers from Hudled get $20,000 of free FX conversions.'; },
        used: function () { return 'Follow our exclusive link to sign up for Airwallex and access our deal'; },
    },
    // [RECOMMENDATIONS_TYPE.PAPR]: {
    //   unused: () => 'Through this deal, you’ll access a lifetime discount of 10%. The only caveat is that you’ll manage'
    //     + ' users from Hudled, as well as be billed with us. But you’ll manage all admin feature from the vendor’s site.',
    //   used: () => 'Thanks for your interest! We’re working on these partnerships and we’ll contact you as soon as we'
    //     + ' close a deal.',
    // },
    // [RECOMMENDATIONS_TYPE.DORM]: {
    //   unused: () => 'We can detect users for whom you’re paying a licence but the user is no longer active',
    //   used: () => 'Thanks for your interest! We’re working on this feature. We’ll contact you as soon as we close a'
    //     + 'deal.',
    // },
    // [RECOMMENDATIONS_TYPE.USUB]: {
    //   unused: (recommendation) => `It seems that this app is not being used by your team. You can consider removing it to
    //    save ${formatNumber(recommendation.potentialSavings)} / yr`,
    //   used: () => 'To cancel this tool, you will need to do this yourself. We’re working on this feature and we’ll'
    //     + ' contact you as soon as this is available',
    // },
    // [RECOMMENDATIONS_TYPE.ALT]: {
    //   unused: (recommendation) => `We're working on a feature to make this change from Hudled. Meanwhile, you'll need to
    //    do this directly from ${recommendation?.serviceStat?.service?.name}'s website following this link:`,
    //   used: (recommendation) => `You'll make a single payment of $2,883 and reduce you annual spend by
    //    ${formatNumber(recommendation.potentialSavings)}.`,
    // },
    _b[interfaces_1.RECOMMENDATIONS_TYPE.NEGOT] = {
        unused: function () { return 'Would you like to learn more from our expert in purchase and renewal your SaaS contracts?'; },
        used: function () { return 'We’ve saved your request for vendor negotiation. We’ll be in touch by email shortly.'; },
    },
    _b);
