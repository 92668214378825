"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var system_1 = require("@mui/system");
var LastCell = (0, system_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        width: 56,
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(0, 2),
        marginLeft: theme.spacing(1),
    });
});
exports.default = LastCell;
