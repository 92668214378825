"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CellSize = void 0;
exports.CellSize = {
    XL: 240,
    LG: 180,
    MD: 120,
    SM: 75,
    XSM: 48,
    XS: 30,
};
