"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatPercentPrecise = exports.formatPercent = exports.camelToSnake = exports.camelToLowerProper = exports.camelToProper = exports.formatMoney = void 0;
var end2end_1 = require("@hudled/end2end");
var formatRangeSpecs = [
    { limit: 1000000, postfix: 'M' },
    { limit: 1000, postfix: 'K' },
];
var formatMoney = function (amount, currency, format, maximumFractionDigitsParam, minimumFractionDigits, asForeignCurrency) {
    var e_1, _a;
    if (!amount && amount !== 0)
        return '';
    var maximumFractionDigits = maximumFractionDigitsParam !== null && maximumFractionDigitsParam !== void 0 ? maximumFractionDigitsParam : 0;
    var amountToBeFormatted = typeof amount === 'string' ? Number(amount) : amount;
    if (format) {
        try {
            for (var formatRangeSpecs_1 = __values(formatRangeSpecs), formatRangeSpecs_1_1 = formatRangeSpecs_1.next(); !formatRangeSpecs_1_1.done; formatRangeSpecs_1_1 = formatRangeSpecs_1.next()) {
                var _b = formatRangeSpecs_1_1.value, limit = _b.limit, postfix = _b.postfix;
                // ToDo: fix formatMoney(999999, true) === "1,000k" (edited)
                if (amountToBeFormatted && amountToBeFormatted > limit) {
                    return "".concat(formatMoneyNumber(amountToBeFormatted && amountToBeFormatted / limit, format, maximumFractionDigits, undefined, currency, asForeignCurrency)).concat(postfix);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (formatRangeSpecs_1_1 && !formatRangeSpecs_1_1.done && (_a = formatRangeSpecs_1.return)) _a.call(formatRangeSpecs_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    return formatMoneyNumber(amountToBeFormatted, false, maximumFractionDigits, minimumFractionDigits, currency, asForeignCurrency);
};
exports.formatMoney = formatMoney;
var formatMoneyNumber = function (amount, format, maxFractionDigits, minimumFractionDigits, currency, asForeignCurrency) {
    var maximumFractionDigits = !maxFractionDigits || maxFractionDigits < 2 ? 2 : maxFractionDigits;
    var intlFormatOptions = format
        ? { maximumSignificantDigits: amount <= 100 && format === 'NEW' ? 2 : 3 }
        : { maximumFractionDigits: maximumFractionDigits, minimumFractionDigits: minimumFractionDigits };
    var numStr = '';
    if (asForeignCurrency) {
        numStr = amount.toLocaleString(currency === 'USD' ? 'en-AU' : 'en-US', __assign(__assign({}, intlFormatOptions), { style: 'currency', currency: currency }));
    }
    else {
        var prefix = currency === '' || currency === null ? '' : (0, end2end_1.getCurrencySymbol)(currency);
        numStr = "".concat(prefix).concat(amount.toLocaleString(undefined, intlFormatOptions));
    }
    if (!format && maxFractionDigits === 0) {
        return numStr.replace(/\.\d+$/, '');
    }
    return numStr;
};
var camelToProper = function (str) { return str === null || str === void 0 ? void 0 : str.replace(/([A-Z])/g, ' $1').replace(/^(.)/g, function (c) { return c.toUpperCase(); }); };
exports.camelToProper = camelToProper;
var camelToLowerProper = function (str) { return str === null || str === void 0 ? void 0 : str.replace(/[A-Z]/g, function (c) { return " ".concat(c.toLowerCase()); }); }; // .replace(/^./g, (c) => c.toLowerCase());
exports.camelToLowerProper = camelToLowerProper;
var camelToSnake = function (str) { return str === null || str === void 0 ? void 0 : str.replace(/([A-Z])/g, '_$1').toLowerCase(); };
exports.camelToSnake = camelToSnake;
var formatPercent = function (percentage) { return "".concat(Math.round(percentage * 100), "%"); };
exports.formatPercent = formatPercent;
var formatPercentPrecise = function (percentage) { return percentage !== undefined
    ? "".concat(Math.round(percentage * 1000) / 10, "%")
    : undefined; };
exports.formatPercentPrecise = formatPercentPrecise;
