"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLORS = void 0;
exports.COLORS = {
    roseQuartz: {
        A600: '#EB94B3',
        A500: '#FF9FBF',
        A400: '#FFABC5',
        A300: '#FBD5E0',
        A100: '#FFF3F7',
    },
    neutral: {
        A600: '#A8B7C0',
        A500: '#9CA9B0',
        A400: '#B6C5CD',
        A300: '#D0D9DF',
        A100: '#EDF1F3', // use this or #E8ECEF?
    },
    green: {
        A600: '#048C58',
        A500: '#02AD6B',
        A400: '#30BC86',
        A300: '#9ADEC4',
        A100: '#E6F7F0',
    },
    red: {
        A600: '#D2372F',
        A500: '#E85048',
        A400: '#EC7069',
        A300: '#F9B9B6',
        A100: '#FEF1F0',
    },
    azzurro: {
        A600: '',
        A500: '#23AFEE',
        A400: '#46BAF1',
        A300: '#B6E5F9',
        A100: '#E7F6FD',
    },
    grey: {
        400: '#e0e0e0',
        500: '#e8e8e8',
        600: '#7F7F7F',
        A100: '#D1D7DB',
        // TODO: this is neutral A300 but we ran out of color tokens for the neutral palette, need to discuss
        // see https://mui.com/material-ui/customization/palette/#color-tokens
        300: '#D0D9DF',
    },
    white: '#FFFFFF',
    obsidian: '#011627',
    charcoal: '#3A4449',
    riverstone: '#70777C',
};
