"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var NotFoundHeader = (0, styles_1.styled)('span')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (__assign(__assign({}, theme.typography.h1), (_b = { lineHeight: '1', fontSize: '3rem', marginBottom: theme.spacing(1), wordBreak: 'break-word', display: 'block' }, _b[theme.breakpoints.down('lg')] = __assign(__assign({}, theme.typography.h1), { textAlign: 'center' }), _b)));
});
exports.default = NotFoundHeader;
