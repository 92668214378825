"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IMG_SIZES = void 0;
exports.IMG_SIZES = {
    sm: {
        size: 18,
        borderRadius: 2,
        fontSize: 14,
        marginRight: 0.75,
    },
    sm_plus: {
        size: 20,
        borderRadius: 3,
        fontSize: 16,
        marginRight: 1,
    },
    md: {
        size: 24,
        borderRadius: 3,
        fontSize: 20,
        marginRight: 1,
    },
    md_table: {
        size: 24,
        borderRadius: 3,
        fontSize: 16,
        marginRight: 1,
    },
    lg: {
        size: 32,
        borderRadius: 4,
        fontSize: 32,
        marginRight: 1.5,
    },
    xl: {
        size: 40,
        borderRadius: 4,
        fontSize: 32,
        marginRight: 1.5,
    },
};
