"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
exports.default = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        position: 'sticky',
        top: theme.spacing(8),
        marginRight: theme.spacing(-2),
        height: '78vh',
    });
});
