"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonSectionDefs = void 0;
var faSearch_1 = require("@fortawesome/pro-regular-svg-icons/faSearch");
var utils_1 = require("../utils");
exports.buttonSectionDefs = [
    {
        heading: 'Button Types',
        description: "The Button comes with three variants: Button (contained), LineButton (outlined),\n    TextButton (text), and BlendButton (gradient).",
        variations: [
            { component: 'Button' },
            { component: 'LineButton' },
            { component: 'TextButton' },
            { component: 'BlendButton' },
        ],
    },
    {
        heading: 'Button Colors',
        description: 'These colors are applicable to all variants except BlendButton.',
        variations: (0, utils_1.makePropVariations)('Button', 'color', ['primary', 'neutral', 'red', 'green', 'azzurro']),
    },
    {
        heading: 'Button Sizes',
        description: 'For larger or smaller buttons, use the size prop. The default size is regular.',
        // ToDo: make sure 'regular' is an allowed size
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        variations: (0, utils_1.makePropVariations)('Button', 'size', ['small', 'regular', 'large']),
    },
    {
        heading: 'Buttons with Icons',
        description: 'To add an icon to a button, use the icon prop.',
        variations: [
            { component: 'Button', properties: [{ prop: 'icon', v: faSearch_1.faSearch }] },
            { component: 'LineButton', properties: [{ prop: 'icon', v: faSearch_1.faSearch }] },
            { component: 'TextButton', properties: [{ prop: 'icon', v: faSearch_1.faSearch }] },
            { component: 'BlendButton', properties: [{ prop: 'icon', v: faSearch_1.faSearch }] },
        ],
    },
    {
        heading: 'Link Button',
        description: 'Add the url prop to make the button a link.',
        variations: (0, utils_1.makePropVariations)('Button', 'url', ['/dashboard']),
    },
    {
        heading: 'Disabled state',
        description: 'To disable a button, use the disabled prop.',
        variations: (0, utils_1.makePropVariations)('Button', 'disabled', [true]),
    },
    {
        heading: 'fullWidth prop',
        description: 'To make a button span the full width of its container, use the fullWidth prop.',
        variations: (0, utils_1.makePropVariations)('Button', 'fullWidth', [true]),
    },
    {
        heading: 'isSending state',
        description: 'isSending disables the button and loads a spinner to indicate that the action is in progress.',
        variations: (0, utils_1.makePropVariations)('Button', 'isSending', [true]),
    },
    {
        heading: 'isSubmitted state',
        description: 'isSubmitted disables the button and loads a checkmark to indicate that the action is complete.',
        variations: (0, utils_1.makePropVariations)('Button', 'isSubmitted', [true]),
    },
];
