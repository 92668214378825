"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var faCheckCircle_1 = require("@fortawesome/pro-solid-svg-icons/faCheckCircle");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var OnboardingStepIcon = function (_a) {
    var isDone = _a.isDone;
    var color = isDone ? 'green-dark' : 'mute';
    return ((0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: faCheckCircle_1.faCheckCircle, className: "color-".concat(color) }));
};
exports.default = OnboardingStepIcon;
