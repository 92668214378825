"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var TableBody = (0, styles_1.styled)(material_1.TableBody)(function (_a) {
    var theme = _a.theme;
    return ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        'tr#accordionRow:hover > td': {
            background: theme.palette.secondary.light,
        },
    });
});
exports.default = TableBody;
