"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var pro_regular_svg_icons_1 = require("@fortawesome/pro-regular-svg-icons");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var faCheckCircle_1 = require("@fortawesome/pro-solid-svg-icons/faCheckCircle");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var STEP_ICON_MAPPER = {
    default: {
        icon: pro_regular_svg_icons_1.faCircle,
        color: '#D0D9DF',
    },
    pending: {
        icon: pro_solid_svg_icons_1.faClock,
        color: '#D0D9DF',
    },
    success: {
        icon: faCheckCircle_1.faCheckCircle,
        color: '#02AD6B',
    },
    error: {
        icon: pro_regular_svg_icons_1.faCircleXmark,
        color: '#E85048',
    },
};
var StepIcon = function (_a) {
    var _b = _a.status, status = _b === void 0 ? 'default' : _b;
    return ((0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: STEP_ICON_MAPPER[status].icon, color: STEP_ICON_MAPPER[status].color }));
};
exports.default = StepIcon;
