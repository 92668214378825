"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var ReportWrapper = (0, styles_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            paddingLeft: theme.spacing(4),
            // paddingRight: theme.spacing(4),
            paddingBottom: theme.spacing(8),
            margin: 'auto',
            maxWidth: 1000
        },
        _b[theme.breakpoints.up('md')] = {
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8),
        },
        _b);
});
exports.default = ReportWrapper;
