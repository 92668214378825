import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';
import { capitalize } from '@mui/material/utils';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const PREFIX = 'TextField';
const FONT_FELLIX = '"Fellix", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",'
  + ' Roboto, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  inputBorder: `${PREFIX}-inputBorder`,
  disabled: `${PREFIX}-disabled`,
  inputSizeSmall: `${PREFIX}-inputSizeSmall`,
  inputSizeMedium: `${PREFIX}-inputSizeMedium`,
  inputSizeLarge: `${PREFIX}-inputSizeLarge`,
  inputSizeXlarge: `${PREFIX}-inputSizeXlarge`,
  formLabel: `${PREFIX}-formLabel`,
  select: `${PREFIX}-select`,
  selectIcon: `${PREFIX}-selectIcon`,
};

const StyledMuiTextField = styled(MuiTextField)(({ theme }) => ({
  [`.${classes.root}`]: {
    padding: 0,
    marginTop: 0,
    fontFamily: FONT_FELLIX,
    // flexDirection: 'row',
    // alignItems: 'baseline',
    // justifyContent: 'flex-end',
  },

  [`.${classes.input}`]: {
    minWidth: theme.spacing(6),
    backgroundColor: theme.palette.common.white,
    '&$disabled': {
      backgroundColor: theme.palette.divider,
    },
  },

  [`.${classes.inputBorder}`]: {
    border: '1px solid #e9ddd0',
    borderRadius: 4,
    '&:focus': {
      borderColor: theme.palette.secondary.main,
    },
  },

  [`.${classes.disabled}`]: {},

  [`.${classes.inputSizeSmall}`]: {
    fontSize: 14,
    padding: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
  },

  [`.${classes.inputSizeMedium}`]: {
    fontSize: 14,
    padding: theme.spacing(1),
    // width: `calc(100% - ${theme.spacing(4)})`,
    width: 270,
  },

  [`.${classes.inputSizeLarge}`]: {
    fontSize: 18,
    padding: 22,
    width: `calc(100% - ${22 * 2}px)`,
  },

  [`.${classes.inputSizeXlarge}`]: {
    fontSize: 20,
    padding: 25,
    width: `calc(100% - ${25 * 2}px)`,
  },

  [`.${classes.formLabel}`]: {
    fontSize: 14,
    marginRight: 16,
    color: '#011627',
    position: 'relative',
    transform: 'initial',
    fontFamily: FONT_FELLIX,
  },

  [`.${classes.select}`]: {
    height: 'auto',
    borderRadius: 0,
  },

  [`.${classes.selectIcon}`]: {
    top: '50%',
    marginTop: -12,
  },
}));

function TextField(props) {
  const {
    InputProps = {},
    InputLabelProps,
    noBorder = false,
    size = 'medium',
    SelectProps,
    ...other
  } = props;

  const {
    classes: { input: InputPropsClassesInput, ...InputPropsClassesOther } = {},
    ...InputPropsOther
  } = InputProps;

  return (
    <StyledMuiTextField
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.root,
          input: clsx(
            classes.input,
            classes[`inputSize${capitalize(size)}`],
            {
              [classes.inputBorder]: !noBorder,
            },
            InputPropsClassesInput,
          ),
          disabled: classes.disabled,
          ...InputPropsClassesOther,
        },
        ...InputPropsOther,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        className: classes.formLabel,
      }}
      SelectProps={{
        ...SelectProps,
        classes: {
          select: classes.select,
          icon: classes.selectIcon,
        },
      }}
      {...other}
    />
  );
}

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  noBorder: PropTypes.bool,
  SelectProps: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
};

export default (TextField);
