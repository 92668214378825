"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var end2end_1 = require("@hudled/end2end");
var react_1 = require("react");
var hooks_1 = require("utils/hooks");
var RedirectToDomain = function (_a) {
    var match = _a.match, location = _a.location;
    var app = match.params.app;
    (0, react_1.useEffect)(function () { return (0, hooks_1.redirectToDomain)(app, location); }, []);
    var qParams = (0, hooks_1.useQParams)();
    var state = (0, end2end_1.parseStateString)(qParams.state);
    var domain = state.domainCode && end2end_1.DOMAIN_MAP[state.domainCode];
    if (!domain) {
        return (0, jsx_runtime_1.jsx)("div", { children: "Error" });
    }
    return (0, jsx_runtime_1.jsx)("div", { children: "Loading..." });
};
exports.default = RedirectToDomain;
