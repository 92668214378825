"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var LayoutCenter = (0, material_1.styled)(material_1.Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '100%',
    background: '#F6F6F0',
});
exports.default = LayoutCenter;
