"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectXeroAccounts = exports.selectNewRechargedVendors = exports.selectRechargedCompanyVendors = exports.selectRechargedVendors = exports.selectAllRechargedVendors = exports.selectPayouts = exports.makeSelectRechargedVendorPlansForVendor = exports.selectAllRechargedVendorPlans = exports.makeSelectServicesWithDeals = exports.selectServiceStats = exports.selectRecommendations = exports.selectAllRecommendations = exports.selectActiveIntegrationConnections = exports.selectIntegrationConnections = exports.selectPaymentsConnections = exports.selectUsers = exports.makeSelectRecommendationsForService = exports.makeSelectServiceComparisons = exports.makeSelectInsightRequestsForService = exports.makeSelectTransactionsForService = exports.makeSelectAllTransactionsForService = exports.makeSelectUsersForCompany = exports.selectConfirmedRechargedOrganizationsWithRecharges = exports.selectConfirmedRechargedOrganizations = exports.selectNonExcludedRecharges = exports.selectRecharges = exports.selectStripeMigratedCustomers = exports.selectRechargedCustomers = exports.selectTransactionContacts = exports.selectCompanyTeams = exports.selectAccountants = exports.selectAccountingFirms = exports.selectCompaniesForAccountants = exports.selectAllCompaniesForAccountants = exports.selectCompaniesForAdmins = exports.selectRecharglySubscriptions = exports.selectRechargerCompanies = exports.selectCompanies = exports.selectMetaCategories = exports.selectInvitations = exports.selectAllUsers = exports.selectReviews = exports.selectAccessRequests = exports.selectAllCompanies = exports.selectInsightRequests = exports.selectAllServices = exports.selectReferences = exports.selectServices = exports.selectQuoteReportBundles = exports.selectQuoteAnalysisBundles = void 0;
exports.selectXeroBankAccounts = exports.selectXeroAccountsForExpenses = exports.selectXeroAccountsForRevenue = exports.selectRechContacts = void 0;
var end2end_1 = require("@hudled/end2end");
var reselect_1 = require("reselect");
var utils_1 = require("./utils");
var selectAllIntegrationConnections = (0, utils_1.makeSelectOrdered)('integration_connections');
var selectAllPaymentsConnections = (0, utils_1.makeSelectOrdered)('payments_connections');
var selectAllServiceStats = (0, utils_1.makeSelectOrdered)('service_stats');
exports.selectQuoteAnalysisBundles = (0, utils_1.makeSelectOrdered)('quote_analysis_bundles');
exports.selectQuoteReportBundles = (0, utils_1.makeSelectOrdered)('quote_report_bundles');
exports.selectServices = (0, utils_1.makeSelectOrdered)('services');
exports.selectReferences = (0, utils_1.makeSelectOrdered)('references');
exports.selectAllServices = (0, utils_1.makeSelectOrdered)('allServices');
exports.selectInsightRequests = (0, utils_1.makeSelectOrdered)('insight_requests');
exports.selectAllCompanies = (0, utils_1.makeSelectOrdered)('allCompanies');
exports.selectAccessRequests = (0, utils_1.makeSelectOrdered)('access_requests');
exports.selectReviews = (0, utils_1.makeSelectOrdered)('reviews');
exports.selectAllUsers = (0, utils_1.makeSelectOrdered)('public_users');
exports.selectInvitations = (0, utils_1.makeSelectOrdered)('invitations');
exports.selectMetaCategories = (0, utils_1.makeSelectOrdered)('meta_categories');
exports.selectCompanies = (0, utils_1.makeSelectOrdered)('companies');
exports.selectRechargerCompanies = (0, utils_1.makeSelectOrdered)('recharger_companies');
exports.selectRecharglySubscriptions = (0, utils_1.makeSelectOrdered)('rechargly_subscriptions');
exports.selectCompaniesForAdmins = (0, utils_1.makeSelectOrdered)('companies_for_admin');
exports.selectAllCompaniesForAccountants = (0, utils_1.makeSelectOrdered)('companies_for_accountant');
exports.selectCompaniesForAccountants = (0, reselect_1.createSelector)(exports.selectAllCompaniesForAccountants, function (companies) { return companies && companies.filter(function (c) { return !c.isRechargeClient; }); });
exports.selectAccountingFirms = (0, utils_1.makeSelectOrdered)('accountingFirms');
exports.selectAccountants = (0, utils_1.makeSelectOrdered)('accountants');
exports.selectCompanyTeams = (0, utils_1.makeSelectOrdered)('company_teams');
exports.selectTransactionContacts = (0, utils_1.makeSelectOrdered)('transaction_contacts');
var selectAllRechargedCustomers = (0, utils_1.makeSelectOrdered)('recharged_customers');
exports.selectRechargedCustomers = (0, reselect_1.createSelector)(selectAllRechargedCustomers, function (rechCustomers) { return rechCustomers && rechCustomers.filter(function (rC) { return !rC.isSoftDelete; }); });
exports.selectStripeMigratedCustomers = (0, utils_1.makeSelectOrdered)('stripe_migrated_customers');
exports.selectRecharges = (0, utils_1.makeSelectOrdered)('recharges');
exports.selectNonExcludedRecharges = (0, reselect_1.createSelector)(exports.selectRecharges, function (recharges) { return recharges === null || recharges === void 0 ? void 0 : recharges.filter(function (r) { return !r.isExcluded; }); });
exports.selectConfirmedRechargedOrganizations = (0, reselect_1.createSelector)((0, utils_1.makeSelectOrdered)('recharged_organizations'), function (rechOrgs) { return rechOrgs && rechOrgs.filter(function (rO) { return rO.matchStatus === 'confirmed'; }); });
exports.selectConfirmedRechargedOrganizationsWithRecharges = (0, reselect_1.createSelector)((0, utils_1.makeSelectOrdered)('recharged_organizations'), exports.selectNonExcludedRecharges, function (rechOrgs, recharges) { return rechOrgs
    && rechOrgs.filter(function (rO) { return rO.matchStatus === 'confirmed' && (recharges === null || recharges === void 0 ? void 0 : recharges.find(function (r) { return r.rechargedOrganizationId === rO.id; })); }); });
var makeSelectUsersForCompany = function (companyId) { return (0, utils_1.makeSelectOrdered)("".concat(companyId, "Users")); };
exports.makeSelectUsersForCompany = makeSelectUsersForCompany;
var makeSelectAllTransactionsForService = function (serviceId) { return (0, utils_1.makeSelectOrdered)("".concat(serviceId, "Transactions")); };
exports.makeSelectAllTransactionsForService = makeSelectAllTransactionsForService;
var getLineItemsForService = function (serviceId, transaction) {
    var _a, _b;
    return (_b = (_a = transaction
        .lineItems) === null || _a === void 0 ? void 0 : _a.map(function (lI, idx) { return lI.serviceId === serviceId && __assign(__assign({}, transaction), { tId: transaction.id, id: "".concat(transaction.id, "-").concat(idx), reference: lI.description, amount: lI.amount, isLineItem: true }); }).filter(end2end_1.notFalsy)) !== null && _b !== void 0 ? _b : [];
};
var makeSelectTransactionsForService = function (serviceId) { return (0, reselect_1.createSelector)((0, exports.makeSelectAllTransactionsForService)(serviceId), function (transactions) { return transactions && transactions
    .map(function (t) { return t.serviceId === serviceId ? t : getLineItemsForService(serviceId, t); })
    .flat()
    .filter(end2end_1.notFalsy); }); };
exports.makeSelectTransactionsForService = makeSelectTransactionsForService;
var makeSelectInsightRequestsForService = function (serviceId) { return (0, utils_1.makeSelectOrdered)("".concat(serviceId, "InsightRequests")); };
exports.makeSelectInsightRequestsForService = makeSelectInsightRequestsForService;
var makeSelectServiceComparisons = function (serviceId) { return (0, utils_1.makeSelectOrdered)("".concat(serviceId, "Comparisons")); };
exports.makeSelectServiceComparisons = makeSelectServiceComparisons;
var makeSelectRecommendationsForService = function (sId) { return (0, utils_1.makeSelectOrdered)("".concat(sId, "Recommendations")); };
exports.makeSelectRecommendationsForService = makeSelectRecommendationsForService;
exports.selectUsers = (0, reselect_1.createSelector)(exports.selectAllUsers, function (users) { return users && users.filter(function (u) { return !u.isSuspended; }); });
exports.selectPaymentsConnections = (0, reselect_1.createSelector)(selectAllPaymentsConnections, function (payConns) { return payConns && payConns.filter(function (c) { return !c.deletedOn; }); });
exports.selectIntegrationConnections = (0, reselect_1.createSelector)(selectAllIntegrationConnections, function (intConns) { return intConns && intConns.filter(function (iC) { return (0, end2end_1.isIntConnVisible)(iC) && (end2end_1.IS_RECHARGLY ? (0, end2end_1.isRechargeAf)(iC) : !(0, end2end_1.isRechargeAf)(iC)); }); });
exports.selectActiveIntegrationConnections = (0, reselect_1.createSelector)(selectAllIntegrationConnections, function (intConns) { return intConns && intConns.filter(end2end_1.isIntConnVisible); });
exports.selectAllRecommendations = (0, utils_1.makeSelectOrdered)('recommendations');
exports.selectRecommendations = (0, reselect_1.createSelector)(exports.selectAllRecommendations, function (recommendations) { return recommendations && recommendations.filter(function (r) { return !r.isSoftDelete; }); });
exports.selectServiceStats = (0, reselect_1.createSelector)(selectAllServiceStats, function (serviceStats) { return serviceStats && serviceStats.filter(function (_a) {
    var isSoftDelete = _a.isSoftDelete;
    return !isSoftDelete;
}); });
var makeSelectServicesWithDeals = function (metaCategoryId) { return function (_a) {
    var firestore = _a.firestore;
    return metaCategoryId
        ? firestore.ordered.services
            && firestore.ordered.services.filter(function (deal) { return deal.metaCategoryId === metaCategoryId; })
        : firestore.ordered.services;
}; };
exports.makeSelectServicesWithDeals = makeSelectServicesWithDeals;
exports.selectAllRechargedVendorPlans = (0, utils_1.makeSelectOrdered)('recharged_vendor_plans');
var makeSelectRechargedVendorPlansForVendor = function (vendorId) { return (0, reselect_1.createSelector)(exports.selectAllRechargedVendorPlans, function (vendorPlans) { return vendorPlans && vendorPlans.filter(function (v) { var _a; return ((_a = v.rechVendorId) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === (vendorId === null || vendorId === void 0 ? void 0 : vendorId.toLowerCase()); }); }); };
exports.makeSelectRechargedVendorPlansForVendor = makeSelectRechargedVendorPlansForVendor;
exports.selectPayouts = (0, utils_1.makeSelectOrdered)('stripe_paid_payout_events');
exports.selectAllRechargedVendors = (0, utils_1.makeSelectOrdered)('recharged_vendors');
exports.selectRechargedVendors = (0, reselect_1.createSelector)(exports.selectAllRechargedVendors, function (vendors) { return vendors && vendors.filter(function (v) { return v.enabled; }); });
exports.selectRechargedCompanyVendors = (0, utils_1.makeSelectOrdered)('recharged_company_vendors');
exports.selectNewRechargedVendors = (0, reselect_1.createSelector)(exports.selectRechargedVendors, exports.selectRechargedCompanyVendors, function (vendors, rechCompVendors) { return vendors && rechCompVendors
    && vendors.filter(function (v) { return !rechCompVendors.find(function (rCV) { return rCV.rechVendorId === v.id; }); }); });
exports.selectXeroAccounts = (0, utils_1.makeSelectOrdered)('xero_accounts');
exports.selectRechContacts = (0, utils_1.makeSelectOrdered)('rech_contacts');
exports.selectXeroAccountsForRevenue = (0, reselect_1.createSelector)(exports.selectXeroAccounts, function (xeroAccounts) { return xeroAccounts && xeroAccounts.filter(function (xA) { return xA.Class === 'REVENUE'; }); });
exports.selectXeroAccountsForExpenses = (0, reselect_1.createSelector)(exports.selectXeroAccounts, function (xeroAccounts) { return xeroAccounts && xeroAccounts.filter(function (xA) { return xA.Class === 'EXPENSE'; }); });
exports.selectXeroBankAccounts = (0, reselect_1.createSelector)(exports.selectXeroAccounts, function (xeroAccounts) { return xeroAccounts && xeroAccounts.filter(function (xA) { return xA.Class === 'ASSET' && xA.Type === 'BANK'; }); });
