"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readFile = void 0;
var readFile = function (file) { return new Promise(function (resolve, reject) {
    var fr = new FileReader();
    fr.onload = function () {
        resolve(fr.result);
    };
    fr.onerror = reject;
    fr.readAsText(file);
}); };
exports.readFile = readFile;
