"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeToggleSort = exports.getNextOrder = void 0;
/**
 * If unsorted or sorted by another column, set to defaultOrder
 * if sorted by defaultOrder set to the other one
 * otherwise if set to the second order, unset
 * */
var getNextOrder = function (sortKey, defaultOrder, sort) {
    if (!sort || sort.key !== sortKey)
        return defaultOrder;
    if (sort.order === defaultOrder)
        return defaultOrder * -1;
    return null;
};
exports.getNextOrder = getNextOrder;
var makeToggleSort = function (setSort, sortKey, defaultOrder, sort) { return function () {
    var nextOrder = (0, exports.getNextOrder)(sortKey, defaultOrder, sort);
    setSort(nextOrder ? { key: sortKey, order: nextOrder } : undefined);
}; };
exports.makeToggleSort = makeToggleSort;
