import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Badge = ({
  children, color = 'white', type, noMargin, width, className, outline, size,
}) => (
  <span
    className={classNames(
      'ds-badge',
      color,
      type,
      { noMargin, outline },
      size && `size-${size}`,
      width && `width__${width}`,
      className,
    )}
  >
    {children}
  </span>
);

Badge.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  noMargin: PropTypes.string,
  size: PropTypes.string,
  outline: PropTypes.bool,
  width: PropTypes.oneOf([6, 8, 10, 12, 14, 16, 20]),
};

export default Badge;
