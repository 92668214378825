"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmallTitle = exports.KpiWrapper = exports.Title = exports.WrapperTop = exports.Heading = void 0;
var styles_1 = require("@mui/material/styles");
exports.Heading = (0, styles_1.styled)('span')(function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: 20,
        fontWeight: 600,
        color: theme.palette.text.secondary,
        letterSpacing: '0.023em',
    });
});
exports.WrapperTop = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        width: '1000px',
        margin: theme.spacing(1, 'auto', 2, 'auto'),
    });
});
exports.Title = (0, styles_1.styled)('span')(function (_a) {
    var theme = _a.theme;
    return (__assign(__assign({}, theme.typography.h1), { fontSize: '2.5rem', marginBottom: 0 }));
});
exports.KpiWrapper = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        textAlign: 'left',
        padding: theme.spacing(1, 0),
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'column',
    });
});
exports.SmallTitle = (0, styles_1.styled)('span')({
    fontSize: '2rem',
});
