"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RECHARGER_STATUS_MAPPER = exports.NON_ACTIONABLE_STATES = void 0;
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var pro_regular_svg_icons_1 = require("@fortawesome/pro-regular-svg-icons");
var faCircleXmark_1 = require("@fortawesome/pro-regular-svg-icons/faCircleXmark");
exports.NON_ACTIONABLE_STATES = ['paid', 'includedInInvoice', 'failed'];
exports.RECHARGER_STATUS_MAPPER = {
    new: {
        text: 'New', iconColor: '#B6C5CD', icon: pro_regular_svg_icons_1.faCircleDot,
    },
    excluded: {
        text: 'Excluded', iconColor: '#F9B9B6', icon: pro_regular_svg_icons_1.faFileSlash,
    },
    nextInvoice: {
        text: 'Next Invoice', iconColor: '#46BAF1', icon: pro_light_svg_icons_1.faFileInvoiceDollar,
    },
    includedInInvoice: {
        text: 'Invoiced', iconColor: '#02AD6B', icon: pro_light_svg_icons_1.faMemoCircleCheck,
    },
    paid: {
        text: 'Paid', iconColor: '#02AD6B', icon: pro_regular_svg_icons_1.faCircleDollar,
    },
    failed: {
        text: 'Failed', iconColor: '#EC7069', icon: faCircleXmark_1.faCircleXmark,
    },
};
