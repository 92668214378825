"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
exports.default = (0, material_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(3, 0),
        '& p': {
            borderTop: '1px solid #dbdbdb',
            padding: theme.spacing(1, 0),
        },
        '& p:last-child': {
            borderBottom: '1px solid #dbdbdb',
        },
    });
});
