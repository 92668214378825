"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AGREEMENT_STATUS_MAPPER = void 0;
var faCheckCircle_1 = require("@fortawesome/pro-light-svg-icons/faCheckCircle");
var faCircleArrowRight_1 = require("@fortawesome/pro-light-svg-icons/faCircleArrowRight");
var faCircleXmark_1 = require("@fortawesome/pro-light-svg-icons/faCircleXmark");
var faEnvelopeOpen_1 = require("@fortawesome/pro-light-svg-icons/faEnvelopeOpen");
var faFileInvoiceDollar_1 = require("@fortawesome/pro-light-svg-icons/faFileInvoiceDollar");
var faBallotCheck_1 = require("@fortawesome/pro-regular-svg-icons/faBallotCheck");
var constants_1 = require("common/constants");
exports.AGREEMENT_STATUS_MAPPER = {
    opened_by_email: {
        text: 'Opened',
        iconColor: constants_1.COLORS.azzurro.A400,
        icon: faEnvelopeOpen_1.faEnvelopeOpen,
        tooltip: { description: 'MD:The agreement link sent by **email** was opened by someone.' },
    },
    opened_by_copy_link: {
        text: 'Opened (by link)',
        iconColor: constants_1.COLORS.azzurro.A400,
        icon: faEnvelopeOpen_1.faEnvelopeOpen,
        tooltip: { description: 'MD:The agreement shared by **Copy link** was opened by someone. The **email** link wasn’t opened.' },
    },
    opened_by_all: {
        text: 'Opened',
        iconColor: constants_1.COLORS.azzurro.A400,
        icon: faEnvelopeOpen_1.faEnvelopeOpen,
        tooltip: { description: 'MD:The agreement link was opened both from **email** and the **Copy link** share button.' },
    },
    scheduled: {
        text: 'Scheduled for sending', iconColor: constants_1.COLORS.azzurro.A400, icon: faFileInvoiceDollar_1.faFileInvoiceDollar,
    },
    sent: {
        text: 'Sent', iconColor: constants_1.COLORS.azzurro.A400, icon: faBallotCheck_1.faBallotCheck,
    },
    accepted: {
        text: 'Accepted', iconColor: constants_1.COLORS.green.A500, icon: faCheckCircle_1.faCheckCircle,
    },
    cancelled: {
        text: 'Cancelled', iconColor: constants_1.COLORS.neutral.A500, icon: faCircleXmark_1.faCircleXmark,
    },
    migrated: {
        text: 'Migrated', iconColor: constants_1.COLORS.green.A500, icon: faCircleArrowRight_1.faCircleArrowRight,
    },
};
