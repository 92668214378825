"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textfieldSectionDef = void 0;
exports.textfieldSectionDef = [
    {
        heading: 'TextField variants',
        description: 'TextField consists of three types: standard, filled, and outlined. It uses the standard type by default.',
        variations: [
            { component: 'TextField', noChildren: true, properties: [{ prop: 'label', v: 'label' }, { prop: 'variant', v: 'standard' }] },
            { component: 'TextField', noChildren: true, properties: [{ prop: 'label', v: 'label' }, { prop: 'variant', v: 'filled' }] },
            { component: 'TextField', noChildren: true, properties: [{ prop: 'label', v: 'label' }, { prop: 'variant', v: 'outlined' }] },
        ],
    },
    {
        heading: 'placeholder',
        description: 'The short hint displayed in the input before the user enters a value.',
        variations: [{ component: 'TextField', noChildren: true, properties: [{ prop: 'placeholder', v: 'placeholder' }] }],
    },
    {
        heading: 'Textfield sizes',
        description: 'TextField has small and medium sizes. It uses the medium size by default.',
        variations: [
            { component: 'TextField', noChildren: true, properties: [{ prop: 'label', v: 'medium' }, { prop: 'size', v: 'medium' }] },
            { component: 'TextField', noChildren: true, properties: [{ prop: 'label', v: 'small' }, { prop: 'size', v: 'small' }] },
        ],
    },
    {
        heading: 'TextField Colors',
        description: 'These colors are applicable to all variants. It uses primary as default',
        variations: [
            {
                component: 'TextField',
                noChildren: true,
                properties: [{ prop: 'label', v: 'primary' }, { prop: 'color', v: 'primary' }],
            },
            {
                component: 'TextField',
                noChildren: true,
                properties: [{ prop: 'label', v: 'neutral' }, { prop: 'color', v: 'neutral' }],
            },
            {
                component: 'TextField',
                noChildren: true,
                properties: [{ prop: 'label', v: 'red' }, { prop: 'color', v: 'red' }],
            },
            {
                component: 'TextField',
                noChildren: true,
                properties: [{ prop: 'label', v: 'green' }, { prop: 'color', v: 'green' }],
            },
            {
                component: 'TextField',
                noChildren: true,
                properties: [{ prop: 'label', v: 'azzurro' }, { prop: 'color', v: 'azzurro' }],
            },
        ],
    },
    {
        heading: 'fullWidth option',
        description: 'If true, the input will take up the full width of its container.',
        variations: [
            {
                component: 'TextField',
                noChildren: true,
                properties: [{ prop: 'label', v: 'label' }, { prop: 'fullWidth', v: true }],
            },
        ],
    },
    {
        heading: 'disabled option',
        description: 'To disable the TextField, set the disabled prop to true.',
        variations: [
            {
                component: 'TextField',
                noChildren: true,
                properties: [{ prop: 'label', v: 'label' }, { prop: 'disabled', v: true }],
            },
        ],
    },
    {
        heading: 'helperText option',
        description: 'To give context to the TextField, use the helperText prop.',
        variations: [
            {
                component: 'TextField',
                noChildren: true,
                properties: [{ prop: 'label', v: 'label' }, { prop: 'helperText', v: 'helperText' }],
            },
        ],
    },
    {
        heading: 'error option',
        description: 'If true, the label is displayed in an error state.',
        variations: [
            {
                component: 'TextField',
                noChildren: true,
                properties: [{ prop: 'label', v: 'label' }, { prop: 'error', v: true }, { prop: 'helperText', v: 'Required' }],
            },
        ],
    },
    {
        heading: 'multiline option',
        description: "If true,  a textarea element is rendered instead of an input. This is set to false by default. \n    Use rows to define the number of rows the textarea element should have. \n    Use maxRows to define the maximum number of rows the textarea element should have.",
        variations: [
            {
                component: 'TextField',
                noChildren: true,
                properties: [{ prop: 'label', v: 'label' }, { prop: 'multiline', v: true }, { prop: 'rows', v: 4 }, { prop: 'maxRows', v: 8 }],
            },
        ],
    },
];
