"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var CheckboxWrapper = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'inline-flex',
        alignItems: 'center',
        height: theme.spacing(4),
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0, 0.5),
    });
});
exports.default = CheckboxWrapper;
