"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var PropagationBlocker = function (_a) {
    var children = _a.children, allowPropagation = _a.allowPropagation;
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: !allowPropagation ? children
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            : (0, jsx_runtime_1.jsx)("span", __assign({ onClick: function (e) { return e.stopPropagation(); }, style: { width: '100%' } }, { children: children })) }));
};
exports.default = PropagationBlocker;
