"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var general_1 = require("utils/general");
var CheckboxCellWrapper = (0, styles_1.styled)('div', { shouldForwardProp: (0, general_1.omitProps)('color') })(function (_a) {
    var _b, _c, _d;
    var theme = _a.theme, color = _a.color, selected = _a.selected;
    return ({
        position: 'absolute',
        top: 18,
        left: -5,
        backgroundColor: selected ? (_b = theme.palette.azzurro) === null || _b === void 0 ? void 0 : _b.light : theme.palette.common.white,
        borderRadius: theme.spacing(0.375),
        padding: theme.spacing(0.125, 0.5),
        border: '1px solid transparent',
        ':hover': !selected && {
            borderColor: (_c = theme.palette[color]) === null || _c === void 0 ? void 0 : _c.main,
            svg: { color: (_d = theme.palette[color]) === null || _d === void 0 ? void 0 : _d.main },
            cursor: 'pointer',
        },
        ':focus-visible': !selected && __assign(__assign({}, theme.focusVisibleStyles), { borderColor: theme.palette.primary.light }),
        height: theme.spacing(3.5),
        width: theme.spacing(3.5),
        display: 'inline-flex',
        alignItems: 'center',
    });
});
exports.default = CheckboxCellWrapper;
