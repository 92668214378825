"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var CommentWrapper = (0, material_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        alignitems: 'flex-start',
        margin: theme.spacing(1, 0),
        width: '100%',
        ':hover': {
            '.MuiIconButton-root': {
                visibility: 'visible',
            },
        },
    });
});
exports.default = CommentWrapper;
