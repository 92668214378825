/**
 * ServicesList
 */

import React from 'react';
import { useSelector, connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { createStructuredSelector } from 'reselect';

import LoadingContent from 'components/Spinners/LoadingContent';
import { selectMyCompany, selectAuth, selectProfileWithId, selectMyRechargerCompany } from 'utils/selectors/single';

export const HasCompanyHoC = (Component, noLoader, needsRechargerCompany) => {
  const WrappedComponent = (props) => {
    const profile = useSelector(selectProfileWithId);
    const company = useSelector(selectMyCompany);
    const rechargerCompany = useSelector(selectMyRechargerCompany);
    if (!isLoaded(profile) || !isLoaded(company) || (needsRechargerCompany && !isLoaded(rechargerCompany))) {
      return noLoader ? null : <LoadingContent />;
    }
    if (!profile.companyId) {
      return noLoader ? null : <div><h1>No Access yet</h1></div>;
    }
    if (!company || (needsRechargerCompany && !rechargerCompany)) {
      return noLoader ? null : <LoadingContent />;
    }

    return <Component profile={profile} company={company} {...props} />;
  };
  WrappedComponent.propTypes = {};

  return WrappedComponent;
};

export const AuthHoC = connect(createStructuredSelector({ authState: selectAuth, profile: selectProfileWithId }));
