"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.makePattern = exports.makeRePattern = exports.makeRePatternStr = exports.MONTHS_SHORT = exports.getShortMonth = exports.inStandardMiniRanges = exports.findMiniRange = exports.isAround = exports.MULTI_MONTH_MAP = exports.RANGE_INCREMENT = exports.STANDARD_RANGES = exports.STANDARD_SHORT_RANGES = void 0;
var constants_1 = require("./constants");
exports.STANDARD_SHORT_RANGES = [29, 61, 91];
exports.STANDARD_RANGES = __spreadArray(__spreadArray([], __read(exports.STANDARD_SHORT_RANGES), false), [182, 365], false);
exports.RANGE_INCREMENT = {
    29: { months: 1 },
    61: { months: 2 },
    91: { months: 3 },
    182: { months: 6 },
    365: { months: 12 },
};
exports.MULTI_MONTH_MAP = {
    1: 'Monthly',
    2: 'Two-monthly',
    3: 'Quarterly',
    6: 'Half-yearly',
    12: 'Yearly',
};
var isAround = function (d, rng) { return d >= rng - 3 && d <= rng + 3; };
exports.isAround = isAround;
var findMiniRange = function (ranges) { return function (d) { return ranges.find(function (rng) { return (0, exports.isAround)(d, rng); }); }; };
exports.findMiniRange = findMiniRange;
exports.inStandardMiniRanges = (0, exports.findMiniRange)(exports.STANDARD_RANGES);
var getShortMonth = function (m) { return m.substring(0, 3).toLowerCase(); };
exports.getShortMonth = getShortMonth;
exports.MONTHS_SHORT = constants_1.MONTHS.map(exports.getShortMonth);
// escapeRegExp
var makeRePatternStr = function (parts, separator, postfix) {
    if (postfix === void 0) { postfix = ''; }
    var partsPart = parts.map(function (p) { return postfix ? constants_1.PARTS[p].replace(/<(\w+)>/, "<$1".concat(postfix, ">")) : constants_1.PARTS[p]; }).join(separator);
    return "(?<dateStr".concat(postfix, ">").concat(partsPart, ")");
};
exports.makeRePatternStr = makeRePatternStr;
// escapeRegExp
var makeRePattern = function (parts, separator, postfix) {
    if (postfix === void 0) { postfix = ''; }
    var leftDelimiter = parts[0] === 'mW' ? '\\b' : '(?<!\\d)';
    var rightDelimiter = parts[parts.length - 1] === 'mW' ? '\\b' : '(?!\\d)';
    return new RegExp("".concat(leftDelimiter).concat((0, exports.makeRePatternStr)(parts, separator, postfix)).concat(rightDelimiter), 'gi');
};
exports.makeRePattern = makeRePattern;
var makePattern = function (parts, separator) { return ({
    name: parts.join(separator),
    re: (0, exports.makeRePattern)(parts, separator)
}); };
exports.makePattern = makePattern;
