"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var general_1 = require("utils/general");
var FadeoutBottom = (0, styles_1.styled)('div', { shouldForwardProp: (0, general_1.omitProps)('height', 'widthPercent') })(function (_a) {
    var height = _a.height, _b = _a.width, width = _b === void 0 ? 1 : _b;
    return ({
        background: 'linear-gradient(-180deg, rgba(255,255,255,0), rgba(255,255,255,1))',
        position: 'absolute',
        width: width,
        zIndex: 9,
        bottom: 0,
        height: height,
    });
});
exports.default = FadeoutBottom;
