"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
exports.default = (0, material_1.styled)(('div'))(function (_a) {
    var theme = _a.theme;
    return ({
        border: '1px solid rgba(112, 119, 124, 0.35)',
        opacity: 0.3,
        margin: theme.spacing(1, 0),
    });
});
