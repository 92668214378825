"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useOwnersFilter = function (serviceStats) {
    var _a = __read((0, react_1.useState)([]), 2), ownersToDisplay = _a[0], setOwnersToFilterBy = _a[1];
    var serviceStatsToDisplay = ownersToDisplay.length === 0
        ? serviceStats
        : serviceStats
            && serviceStats.filter(function (serviceStat) { return !!serviceStat.ownerId && ownersToDisplay.includes(serviceStat.ownerId); });
    return [serviceStatsToDisplay, setOwnersToFilterBy];
};
exports.default = useOwnersFilter;
