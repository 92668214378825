"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var BackgroundText = (0, styles_1.styled)('span')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            position: 'absolute',
            zIndex: -1,
            overflow: 'hidden',
            fontSize: 400,
            color: theme.palette.text.secondary,
            opacity: 0.10,
            fontWeight: theme.typography.fontWeightMedium
        },
        _b[theme.breakpoints.down('lg')] = {
            display: 'none',
        },
        _b);
});
exports.default = BackgroundText;
