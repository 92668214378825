"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mimeToExtension = void 0;
var mimeToExtension = function (contentType) {
    var _a;
    var fullImageType = (_a = contentType === null || contentType === void 0 ? void 0 : contentType.match(/image\/(.+)/)) === null || _a === void 0 ? void 0 : _a[1];
    if (!fullImageType)
        return undefined;
    if (fullImageType.match(/^jpe?g\b/))
        return 'jpg';
    var extMatch = fullImageType.match(/\b(ico)n\b|^([a-z]{3}|webp|avif)\b/);
    if (extMatch === null || extMatch === void 0 ? void 0 : extMatch[1])
        return extMatch[1];
    return undefined;
};
exports.mimeToExtension = mimeToExtension;
