"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logoGroupSectionDefs = exports.logoSectionDefs = void 0;
var constants_1 = require("../constants");
exports.logoSectionDefs = [
    {
        heading: 'Logo brand',
        description: 'A logo is the image of a tool or a company. Use the brand prop to set the logo of the service.',
        variations: [
            {
                component: 'Logo',
                properties: [{ prop: 'brand', v: constants_1.sampleServices[2] }],
                noChildren: true,
            },
        ],
    },
    {
        heading: 'Logo sizes',
        description: 'The Logo comes with three sizes: sm, md and lg. It uses md as default',
        variations: [
            { component: 'Logo', properties: [{ prop: 'brand', v: constants_1.sampleServices[2] }, { prop: 'size', v: 'sm' }], noChildren: true },
            { component: 'Logo', properties: [{ prop: 'brand', v: constants_1.sampleServices[2] }, { prop: 'size', v: 'md' }], noChildren: true },
            { component: 'Logo', properties: [{ prop: 'brand', v: constants_1.sampleServices[2] }, { prop: 'size', v: 'lg' }], noChildren: true },
        ],
    },
];
var logoChildren = [
    { component: 'Logo', properties: [{ prop: 'brand', v: constants_1.sampleServices[0] }], noChildren: true },
    { component: 'Logo', properties: [{ prop: 'brand', v: constants_1.sampleServices[1] }], noChildren: true },
    { component: 'Logo', properties: [{ prop: 'brand', v: constants_1.sampleServices[3] }], noChildren: true },
    { component: 'Logo', properties: [{ prop: 'brand', v: constants_1.sampleServices[2] }], noChildren: true },
];
var logoChildrenGeneric = logoChildren;
exports.logoGroupSectionDefs = [
    {
        heading: 'AvatarGroup',
        description: 'An AvatarGroup can also be used to group logos. Simply place the logo components as children.',
        variations: [{ component: 'AvatarGroup', children: logoChildrenGeneric }],
    },
    {
        heading: 'max prop',
        description: "The AvatarGroup comes with a max prop to set the maximum number of logos to show. \n    If the group has more avatars than the max, it will show a +{remaining} label.",
        variations: [
            { component: 'AvatarGroup', properties: [{ prop: 'max', v: 3 }], children: logoChildrenGeneric },
        ],
    },
    {
        heading: 'AvatarGroup sizes',
        description: 'The AvatarGroup comes with three sizes: sm, md and lg. This resizes the logos inside the group.',
        variations: [
            { component: 'AvatarGroup', properties: [{ prop: 'size', v: 'sm' }], children: logoChildrenGeneric },
            { component: 'AvatarGroup', properties: [{ prop: 'size', v: 'md' }], children: logoChildrenGeneric },
            { component: 'AvatarGroup', properties: [{ prop: 'size', v: 'lg' }], children: logoChildrenGeneric },
        ],
    },
];
