"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var constants_1 = require("atomicComponents/atoms/Logo/constants");
var general_1 = require("utils/general");
var Container = (0, material_1.styled)(material_1.Box, { shouldForwardProp: (0, general_1.omitProps)('url', 'isLink', 'size', 'isClickable', 'noWrap', 'isLink', 'fontWeight') })(function (_a) {
    var theme = _a.theme, size = _a.size, isLink = _a.isLink, fontWeight = _a.fontWeight, _b = _a.noWrap, noWrap = _b === void 0 ? true : _b;
    return (__assign(__assign({ display: noWrap ? 'flex' : 'inline', alignItems: 'center', fontSize: constants_1.IMG_SIZES[size || 'md'].fontSize, fontWeight: isLink || fontWeight === 'medium' ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular, lineHeight: !isLink ? 'inherit' : 1.2, letterSpacing: '0.023em', borderRadius: 6, img: {
            marginRight: theme.spacing(constants_1.IMG_SIZES[size || 'md'].marginRight),
        } }, (isLink && {
        ':focus, :hover, :visited, :link, :active': {
            textDecoration: 'none',
        },
        ':hover': __assign({ letterSpacing: '0.023em', color: theme.palette.text.primary }, size === 'lg' && {
            textDecoration: 'underline',
        }),
        ':focus-visible': __assign({}, theme.focusVisibleStyles),
    })), (noWrap && {
        '& .LogoAndName_label': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    })));
});
exports.default = Container;
