"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTitleDescForSignUp = void 0;
var constants_1 = require("./constants");
var getTitleDescForSignUp = function () {
    if (!window.location.pathname.match(/(\/activate|\/signup(\/accounting-firm)?)/)) {
        return null;
    }
    if (window.location.pathname.match(/\/activate\/\w+/)) {
        return {
            headerMeta: {
                title: 'Hudled - Activate invitation',
                desc: 'Get Hudled today and help your customers never overpay on your SaaS again',
            },
            welcomeMsg: 'Get started with Hudled',
            descriptionPoints: [
                'Connect directly to your accounting system',
                'Tailored savings recommendations to reduce spend',
                'Access to exclusive discounts',
            ],
        };
    }
    if (window.location.pathname === '/signup/accounting-firm') {
        return {
            isAccountingSignUp: true,
            headerMeta: {
                title: 'Hudled - Signup Accounting Firm',
                desc: 'Get Hudled today and help your customers never overpay on your SaaS again',
            },
            welcomeMsg: 'Get started as an accounting firm',
            descriptionPoints: [
                'Connect your customers directly to their accounting system',
                'Tailored savings recommendations to reduce spend of your customers',
                'Give your customers access to exclusive discounts',
            ],
        };
    }
    var planParam = new URLSearchParams(window.location.search).get('plan');
    var plan = ['Start-Up', 'Growth'].find(function (p) { return (planParam === null || planParam === void 0 ? void 0 : planParam.toLowerCase()) === p.toLowerCase(); }) || 'Start-Up';
    var headerMeta = constants_1.TEXTS_MAPPER[plan];
    return {
        headerMeta: headerMeta,
        welcomeMsg: 'Get started with Hudled',
        descriptionPoints: [
            'Connect directly to your accounting system',
            'Tailored savings recommendations to reduce spend',
            'Access to exclusive discounts',
        ],
    };
};
exports.getTitleDescForSignUp = getTitleDescForSignUp;
