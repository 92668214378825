"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var MainWrapper = (0, styles_1.styled)('main')(function () { return ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: 1,
}); });
exports.default = MainWrapper;
