"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var ImportCodeWrapper = (0, styles_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        position: 'relative',
        'pre[class*="language-"]': {
            margin: theme.spacing(2, 0),
            padding: theme.spacing(2, 4),
        },
        '.toolbar': {
            position: 'absolute',
            right: theme.spacing(2),
            top: theme.spacing(1),
            color: theme.palette.common.white,
        },
    });
});
exports.default = ImportCodeWrapper;
