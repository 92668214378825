"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeVariationString = exports.makePropVariations = void 0;
var end2end_1 = require("@hudled/end2end");
var makePropVariations = function (component, prop, propVals, fixedProps) { return propVals.map(function (v) { return ({
    component: component,
    properties: __spreadArray(__spreadArray([], __read(fixedProps !== null && fixedProps !== void 0 ? fixedProps : []), false), [{ prop: prop, v: v }], false),
}); }); };
exports.makePropVariations = makePropVariations;
var getValueStr = function (value) {
    if (typeof value === 'string') {
        return "=\"".concat(value, "\"");
    }
    if (typeof value === 'object' && 'iconName' in value) {
        return "={".concat((0, end2end_1.kebabToCamel)("fa-".concat(value.iconName)), "}");
    }
    if (typeof value === 'number') {
        return "={".concat(value, "}");
    }
    if (typeof value === 'object' && 'logoUrl' in value) {
        return '={service}';
    }
    if (typeof value === 'object' && 'avatarUrl' in value) {
        return '={profile}';
    }
    if (typeof value === 'object') {
        var json = JSON.stringify(value);
        var unquoted = json.replace(/"([^"]+)":/g, function (match, p1) {
            // Check if it's a valid identifier (key without quotes)
            if (/^[a-zA-Z_$][0-9a-zA-Z_$]*$/.test(p1)) {
                return "".concat(p1, ": "); // If it's a valid identifier, add colon
            }
            return "'".concat(p1, "': "); // If not, stringify the key and add colon
        });
        return "={".concat(unquoted.replace(/,/g, ', ').replace(/"/g, "'").replace(/^\{/, '{ ').replace(/\}$/, ' }'), "}");
    }
    if (value === true) {
        return '';
    }
    if (value === false) {
        return '={false}';
    }
    return value;
};
var getPropertiesStr = function (properties) { return properties === null || properties === void 0 ? void 0 : properties.map(function (p) { return " ".concat(p.prop).concat(getValueStr(p.v)); }).join(''); };
var makeVariationString = function (v) {
    var _a;
    var propertiesStr = (_a = getPropertiesStr(v.properties)) !== null && _a !== void 0 ? _a : '';
    if (v.noChildren) {
        return "<".concat(v.component).concat(propertiesStr, " />");
    }
    if (v.children) {
        var childrenStr = v.children.map(function (child) { return makeVariationStringForChildren(child); }).join('\n');
        return "<".concat(v.component).concat(propertiesStr, ">\n").concat(childrenStr, "\n</").concat(v.component, ">");
    }
    return "<".concat(v.component).concat(propertiesStr, ">").concat(v.component, "</").concat(v.component, ">");
};
exports.makeVariationString = makeVariationString;
var makeVariationStringForChildren = function (v) { var _a; return "  <".concat(v.component).concat((_a = getPropertiesStr(v.properties)) !== null && _a !== void 0 ? _a : '').concat(v.noChildren ? ' />' : ">".concat(v.component, "</").concat(v.component, ">")); };
