"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var CustomersWrapper = (0, material_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        borderTop: "1px solid ".concat(theme.palette.grey[300]),
        borderBottom: "1px solid ".concat(theme.palette.grey[300]),
        padding: theme.spacing(2, 0),
        margin: theme.spacing(2, 0),
        maxHeight: 200,
        overflow: 'auto',
    });
});
exports.default = CustomersWrapper;
