import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ButtonsHolder = ({
  className, expand, mobileInline, children,
}) => (
  <div
    className={classNames('ds-button__holder', className, {
      '_flex-expand_': expand,
      '_inline-buttons-mobile_': mobileInline,
    })}
  >
    {children}
  </div>
);

ButtonsHolder.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  expand: PropTypes.bool,
  mobileInline: PropTypes.bool,
};

export default ButtonsHolder;
