"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var styles_1 = require("@mui/material/styles");
var PREFIX = 'RatingIcon';
var classes = {
    green: "".concat(PREFIX, "-green"),
    greenYellow: "".concat(PREFIX, "-greenYellow"),
    yellow: "".concat(PREFIX, "-yellow"),
    orange: "".concat(PREFIX, "-orange"),
    red: "".concat(PREFIX, "-red"),
    noRating: "".concat(PREFIX, "-noRating"),
};
var Root = (0, styles_1.styled)('span')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            height: 12,
            width: 12,
            borderRadius: 12,
            display: 'inline-block',
            marginRight: theme.spacing(1)
        },
        _b["&.".concat(classes.green)] = {
            backgroundColor: '#02E78E',
        },
        _b["&.".concat(classes.greenYellow)] = {
            backgroundColor: '#7FD861',
        },
        _b["&.".concat(classes.yellow)] = {
            backgroundColor: '#FCC934',
        },
        _b["&.".concat(classes.orange)] = {
            backgroundColor: '#FE935C',
        },
        _b["&.".concat(classes.red)] = {
            backgroundColor: theme.palette.secondary.dark,
        },
        _b["&.".concat(classes.noRating)] = {
            border: '#CCCCCC solid 1px',
        },
        _b);
});
var RatingIcon = function (_a) {
    var _b = _a.rating, rating = _b === void 0 ? 0 : _b;
    var colourClassName;
    if (rating >= 4.5) {
        colourClassName = classes.green;
    }
    else if (rating >= 4) {
        colourClassName = classes.greenYellow;
    }
    else if (rating >= 3.3) {
        colourClassName = classes.yellow;
    }
    else if (rating >= 3) {
        colourClassName = classes.orange;
    }
    else if (rating >= 1) {
        colourClassName = classes.red;
    }
    else {
        colourClassName = classes.noRating;
    }
    return (0, jsx_runtime_1.jsx)(Root, { className: colourClassName });
};
exports.default = RatingIcon;
