"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.polishLineItem = exports.polishRechargeOrgName = exports.polishServiceName = exports.polishReference = void 0;
var utils_1 = require("./utils");
var polishReference = function (bankRef) { return bankRef
    .replace(utils_1.MONTH_YEAR_RE, '_MONTH_')
    .replace(/\d{3,}/g, '_#_')
    .replace(/(USD|AUD|NZD|INR) [\d.]+/, 'CURR _#_')
    .toLowerCase()
    .trim(); };
exports.polishReference = polishReference;
var polishServiceName = function (serviceName, reReplacements) {
    var processedContactName = serviceName;
    if (reReplacements) {
        reReplacements.forEach(function (_a) {
            var re = _a.re, flags = _a.flags, replaceString = _a.replaceString;
            processedContactName = processedContactName.replace(new RegExp(re, flags), replaceString);
        });
    }
    return processedContactName.toLowerCase().trim()
        .replace(/ ?- \.$/, '')
        .replace(/(\b|_|,) ?(pt[ye] limited|(pt[ye] )?ltd|inc|llc)\.?$/, '')
        .trim();
};
exports.polishServiceName = polishServiceName;
var polishRechargeOrgName = function (orgName) { return orgName.toLowerCase().trim()
    .replace(/ ?- \.$/, '')
    .replace(/(\b|_|,) ?((pt[ye] )?limited|(pt[ye] )?ltd|inc|llc)\.?$/i, '')
    .replace(/\b(holdings?|holdco|opco|co|ip) ?$/i, '')
    .trim(); };
exports.polishRechargeOrgName = polishRechargeOrgName;
var polishLineItem = function (lI) { return (0, exports.polishReference)(lI
    .replace(/https:\/\/www.certify.com\/.+?( |$)/i, '').replace(/\//gi, '_-').replace(/\n/gi, ' ')); };
exports.polishLineItem = polishLineItem;
