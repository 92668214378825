"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCycleOption = exports.getAvailableAddOns = exports.getAvailableFamilies = exports.BILLING_FREQ_MAPPER = void 0;
var lodash_1 = require("lodash");
var constants_1 = require("../constants");
exports.BILLING_FREQ_MAPPER = { 1: 'monthly', 12: 'annually' };
var getAvailableFamilies = function (srvPricePlans) { return function (plans) { return (srvPricePlans.families.filter(function (f) { return !plans.find(function (r) { return r.family === f; }); })); }; };
exports.getAvailableFamilies = getAvailableFamilies;
var getAvailableAddOns = function (srvPricePlans) { return function (addOns) {
    var _a, _b;
    return ((_b = (_a = srvPricePlans.addOns) === null || _a === void 0 ? void 0 : _a.filter(function (f) { return !addOns.find(function (r) { return r.addOn === f; }); })) !== null && _b !== void 0 ? _b : []);
}; };
exports.getAvailableAddOns = getAvailableAddOns;
var getCycleOption = function (cycle) { return constants_1.FREQUENCY_OPTIONS.find(function (opt) { return (0, lodash_1.isEqual)(opt.value, cycle); }); };
exports.getCycleOption = getCycleOption;
