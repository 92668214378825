"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var faCheckCircle_1 = require("@fortawesome/pro-solid-svg-icons/faCheckCircle");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var styles_1 = require("@mui/material/styles");
var ListItem = (0, styles_1.styled)('li')(function (_a) {
    var theme = _a.theme;
    return ({
        '& svg': {
            color: theme.palette.secondary.main,
            marginRight: theme.spacing(2),
        },
        padding: theme.spacing(1, 0),
        display: 'flex',
        alignItems: 'center',
    });
});
var FeatureListItem = function (_a) {
    var title = _a.title;
    return ((0, jsx_runtime_1.jsxs)(ListItem, { children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: faCheckCircle_1.faCheckCircle }), title] }));
};
exports.default = FeatureListItem;
