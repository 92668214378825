import { Col } from 'reactstrap';

import ClassNameExtendableHoC from 'components/HoC/ClassNameExtendableHoC';

const HeaderOffset = ClassNameExtendableHoC(
  Col,
  'ds-col_offset',
  ({ isHalf, avatarSize }) => [
    avatarSize && `_avatar-${avatarSize}_`,
    { 'd-none': isHalf },
  ],
  ['avatarSize', 'isHalf'],
);

export default HeaderOffset;
