import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'reactstrap';

import HeaderOffset from './HeaderOffset';

const NonSortableHeader = ({
  width,
  isHalf,
  text,
  offsetSize,
  textCenter,
  isLastRow,
  className,
  noLeftPad,
}) => (
  <Col
    className={classNames(className, width && `ds-width__${width}`, {
      'text-center': textCenter,
      'd-none': isHalf,
      'pl-5 d-flex': offsetSize,
      'pr-6': isLastRow,
    })}
  >
    {!!offsetSize && <HeaderOffset avatarSize={offsetSize} />}
    <span className={classNames('ds-list__header-item', { 'pl-0': noLeftPad })}>{text}</span>
  </Col>
);

NonSortableHeader.propTypes = {
  isHalf: PropTypes.bool,
  textCenter: PropTypes.bool,
  isLastRow: PropTypes.bool,
  noLeftPad: PropTypes.bool,
  text: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  offsetSize: PropTypes.string,
};

export default NonSortableHeader;
