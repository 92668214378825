"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSingleInvitationsSubmitter = exports.useInvitationsSubmitter = void 0;
var end2end_1 = require("@hudled/end2end");
var react_redux_1 = require("react-redux");
var backend_1 = require("utils/backend");
var firestore_1 = require("utils/firebase/firestore");
var validators_1 = require("utils/form/validators");
var ordered_1 = require("utils/selectors/ordered");
var single_1 = require("utils/selectors/single");
var useInvitationsSubmitter = function () {
    var singleInvitationsSubmitter = (0, exports.useSingleInvitationsSubmitter)();
    return function (_a) {
        var emailList = _a.emailList, accessLevel = _a.accessLevel, companyTeamId = _a.companyTeamId, isAccountant = _a.isAccountant;
        return __awaiter(void 0, void 0, void 0, function () {
            var emails;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!emailList) {
                            return [2 /*return*/, null];
                        }
                        emails = emailList.split(',');
                        if (emails.every(validators_1.getEmailError)) {
                            return [2 /*return*/, { emailList: 'Please input valid emails' }];
                        }
                        return [4 /*yield*/, Promise.all(emails.map(function (email) { return singleInvitationsSubmitter({
                                type: end2end_1.IS_RECHARGLY ? 'userRechargly' : 'user',
                                email: email,
                                accessLevel: accessLevel,
                                companyTeamId: companyTeamId,
                                isAccountant: isAccountant,
                            }); }))];
                    case 1:
                        _b.sent();
                        return [2 /*return*/, null];
                }
            });
        });
    };
};
exports.useInvitationsSubmitter = useInvitationsSubmitter;
var useSingleInvitationsSubmitter = function () {
    var store = (0, react_redux_1.useStore)();
    return function (_a) {
        var type = _a.type, email = _a.email, accessLevel = _a.accessLevel, companyTeamId = _a.companyTeamId, targetField = _a.targetField, targetDocRef = _a.targetDocRef, isAccountant = _a.isAccountant;
        return __awaiter(void 0, void 0, void 0, function () {
            var state, invitations, users, profile, companyId, existingInvitations;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!email || (0, validators_1.getEmailError)(email)) {
                            return [2 /*return*/, null];
                        }
                        state = store.getState();
                        invitations = (0, ordered_1.selectInvitations)(state);
                        users = (0, ordered_1.selectAllUsers)(state);
                        profile = (0, single_1.selectProfileWithId)(state);
                        companyId = profile.companyId;
                        if (!companyId) {
                            throw new Error('User has no company');
                        }
                        existingInvitations = invitations === null || invitations === void 0 ? void 0 : invitations.filter(function (i) { return !i.usedBy && i.email === email; });
                        // ToDo: See if we need to handle unauth Users
                        if (users === null || users === void 0 ? void 0 : users.find(function (u) { return u.email === email; })) {
                            return [2 /*return*/, null];
                        }
                        if (!(existingInvitations === null || existingInvitations === void 0 ? void 0 : existingInvitations.length)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all(existingInvitations.map(function (eI) { return __awaiter(void 0, void 0, void 0, function () {
                                var updateData;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            updateData = {};
                                            // Increasing the accLvl if the accLvl in the existing invitation is lower
                                            if (accessLevel && !(0, end2end_1.accessLevelCan)(accessLevel)(eI.accessLevel))
                                                updateData.accessLevel = accessLevel;
                                            if (!eI.companyTeamId && companyTeamId)
                                                updateData.companyTeamId = companyTeamId;
                                            if (!eI.isAccountant && isAccountant)
                                                updateData.isAccountant = isAccountant;
                                            if (!Object.keys(updateData).length) return [3 /*break*/, 2];
                                            return [4 /*yield*/, (0, firestore_1.updateDoc)('invitations', eI.id, updateData)];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2: return [4 /*yield*/, (0, backend_1.callBackendFn)('invitations-resendInvitation', eI.id)];
                                        case 3:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }))];
                    case 1:
                        _b.sent();
                        return [2 /*return*/, null];
                    case 2: return [4 /*yield*/, (0, firestore_1.addDoc)('invitations', {
                            type: type,
                            email: email,
                            accessLevel: accessLevel,
                            companyTeamId: companyTeamId,
                            isAccountant: isAccountant,
                            companyId: companyId,
                            targetField: targetField,
                            targetDocRef: targetDocRef,
                            inviterId: profile.id,
                        })];
                    case 3:
                        _b.sent();
                        return [2 /*return*/, null];
                }
            });
        });
    };
};
exports.useSingleInvitationsSubmitter = useSingleInvitationsSubmitter;
