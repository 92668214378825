"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapBy = exports.formatPercentagePrecise = exports.formatPercentage = exports.toFixedDigits = exports.formatNumber = exports.getQParamAttachChar = exports.getCurrencySymbol = exports.MONTH_YEAR_RE = exports.getSmallServiceData = exports.convertTransactionAmount = exports.convert = exports.addRenewalDaysAway = exports.getAmountForExpenditure = exports.reduceName = exports.delay = exports.userCan = exports.userCanInCompany = exports.accessLevelCan = exports.notFalsy = exports.mergeFxAmount = void 0;
var date_fns_1 = require("date-fns");
var lodash_1 = require("lodash");
var mappers_1 = require("./mappers");
var constants_1 = require("./constants");
var mergeFxAmount = function (fx1, fx2) {
    return (__assign(__assign({}, fx2), (0, lodash_1.mapValues)(fx1, function (v, k) { return fx2[k] ? v + (fx2[k] || 0) : v; })));
};
exports.mergeFxAmount = mergeFxAmount;
var notFalsy = function (item) { return !!item; };
exports.notFalsy = notFalsy;
// Returns a function that checks if accLvl has permission to do what minAccLvl can
var accessLevelCan = function (minAccLvl) { return function (accLvl) { return !!accLvl
    && constants_1.ACCESS_LEVELS_HIERARCHY.indexOf(accLvl) >= constants_1.ACCESS_LEVELS_HIERARCHY.indexOf(minAccLvl); }; };
exports.accessLevelCan = accessLevelCan;
var userCanInCompany = function (accLvl, companyId) { return function (user) { var _a; return !(user === null || user === void 0 ? void 0 : user.isSuspended) && ((user === null || user === void 0 ? void 0 : user.isAdmin) || (0, exports.accessLevelCan)(accLvl)((_a = user === null || user === void 0 ? void 0 : user.companyAccessLevel) === null || _a === void 0 ? void 0 : _a[companyId])); }; };
exports.userCanInCompany = userCanInCompany;
var userCan = function (accLvl) { return function (user) { return (user === null || user === void 0 ? void 0 : user.isAdmin) || (0, exports.accessLevelCan)(accLvl)(user === null || user === void 0 ? void 0 : user.accessLevel); }; };
exports.userCan = userCan;
var delay = function (ms, resolveValue) { return new Promise(function (r) { return setTimeout(function () { return r(resolveValue); }, ms); }); };
exports.delay = delay;
var reduceName = function (name) { return name === null || name === void 0 ? void 0 : name.toLowerCase().replace(/ /g, ''); };
exports.reduceName = reduceName;
var getAmountForExpenditure = function (t, serviceIdOrAll) {
    var _a;
    if (t.serviceId === serviceIdOrAll || serviceIdOrAll === 'ALL') {
        return t.subTotal || t.total;
    }
    return (0, lodash_1.sumBy)((_a = t.lineItems) === null || _a === void 0 ? void 0 : _a.filter(function (lI) { return lI.serviceId === serviceIdOrAll; }), function (lI) { return lI.amount; });
};
exports.getAmountForExpenditure = getAmountForExpenditure;
var addRenewalDaysAway = function (sS) { return (__assign(__assign({}, sS), { renewalDaysAway: sS.renewalDateNext && (0, date_fns_1.differenceInDays)(sS.renewalDateNext.toDate(), new Date()) })); };
exports.addRenewalDaysAway = addRenewalDaysAway;
var convert = function (dayRates) { return function (sourceCurr, targetCurr) { return function (amount) { return amount && amount * dayRates.rates[targetCurr] / dayRates.rates[sourceCurr]; }; }; };
exports.convert = convert;
var convertTransactionAmount = function (dayRates, targetCurr) { return function (transaction, serviceId) { return (0, exports.convert)(dayRates)(transaction.currency, targetCurr)((0, exports.getAmountForExpenditure)(transaction, serviceId)); }; };
exports.convertTransactionAmount = convertTransactionAmount;
var getSmallServiceData = function (service) { return (__assign({ name: service.niceName || service.name }, (0, lodash_1.pick)(service, ['id', 'logoUrl', 'slug']))); };
exports.getSmallServiceData = getSmallServiceData;
// eslint-disable-next-line max-len
exports.MONTH_YEAR_RE = /\b(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?) ?((20|')?\d{2})(?=\D|$)/gi;
var getCurrencySymbol = function (currency) { return mappers_1.CURRENCY_SYMBOLS[currency !== null && currency !== void 0 ? currency : 'AUD']; };
exports.getCurrencySymbol = getCurrencySymbol;
var getQParamAttachChar = function (url) { return url.includes('?') ? '&' : '?'; };
exports.getQParamAttachChar = getQParamAttachChar;
function formatNumber(intOrIntStr, currency) {
    var prefix = (0, exports.getCurrencySymbol)(currency);
    var intStr = typeof intOrIntStr === 'number' ? intOrIntStr.toString() : intOrIntStr;
    if (!intStr)
        return intStr;
    if (typeof intOrIntStr === 'number')
        return "".concat(prefix).concat(intOrIntStr.toLocaleString());
    if (intStr.match(/\$/))
        return intOrIntStr;
    return "".concat(prefix).concat(intOrIntStr);
}
exports.formatNumber = formatNumber;
var toFixedDigits = function (maxD, minD) { return function (v) { return v.toLocaleString(undefined, { minimumFractionDigits: minD, maximumFractionDigits: maxD }); }; };
exports.toFixedDigits = toFixedDigits;
var formatPercentage = function (v) { return "".concat((0, exports.toFixedDigits)(1)(v * 100), "%"); };
exports.formatPercentage = formatPercentage;
var formatPercentagePrecise = function (v) { return "".concat((0, exports.toFixedDigits)(1, 1)(v * 100), "%"); };
exports.formatPercentagePrecise = formatPercentagePrecise;
var mapBy = function (arr, getKey) { return Object.fromEntries(arr.map(function (v) { return [getKey(v), v]; })); };
exports.mapBy = mapBy;
