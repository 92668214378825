"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var general_1 = require("utils/general");
var SkeletonGroup = (0, styles_1.styled)('div', { shouldForwardProp: (0, general_1.omitProps)('size') })(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'inline-flex',
        flexDirection: 'row-reverse',
        paddingLeft: theme.spacing(0.5),
        margin: theme.spacing(0.5, 0.25),
        verticalAlign: 'middle',
        direction: 'rtl',
        '.MuiSkeleton-root:not(:first-child)': {
            marginLeft: theme.spacing(-2),
        },
        '.MuiSkeleton-root:first-child': {
            marginLeft: 0,
        },
    });
});
exports.default = SkeletonGroup;
