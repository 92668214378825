"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTextFieldErrorToShow = exports.getErrorToShow = exports.getUrlError = exports.getEmailError = exports.validateNumber = exports.required = exports.requiredFields = void 0;
var requiredFields = function (fields) { return function (vals) {
    var err = {};
    fields.forEach(function (field) {
        if (!vals[field] || (Array.isArray(vals[field]) && !vals[field].length)) {
            err[field] = 'Missing Value';
        }
    });
    return err;
}; };
exports.requiredFields = requiredFields;
var required = function (err) {
    if (err === void 0) { err = 'Please fill'; }
    return function (name) { return !name && name !== 0 && err; };
};
exports.required = required;
// ToDo: rename to validatePositiveNumber or validatePosNumber
var validateNumber = function (err) {
    if (err === void 0) { err = 'Please enter a valid number!'; }
    return (function (text) { return (parseInt(text, 10) <= 0 || !Number(text)) && err; });
};
exports.validateNumber = validateNumber;
var emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
var getEmailError = function (email) { return !emailRe.test(email) && 'Please enter a valid email.'; };
exports.getEmailError = getEmailError;
var getUrlError = function (url) { return /^https?:\/\/\w+\.\w+(\/\w*)+$/i.test(url) && 'Please enter a valid URL.'; };
exports.getUrlError = getUrlError;
var getErrorToShow = function (meta) { return (((meta === null || meta === void 0 ? void 0 : meta.touched) || (meta === null || meta === void 0 ? void 0 : meta.dirty)) && meta.error) || (meta === null || meta === void 0 ? void 0 : meta.submitError); };
exports.getErrorToShow = getErrorToShow;
var getTextFieldErrorToShow = function (meta) { return ((meta === null || meta === void 0 ? void 0 : meta.touched) && meta.error) || (meta === null || meta === void 0 ? void 0 : meta.submitError); };
exports.getTextFieldErrorToShow = getTextFieldErrorToShow;
