"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flatFileButtonStyles = void 0;
var flatFileButtonStyles = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var theme = _a.theme, ownerState = _a.ownerState;
    return ({
        boxShadow: 'none',
        borderRadius: '5px',
        lineHeight: '24px',
        fontWeight: theme.typography.fontWeightRegular.toString(),
        backgroundColor: (_c = (_b = theme.palette[ownerState.color]) === null || _b === void 0 ? void 0 : _b[ownerState.variant]) === null || _c === void 0 ? void 0 : _c.backgroundColor,
        color: (_e = (_d = theme.palette[ownerState.color]) === null || _d === void 0 ? void 0 : _d[ownerState.variant]) === null || _e === void 0 ? void 0 : _e.color,
        border: ownerState.variant === 'outlined'
            ? "1px solid ".concat((_g = (_f = theme.palette[ownerState.color]) === null || _f === void 0 ? void 0 : _f[ownerState.variant]) === null || _g === void 0 ? void 0 : _g.color) : 'none',
        ':hover': {
            backgroundColor: (_j = (_h = theme.palette[ownerState.color]) === null || _h === void 0 ? void 0 : _h[ownerState.variant]) === null || _j === void 0 ? void 0 : _j.hover.backgroundColor,
            color: (_l = (_k = theme.palette[ownerState.color]) === null || _k === void 0 ? void 0 : _k[ownerState.variant]) === null || _l === void 0 ? void 0 : _l.hover.color,
        },
        ':active': {
            backgroundColor: (_o = (_m = theme.palette[ownerState.color]) === null || _m === void 0 ? void 0 : _m[ownerState.variant]) === null || _o === void 0 ? void 0 : _o.pressed.backgroundColor,
            color: (_q = (_p = theme.palette[ownerState.color]) === null || _p === void 0 ? void 0 : _p[ownerState.variant]) === null || _q === void 0 ? void 0 : _q.pressed.color,
        },
    });
};
exports.flatFileButtonStyles = flatFileButtonStyles;
