import DefaultPropsHoC from 'components/Generic/DefaultPropsHoC';

import ButtonBase from './ButtonBase';

const ButtonLink = DefaultPropsHoC(ButtonBase, { color: 'link' });
const ButtonLinkPink = DefaultPropsHoC(ButtonBase, { color: 'link', isPink: true });

export {
  ButtonLink,
  ButtonLinkPink,
};
