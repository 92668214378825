"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackingMap = void 0;
/*
  'Activation Step Completed': {
    stepName: 'Choose Accounting System' | 'Connect Accounting System';
    'Step Number': 1|2|3|4|5|6;
    resolution: 'Skipped' | 'Supported Accounting' | 'Non-supported Accounting' | 'CSV Upload';
    accountingSystem: 'Xero' | 'Quickbooks' | 'NetSuite';
  };
*/
exports.trackingMap = {
    USER_INTRO: { fromForm: { firstName: 'profile.', lastName: 'profile.' } },
    COMPANY_INTRO: {
        fromForm: {
            companyName: 'company.name',
            employeeCount: 'company.',
            isLogoUploaded: function (fV) { var _a; return !!((_a = fV.company) === null || _a === void 0 ? void 0 : _a.logoFileArr); },
        },
    },
    TRACK_SOFTWARE_TEAM: {
        fromForm: {
            previousSaasTracking: 'companyTeam.',
            previousSaasTrackingOther: 'companyTeam.',
        },
    },
    TRACK_SOFTWARE: {
        fromForm: {
            previousSaasTracking: 'company.',
            previousSaasTrackingOther: 'company.',
        },
    },
    SAAS_QUIZ: { fromForm: { estimationAnnualSpend: 'profile.', estimationNumTools: 'profile.' } },
};
