import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

import { RatingBadge } from 'components/Inputs/Checkboxs/CheckboxLabelSec';

const RatingBadgeWrapper = React.forwardRef(
  (props, ref) => (
    <div {...props} ref={ref}>
      <RatingBadge
        label={props.label}
        onClick={props.onClick}
        disabled={props.disabled}
        isChecked={props.isChecked}
        className={props.className}
      />
    </div>
  ),
);

RatingBadgeWrapper.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default function RatingBadgeTooltip({
  label, tooltipTitle, onClick, disabled, className, isChecked,
}) {
  return (
    <Tooltip title={tooltipTitle}>
      <RatingBadgeWrapper
        label={label}
        onClick={onClick}
        disabled={disabled}
        className={className}
        isChecked={isChecked}
      />
    </Tooltip>
  );
}

RatingBadgeTooltip.propTypes = {
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
};
