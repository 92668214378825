"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.countLicenses = void 0;
var interfaces_1 = require("@hudled/interfaces");
var countLicenses = function (reviews) {
    var licensesCount = {
        own: 0, withShared: 0, shared: 0, totalLicenses: 0, totalUsers: 0, other: 0,
    };
    reviews.forEach(function (r) {
        if (r.accessType === interfaces_1.ACCESS_TYPE.OWN)
            licensesCount.own++;
        else if (r.accessType === interfaces_1.ACCESS_TYPE.SHARED)
            licensesCount.withShared++;
        else
            licensesCount.other++;
    });
    if (licensesCount.withShared)
        licensesCount.shared = 1;
    licensesCount.totalLicenses = licensesCount.shared + licensesCount.own + licensesCount.other;
    licensesCount.totalUsers = licensesCount.withShared + licensesCount.own + licensesCount.other;
    return licensesCount;
};
exports.countLicenses = countLicenses;
