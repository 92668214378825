"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACCESS_LEVELS = exports.ACC_LVL_PROPS_MAPPER = void 0;
var interfaces_1 = require("@hudled/interfaces");
exports.ACC_LVL_PROPS_MAPPER = (_a = {},
    _a[interfaces_1.ACCESS_LEVEL.Owner] = {
        title: 'Owner',
        description: 'Primary account contact. Same permissions as admins.',
    },
    _a[interfaces_1.ACCESS_LEVEL.Admin] = {
        title: 'Admin',
        description: 'Full access. Admins can manage users, teams and billing.',
    },
    _a[interfaces_1.ACCESS_LEVEL.User] = {
        title: 'User',
        description: 'Can invite users. Cannot manage permissions nor billing.',
    },
    _a[interfaces_1.ACCESS_LEVEL.Accountant] = {
        title: 'Accountant',
        description: 'Can manage accounting integrations.',
    },
    _a[interfaces_1.ACCESS_LEVEL.UnauthUser] = {
        title: 'Survey User',
        description: 'Can answer the SaaS Survey.',
    },
    _a);
// export const ACCESS_LEVELS = [ACCESS_LEVEL.Owner, ACCESS_LEVEL.Admin, ACCESS_LEVEL.User, ACCESS_LEVEL.Accountant];
exports.ACCESS_LEVELS = [interfaces_1.ACCESS_LEVEL.Owner, interfaces_1.ACCESS_LEVEL.Admin, interfaces_1.ACCESS_LEVEL.User];
