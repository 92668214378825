"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c, _d, _e, _f;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACCESS_LEVELS_HIERARCHY = exports.privacyPolicyVersion = exports.termsAndConditionsVersion = exports.XERO_MAIN_RECHARGLY_CLIENT_ID = exports.XERO_MAIN_CLIENT_ID = exports.XERO_STAGE_MAIN_CLIENT_ID = exports.XERO_PROD_MAIN_CLIENT_ID = exports.XERO_PROD_MAIN_RECHARGLY_CLIENT_ID = exports.XERO_RECHARGLY_MAIN = exports.XERO_MAIN = exports.XERO_STAGE_MAIN = exports.XERO_PROD_MAIN = exports.FB_ENV = exports.IS_RECHARGLY = exports.FE_DOMAIN_MAPPER = exports.GCP_PROJECT = exports.HUDLED_EMAILS = exports.OAUTH_STATE_DIVIDER = exports.OAUTH_DOMAIN_CODE_PLACEHOLDER = exports.OAUTH_RETURN_URL_PLACEHOLDER = exports.OAUTH_REDIRECT_PATH_PLACEHOLDER = exports.xeroDefaultScope = exports.xeroRechargeScope = exports.xeroDefaultScopesList = exports.xeroSignInScope = exports.XERO_CONSENT_URL_BASE = exports.GROWTH_FREE_TRIAL_PERIOD = void 0;
var interfaces_1 = require("@hudled/interfaces");
var lodash_1 = require("lodash");
exports.GROWTH_FREE_TRIAL_PERIOD = 14;
exports.XERO_CONSENT_URL_BASE = "https://login.xero.com/identity/connect/authorize";
var openIdScopesList = ['openid', 'profile', 'email'];
exports.xeroSignInScope = openIdScopesList.join(' ');
exports.xeroDefaultScopesList = __spreadArray(__spreadArray([], __read(openIdScopesList), false), ['offline_access', 'accounting.transactions.read', 'accounting.settings.read',
    'accounting.attachments.read', 'accounting.contacts.read'], false);
var xeroRechargeScopesList = __spreadArray(__spreadArray([], __read(exports.xeroDefaultScopesList.map(function (scope) { return scope.replace(/\.read/, ''); })), false), ['accounting.reports.read'], false);
// 'accounting.reports.read', 'practicemanager.job', 'practicemanager.client', 'practicemanager.staff.read', 'practicemanager.time',
exports.xeroRechargeScope = xeroRechargeScopesList.join(' ');
exports.xeroDefaultScope = exports.xeroDefaultScopesList.join(' ');
exports.OAUTH_REDIRECT_PATH_PLACEHOLDER = 'REDIRECT_PATH';
exports.OAUTH_RETURN_URL_PLACEHOLDER = 'REDIRECT_URI';
exports.OAUTH_DOMAIN_CODE_PLACEHOLDER = 'DOMAIN_CODE';
exports.OAUTH_STATE_DIVIDER = '-_-';
exports.HUDLED_EMAILS = {
    product: 'product@hudled.com',
    support: 'support@hudled.com',
};
var getJSONKey = function (jsonStr, key) {
    var _a;
    if (!jsonStr) {
        return undefined;
    }
    try {
        return (_a = JSON.parse(jsonStr)) === null || _a === void 0 ? void 0 : _a[key];
    }
    catch (_b) {
        return undefined;
    }
};
exports.GCP_PROJECT = typeof process !== 'undefined'
    ? ((_a = process === null || process === void 0 ? void 0 : process.env) === null || _a === void 0 ? void 0 : _a.GCLOUD_PROJECT) || ((_b = process === null || process === void 0 ? void 0 : process.env) === null || _b === void 0 ? void 0 : _b.GCP_PROJECT) || getJSONKey((_c = process === null || process === void 0 ? void 0 : process.env) === null || _c === void 0 ? void 0 : _c.FIREBASE_CONFIG, 'projectId') || ''
    : '';
exports.FE_DOMAIN_MAPPER = {
    'hudled-5cfe5': 'app.hudled.com',
    'staging-hudled': 'staging.hudled.com',
    'hudled-dev': 'dev.hudled.com',
};
var HOST;
try {
    // @ts-ignore
    HOST = (_d = window.location.host) !== null && _d !== void 0 ? _d : '';
}
catch (_g) {
    HOST = (_e = exports.FE_DOMAIN_MAPPER[exports.GCP_PROJECT]) !== null && _e !== void 0 ? _e : '';
}
var loclStr = {};
try {
    // @ts-ignore
    loclStr = (_f = window.localStorage) !== null && _f !== void 0 ? _f : {};
}
catch (_h) {
}
exports.IS_RECHARGLY = loclStr.APP_OVERRIDE
    ? loclStr.APP_OVERRIDE === 'rechargly'
    : !!process.env.REACT_APP_IS_RECHARGLY || !!process.env.IS_RECHARGLY
        || !!HOST.match(/rechargly-1[^.]*\.web\.app/) || !!HOST.match(/(app|staging)\.rechargly\.com/) || !!HOST.match(/pr\d+-(hdld-\d+-)?rech(argly)?\b/);
var isStaging = HOST.match(/staging[.-](hudled|rechargly)/) || HOST.match(/hudled-5cfe5--pr.+\.web\.app/) && !exports.IS_RECHARGLY;
var isLocalhost = HOST.match(/localhost/);
var fallbackFbEnv = isLocalhost && 'dev' || isStaging && 'staging' || 'prod';
exports.FB_ENV = process.env.REACT_APP_FB_ENV || fallbackFbEnv;
exports.XERO_PROD_MAIN = 'HUDLED2';
exports.XERO_STAGE_MAIN = 'STAGING_HUDLED_2';
exports.XERO_MAIN = exports.FB_ENV === 'prod' ? exports.XERO_PROD_MAIN : exports.XERO_STAGE_MAIN;
exports.XERO_RECHARGLY_MAIN = 'RECHARGLY';
exports.XERO_PROD_MAIN_RECHARGLY_CLIENT_ID = 'E440F0E3849946868B66ACA02C10D234';
exports.XERO_PROD_MAIN_CLIENT_ID = '7E4C660B5A254349AD17916E2D5C40B0';
exports.XERO_STAGE_MAIN_CLIENT_ID = 'F080C08BAE5F475AB332FB9D5D02D34A';
exports.XERO_MAIN_CLIENT_ID = exports.FB_ENV === 'prod' ? exports.XERO_PROD_MAIN_CLIENT_ID : exports.XERO_STAGE_MAIN_CLIENT_ID;
exports.XERO_MAIN_RECHARGLY_CLIENT_ID = exports.FB_ENV === 'prod' ? exports.XERO_PROD_MAIN_RECHARGLY_CLIENT_ID : exports.XERO_PROD_MAIN_RECHARGLY_CLIENT_ID;
exports.termsAndConditionsVersion = 2;
exports.privacyPolicyVersion = 1;
exports.ACCESS_LEVELS_HIERARCHY = [
    interfaces_1.ACCESS_LEVEL.UnauthUser, interfaces_1.ACCESS_LEVEL.User, interfaces_1.ACCESS_LEVEL.Admin, interfaces_1.ACCESS_LEVEL.Accountant, interfaces_1.ACCESS_LEVEL.Owner,
];
if (!exports.GCP_PROJECT && HOST) {
    exports.GCP_PROJECT = (0, lodash_1.invert)(exports.FE_DOMAIN_MAPPER)[HOST];
}
if (!exports.GCP_PROJECT && exports.FB_ENV) {
    exports.GCP_PROJECT = { prod: 'hudled-5cfe5', staging: 'staging-hudled', dev: 'hudled-dev' }[exports.FB_ENV];
}
