"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSignUpAndActivate = exports.validateSignUp = void 0;
var end2end_1 = require("@hudled/end2end");
var interfaces_1 = require("@hudled/interfaces");
var final_form_1 = require("final-form");
var firestore_1 = require("firebase/firestore");
var jDA = __importStar(require("just-detect-adblock"));
var react_1 = require("react");
var react_redux_firebase_1 = require("react-redux-firebase");
var react_router_dom_1 = require("react-router-dom");
var PrivacyPolicy_1 = require("containers/App/Policies/PrivacyPolicy");
var TermsAndConditions_1 = require("containers/App/Policies/TermsAndConditions");
var analytics_1 = require("utils/analytics");
var backend_1 = require("utils/backend");
var companies_1 = require("utils/companies");
var customErrors_1 = require("utils/customErrors");
var firestore_2 = require("utils/firebase/firestore");
var firebaseConf_1 = require("utils/firebaseConf");
var validators_1 = require("utils/form/validators");
var integrationsConnections_1 = require("utils/integrationsConnections");
var logging_1 = require("utils/logging");
var detectDomAdblocker = jDA.detectDomAdblocker, detectBraveShields = jDA.detectBraveShields, detectOperaAdblocker = jDA.detectOperaAdblocker;
var validateSignUp = function (credentials) {
    var errors = {};
    if (!credentials.acceptedTerms)
        errors.acceptedTerms = 'Please agree to continue';
    if (!credentials.isGoogleSignUp && !credentials.isXeroSignUp) {
        if (!credentials.password)
            errors.password = 'Missing';
        if ((0, validators_1.getEmailError)(credentials.email))
            errors.email = 'Please input a valid email';
    }
    return Object.keys(errors).length > 0 ? errors : undefined;
};
exports.validateSignUp = validateSignUp;
var CREATION_FLOW = 'STANDARD';
var useSignUpAndActivate = function (isAccountingSignUp) {
    var _a = __read((0, react_1.useState)(false), 2), loading = _a[0], setLoading = _a[1];
    var invId = (0, react_router_dom_1.useParams)().invId;
    var location = (0, react_router_dom_1.useLocation)();
    var history = (0, react_router_dom_1.useHistory)();
    var firebase = (0, react_redux_firebase_1.useFirebase)();
    var urlSearchParams = new URLSearchParams(location.search);
    var planData = (0, companies_1.getPlanData)(new URLSearchParams(location.search).get('plan'));
    var trackingProps = {
        realsource: window.location.search || 'N/A',
        searchParams: Object.fromEntries(urlSearchParams.entries()),
        source: location.pathname.split('/')[1],
        pricingPlanSelected: planData.plan,
        product: 'HUDLED',
        creationFlow: CREATION_FLOW,
    };
    var trackCreationInitiated = (0, analytics_1.usePreparedTracker)('User Account Creation Initiated', trackingProps);
    var trackCreated = (0, analytics_1.usePreparedTracker)('User Account Created', trackingProps);
    var googleSignUp = function () { return __awaiter(void 0, void 0, void 0, function () {
        var user, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, firebaseConf_1.auth.signInWithPopup(firebaseConf_1.googleAuthProvider)];
                case 1:
                    user = _a.sent();
                    if (!(user === null || user === void 0 ? void 0 : user.credential)) {
                        return [2 /*return*/, null];
                    }
                    return [2 /*return*/, firebase.login({ credential: user.credential })];
                case 2:
                    e_1 = _a.sent();
                    (0, logging_1.logError)(e_1);
                    throw e_1;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var emailAndPasswordSignUp = function (_a) {
        var email = _a.email, password = _a.password;
        return __awaiter(void 0, void 0, void 0, function () {
            var e_2, e2_1, failedRequirements;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 9]);
                        return [4 /*yield*/, firebase.createUser({ email: email, password: password }, { username: email, email: email })];
                    case 1:
                        _c.sent();
                        return [3 /*break*/, 9];
                    case 2:
                        e_2 = _c.sent();
                        if (!(e_2.code === 'auth/email-already-in-use')) return [3 /*break*/, 7];
                        _c.label = 3;
                    case 3:
                        _c.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, firebase.login({ email: email, password: password })];
                    case 4:
                        _c.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        e2_1 = _c.sent();
                        throw new customErrors_1.ValidationError({ password: 'Wrong password', email: 'Email exists' });
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        if (e_2.code === 'auth/password-does-not-meet-requirements') {
                            failedRequirements = (_b = e_2.message.match(/\[([^\]]+)]/)) === null || _b === void 0 ? void 0 : _b[1].split(',');
                            throw new customErrors_1.ValidationError({ password: "MD:".concat(failedRequirements.map(function (fReq) { return "* ".concat(fReq); }).join('\n')) });
                        }
                        else {
                            throw e_2;
                        }
                        _c.label = 8;
                    case 8: return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    return [
        loading,
        function (credentials) { return __awaiter(void 0, void 0, void 0, function () {
            var errors, signUpCb, e_3, user, profile, e_4, detectedAdBlockers, _a, domAdBlocker, braveShields, operaAdBlocker, e_5, newUserData, trackData, trackingData, companyRef, e_6, companyId, accessLevel, e_7;
            var _b, _c;
            var _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        setLoading(true);
                        errors = (0, exports.validateSignUp)(credentials);
                        if (errors)
                            return [2 /*return*/, errors];
                        if (credentials.isXeroSignUp) {
                            window.location.href = (0, integrationsConnections_1.makeAuthorizeUri)((0, end2end_1.getXeroAuthorizeUriTemplate)('NONE', end2end_1.XERO_MAIN_CLIENT_ID, 'SIGN-UP'), location.pathname, 'xero');
                            return [2 /*return*/, {}];
                        }
                        signUpCb = credentials.isGoogleSignUp ? googleSignUp : emailAndPasswordSignUp;
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, signUpCb(credentials)];
                    case 2:
                        _f.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _f.sent();
                        setLoading(false);
                        if (e_3.validationErrors) {
                            return [2 /*return*/, e_3.validationErrors];
                        }
                        return [2 /*return*/, (_b = {}, _b[final_form_1.FORM_ERROR] = e_3.message || ((_d = e_3.toString) === null || _d === void 0 ? void 0 : _d.call(e_3)) || 'Unknown error', _b)];
                    case 4:
                        user = firebase.auth().currentUser;
                        if (!user)
                            throw new Error('No auth user found');
                        if (!!user.emailVerified) return [3 /*break*/, 6];
                        return [4 /*yield*/, user.sendEmailVerification()];
                    case 5:
                        _f.sent();
                        _f.label = 6;
                    case 6:
                        _f.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, (0, firestore_2.getDocData)('users', user.uid)];
                    case 7:
                        profile = _f.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        e_4 = _f.sent();
                        (0, logging_1.logError)('Error fetching user');
                        throw e_4;
                    case 9:
                        if (!profile)
                            throw new Error('User Doc not found');
                        detectedAdBlockers = jDA;
                        _f.label = 10;
                    case 10:
                        _f.trys.push([10, 12, , 13]);
                        return [4 /*yield*/, Promise.all([detectDomAdblocker(), detectBraveShields(), detectOperaAdblocker()])];
                    case 11:
                        _a = __read.apply(void 0, [_f.sent(), 3]), domAdBlocker = _a[0], braveShields = _a[1], operaAdBlocker = _a[2];
                        detectedAdBlockers = { domAdBlocker: domAdBlocker, braveShields: braveShields, operaAdBlocker: operaAdBlocker };
                        return [3 /*break*/, 13];
                    case 12:
                        e_5 = _f.sent();
                        (0, logging_1.logError)('detectingAdBlockersFailed', e_5);
                        return [3 /*break*/, 13];
                    case 13:
                        newUserData = {
                            privacyPolicyVersion: PrivacyPolicy_1.privacyPolicyVersion,
                            termsAndConditionsVersion: TermsAndConditions_1.termsAndConditionsVersion,
                            isAccountant: isAccountingSignUp,
                            userAgentOnSignUp: window.navigator.userAgent,
                            segmentInitializedOnSignUp: !!window.analytics && !!window.analytics.init,
                            detectedAdBlockers: detectedAdBlockers,
                        };
                        if (!!profile.companyId) return [3 /*break*/, 23];
                        trackData = { authType: (_e = user === null || user === void 0 ? void 0 : user.providerData[0]) === null || _e === void 0 ? void 0 : _e.providerId };
                        trackCreationInitiated(trackData);
                        if (!invId) return [3 /*break*/, 15];
                        return [4 /*yield*/, (0, backend_1.callBackendFn)('invitations-activate')({ invId: invId })];
                    case 14:
                        trackingData = (_f.sent()).data.trackingData;
                        Object.assign(trackData, __assign(__assign({}, trackingData), { invitePurpose: 'Account Creation', inviteType: 'Email Activation Link' }));
                        return [3 /*break*/, 19];
                    case 15:
                        companyRef = (0, firestore_2.makeDocRef)('companies');
                        newUserData.companyId = companyRef.id;
                        newUserData.mainCompanyId = companyRef.id;
                        newUserData.accessLevel = interfaces_1.ACCESS_LEVEL.Owner;
                        _f.label = 16;
                    case 16:
                        _f.trys.push([16, 18, , 19]);
                        return [4 /*yield*/, (0, firestore_1.setDoc)(companyRef, (0, companies_1.makeNewCompany)(CREATION_FLOW, user.uid, planData.plan, isAccountingSignUp)())];
                    case 17:
                        _f.sent();
                        return [3 /*break*/, 19];
                    case 18:
                        e_6 = _f.sent();
                        (0, logging_1.logError)('Error to create company');
                        throw e_6;
                    case 19:
                        companyId = newUserData.companyId, accessLevel = newUserData.accessLevel;
                        if (companyId && accessLevel) {
                            Object.assign(newUserData, (_c = {
                                    companyIds: (0, firestore_1.arrayUnion)(companyId)
                                },
                                _c["companyAccessLevel.".concat(companyId)] = accessLevel,
                                _c));
                        }
                        // isCompanyCreator: !invId works only for now;
                        trackCreated(__assign(__assign({}, trackData), { isCompanyCreator: !invId }));
                        _f.label = 20;
                    case 20:
                        _f.trys.push([20, 22, , 23]);
                        return [4 /*yield*/, (0, firestore_1.updateDoc)(profile.ref, newUserData)];
                    case 21:
                        _f.sent();
                        return [3 /*break*/, 23];
                    case 22:
                        e_7 = _f.sent();
                        (0, logging_1.logError)('Error trying to set user accessLevel and company');
                        (0, logging_1.logError)(newUserData);
                        throw e_7;
                    case 23: return [4 /*yield*/, Promise.race([
                            (0, end2end_1.delay)(10000),
                            (function () { return __awaiter(void 0, void 0, void 0, function () {
                                var i, currentProfileData;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!profile) {
                                                return [2 /*return*/];
                                            }
                                            i = 0;
                                            _a.label = 1;
                                        case 1:
                                            if (!(i < 30)) return [3 /*break*/, 4];
                                            return [4 /*yield*/, (0, firestore_2.getDocData)('users', user.uid)];
                                        case 2:
                                            currentProfileData = _a.sent();
                                            (0, logging_1.logInfo)('i', i);
                                            if (currentProfileData === null || currentProfileData === void 0 ? void 0 : currentProfileData.companyId) {
                                                (0, logging_1.logInfo)('currentProfileData', currentProfileData);
                                                return [2 /*return*/];
                                            }
                                            _a.label = 3;
                                        case 3:
                                            i++;
                                            return [3 /*break*/, 1];
                                        case 4: return [2 /*return*/];
                                    }
                                });
                            }); })(),
                        ])];
                    case 24:
                        _f.sent();
                        history.push('/');
                        return [2 /*return*/, {}];
                }
            });
        }); },
    ];
};
exports.useSignUpAndActivate = useSignUpAndActivate;
