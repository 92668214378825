"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useInfiniteScroll(items, itemsPerPage) {
    var _a = __read((0, react_1.useState)(1), 2), page = _a[0], setPage = _a[1];
    var _b = __read((0, react_1.useState)(false), 2), isMounted = _b[0], setIsMounted = _b[1];
    // add loader reference
    var loaderRef = (0, react_1.useRef)(null);
    var loaderRef2 = (0, react_1.useRef)(null);
    var increasePage = function () {
        if (items && page * itemsPerPage < items.length) {
            setPage(function (prevPage) { return prevPage + 1; });
        }
    };
    // here we handle what happens when user scrolls to Load More div  in this case we just update page variable
    var handleObserver = function (entities) {
        var target = entities[0];
        if (target.isIntersecting) {
            increasePage();
        }
    };
    (0, react_1.useEffect)(function () {
        if (items && items.length !== 0)
            setIsMounted(true);
    }, [items]);
    (0, react_1.useEffect)(function () {
        if ((loaderRef.current || loaderRef2.current) && isMounted) {
            var options = { root: null, rootMargin: '400px', threshold: 1.0 };
            // initialize IntersectionObserver and attaching to Load More div
            if (loaderRef.current) {
                var observer = new IntersectionObserver(handleObserver, options);
                loaderRef.current.onclick = increasePage;
                observer.observe(loaderRef.current);
            }
            if (loaderRef2.current) {
                var observer2 = new IntersectionObserver(handleObserver, options);
                observer2.observe(loaderRef2.current);
            }
        }
    }, [loaderRef.current, loaderRef2.current, isMounted]);
    return [items && items.slice(0, page * itemsPerPage), loaderRef, loaderRef2];
}
exports.default = useInfiniteScroll;
