"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var system_1 = require("@mui/system");
var TableRow = (0, system_1.styled)('div', {
    shouldForwardProp: function (prop) { return prop !== 'isClickable'; },
})(function (_a) {
    var theme = _a.theme, isClickable = _a.isClickable, onClick = _a.onClick;
    return ({
        display: 'flex',
        padding: theme.spacing(0, 2),
        cursor: isClickable || onClick ? 'pointer' : 'default',
        '> div:first-child': {
            color: theme.palette.text.secondary,
            '& .MuiTypography-root.MuiTypography-body1': {
                color: theme.palette.text.secondary,
            },
        },
    });
});
exports.default = TableRow;
