"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withSelectedIdsProvider = exports.makeUseSelectedIdsContext = exports.makeSelectedIdsContext = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var end2end_1 = require("@hudled/end2end");
var react_1 = __importStar(require("react"));
var utils_1 = require("utils/selectors/utils");
var makeSelectedIdsContext = function () { return react_1.default.createContext(null); };
exports.makeSelectedIdsContext = makeSelectedIdsContext;
var makeUseSelectedIdsContext = function (Context) { return function () {
    var contextVals = (0, react_1.useContext)(Context);
    if (!contextVals) {
        throw new Error('Unitialized context');
    }
    return contextVals;
}; };
exports.makeUseSelectedIdsContext = makeUseSelectedIdsContext;
var withSelectedIdsProvider = function (Context, dataSelector) { return function (WrappedComponent) { return function (props) {
    var dataMap = (0, utils_1.useDebouncedSelector)(dataSelector);
    var _a = __read(react_1.default.useState([]), 2), selectedIds = _a[0], setSelectedIds = _a[1];
    var contextValue = react_1.default.useMemo(function () { return ({
        selectedIds: selectedIds,
        selectedItems: selectedIds.map(function (id) { return dataMap === null || dataMap === void 0 ? void 0 : dataMap[id]; }).filter(end2end_1.notFalsy),
        setSelectedIds: setSelectedIds,
        toggleId: function (id) { return function () { return setSelectedIds(selectedIds.includes(id)
            ? selectedIds.filter(function (selId) { return selId !== id; })
            : __spreadArray(__spreadArray([], __read(selectedIds), false), [id], false)); }; },
    }); }, [selectedIds, !!dataMap]);
    return ((0, jsx_runtime_1.jsx)(Context.Provider, __assign({ value: contextValue }, { children: (0, jsx_runtime_1.jsx)(WrappedComponent, __assign({}, props)) })));
}; }; };
exports.withSelectedIdsProvider = withSelectedIdsProvider;
