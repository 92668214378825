import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

class ScrollIntoViewRow extends React.Component {
  constructor(props) {
    super(props);
    this.rowRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.rowRef.current && this.props.active && !prevProps.active) {
      scrollIntoView(this.rowRef.current, {
        scrollMode: 'if-needed',
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      active, className, children, isRow,
    } = this.props;

    return (
      <div
        className={classNames('row', { active }, `ds-list__${isRow ? 'row' : 'item'}`, className)}
        ref={this.rowRef}
      >
        {children}
      </div>
    );
  }
}

ScrollIntoViewRow.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  children: PropTypes.any,
  isRow: PropTypes.bool,
};

export default ScrollIntoViewRow;
