"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeMakeNewCompany = exports.makeGetPlanData = exports.calculateStartedRecently = void 0;
var date_fns_1 = require("date-fns");
var constants_1 = require("./constants");
var calculateStartedRecently = function (company) {
    var _a, _b;
    return !!((_a = company === null || company === void 0 ? void 0 : company.isMappingTransactionsStartedAt) === null || _a === void 0 ? void 0 : _a.toDate())
        && (0, date_fns_1.differenceInMinutes)(new Date(), (_b = company.isMappingTransactionsStartedAt) === null || _b === void 0 ? void 0 : _b.toDate()) < 10;
};
exports.calculateStartedRecently = calculateStartedRecently;
var makeGetPlanData = function (TimestampCls) { return function (plan) {
    var planData = { planFrequency: 'Monthly', planStatus: 'active', plan: plan || 'Start-Up' };
    if (plan === 'Growth') {
        planData.freeTrialStartDate = TimestampCls.now();
        planData.freeTrialFinishDate = TimestampCls.fromDate((0, date_fns_1.addDays)(new Date(), constants_1.GROWTH_FREE_TRIAL_PERIOD));
        planData.planStatus = 'in_trial';
    }
    return planData;
}; };
exports.makeGetPlanData = makeGetPlanData;
var makeMakeNewCompany = function (TimestampCls, localStorage) { return function (creationFlow, ownerId, plan, isAccountingFirm) { return function (extras) { return (__assign(__assign({ createdAt: TimestampCls.now(), ownerId: ownerId, isAccountingFirm: isAccountingFirm || undefined, creationFlow: creationFlow, referredBy: localStorage === null || localStorage === void 0 ? void 0 : localStorage.referredBy, 
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    referredAt: (localStorage === null || localStorage === void 0 ? void 0 : localStorage.lastReferredAt)
        ? TimestampCls.fromMillis(+localStorage.lastReferredAt)
        : undefined }, (0, exports.makeGetPlanData)(TimestampCls)(plan)), extras)); }; }; };
exports.makeMakeNewCompany = makeMakeNewCompany;
