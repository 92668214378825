"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLOR_RATING = exports.RATING_OPTIONS = exports.RATING = exports.DK = exports.COLOR_RATE = exports.RATE = void 0;
exports.RATE = { hate: 1, ok: 3, love: 5 };
exports.COLOR_RATE = (_a = {},
    _a[exports.RATE.hate] = 'red',
    _a[exports.RATE.ok] = 'yellow',
    _a[exports.RATE.love] = 'blue',
    _a);
exports.DK = 'I don’t know';
exports.RATING = {
    1: 1, 2: 2, 3: 3, 4: 4, 5: 5,
};
exports.RATING_OPTIONS = [
    { label: '1', value: exports.RATING[1] },
    { label: '2', value: exports.RATING[2] },
    { label: '3', value: exports.RATING[3] },
    { label: '4', value: exports.RATING[4] },
    { label: '5', value: exports.RATING[5] },
];
exports.COLOR_RATING = (_b = {},
    _b[exports.RATING[1]] = 'red',
    _b[exports.RATING[2]] = 'orange',
    _b[exports.RATING[3]] = 'yellow',
    _b[exports.RATING[4]] = 'yellow-green',
    _b[exports.RATING[5]] = 'green',
    _b);
// export const RATING_TOOLTIP_LABELS = {
//   [RATING[1]]: 'I hate it',
//   [RATING[2]]: 'I dislike it',
//   [RATING[3]]: 'It\'s Ok',
//   [RATING[4]]: 'I like it',
//   [RATING[5]]: 'I love it',
// };
