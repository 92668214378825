"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var system_1 = require("@mui/system");
var SubtleTextField = (0, system_1.styled)(material_1.TextField)(function (_a) {
    var theme = _a.theme;
    return ({
        '.MuiInput-root': {
            cursor: 'default',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.common.white,
            border: '1px solid transparent',
            minWidth: 30,
            height: 32,
            alignItems: 'center',
            borderColor: theme.palette.primary.light,
            ':hover': {
                borderColor: theme.palette.primary.light,
            },
            '&.Mui-disabled': {
                opacity: 0.5,
            },
        },
        '& input': {
            color: theme.palette.riverstone,
            fontWeight: 'inherit',
            fontSize: 'inherit',
            letterSpacing: 'normal',
            alignItems: 'center',
            padding: theme.spacing(0.5, 1),
        },
    });
});
exports.default = SubtleTextField;
