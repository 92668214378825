"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var material_1 = require("@mui/material");
var lodash_1 = require("lodash");
// TODO implement this on components with iconSize, iconColor, etc props
var Icon = function (_a) {
    var icon = _a.icon, _b = _a.size, size = _b === void 0 ? 'sm' : _b, _c = _a.color, color = _c === void 0 ? 'riverstone' : _c, props = __rest(_a, ["icon", "size", "color"]);
    var theme = (0, material_1.useTheme)();
    return ((0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, __assign({ icon: icon, size: size, color: (0, lodash_1.get)(theme.palette, color || 'neutral.contrastText') }, props)));
};
exports.default = Icon;
