"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var styles_1 = require("@mui/material/styles");
var general_1 = require("utils/general");
var StyledButton = (0, styles_1.styled)('button', { shouldForwardProp: (0, general_1.omitProps)('isSelected', 'sortKey', 'hasSortKey') })(function (_a) {
    var theme = _a.theme, isSelected = _a.isSelected, _b = _a.hasSortKey, hasSortKey = _b === void 0 ? false : _b;
    return (__assign(__assign(__assign(__assign({ color: isSelected ? theme.palette.text.secondary : theme.palette.riverstone, borderRadius: 6, lineHeight: '40px' }, (isSelected && __assign({}, theme.primaryUnderline))), { ':hover': hasSortKey && __assign(__assign({}, theme.primaryUnderline), { color: theme.palette.text.secondary }) }), (!hasSortKey && {
        cursor: 'default',
        pointerEvents: 'none',
    })), { ':focus-visible': __assign(__assign({}, theme.focusVisibleStyles), { lineHeight: '32px' }) }));
});
var SortButton = function (_a) {
    var isSelected = _a.isSelected, children = _a.children, rest = __rest(_a, ["isSelected", "children"]);
    return ((0, jsx_runtime_1.jsx)(StyledButton, __assign({}, rest, { type: "button", isSelected: isSelected }, { children: children })));
};
exports.default = SortButton;
