"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var IconButtons_1 = require("atomicComponents/molecules/IconButtons/IconButtons");
var hooks_1 = require("utils/hooks");
var DisableVendor = function (_a) {
    var item = _a.item;
    var _b = __read((0, hooks_1.useSubmitter)('recharged_vendors', item.id, { enabled: !item.enabled }), 2), vendorToggler = _b[0], isSending = _b[1];
    var toggle = function () { return (!item.enabled || window.confirm('You sure?')) && vendorToggler(); };
    return !item.enabled
        ? ((0, jsx_runtime_1.jsx)(IconButtons_1.UndoButton, { outlined: true, iconSize: 16, tooltipTitle: "Enable vendor", onClick: toggle, isSending: isSending, className: "highlightInput" }))
        : ((0, jsx_runtime_1.jsx)(IconButtons_1.RemoveButton, { outlined: true, iconSize: 16, tooltipTitle: "Disable vendor", onClick: toggle, isSending: isSending, className: "highlightInput", neutralByDefault: false }));
};
exports.default = DisableVendor;
