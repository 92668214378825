"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.logoAndNameSectionDefs = void 0;
var constants_1 = require("../constants");
exports.logoAndNameSectionDefs = [
    {
        heading: 'LogoAndName brand prop',
        description: 'Use the brand prop to set the logo and name of the service',
        variations: [
            {
                component: 'LogoAndName',
                properties: [{ prop: 'brand', v: constants_1.sampleServices[0] }],
                noChildren: true,
            },
        ],
    },
    {
        heading: 'LogoAndName sizes',
        description: 'The LogoAndName comes with three sizes: sm, md and lg. It uses md as default',
        variations: [
            { component: 'LogoAndName', properties: [{ prop: 'brand', v: constants_1.sampleServices[2] }, { prop: 'size', v: 'sm' }], noChildren: true },
            { component: 'LogoAndName', properties: [{ prop: 'brand', v: constants_1.sampleServices[2] }, { prop: 'size', v: 'md' }], noChildren: true },
            { component: 'LogoAndName', properties: [{ prop: 'brand', v: constants_1.sampleServices[2] }, { prop: 'size', v: 'lg' }], noChildren: true },
        ],
    },
    {
        heading: 'LogoAndName with link context',
        description: 'Add the url prop to make the LogoAndName a link.',
        variations: [
            {
                component: 'LogoAndName',
                properties: [{ prop: 'brand', v: constants_1.sampleServices[2] },
                    { prop: 'url', v: "/tools/".concat((_a = constants_1.sampleServices[2]) === null || _a === void 0 ? void 0 : _a.metaCategoryId, "/").concat((_b = constants_1.sampleServices[2]) === null || _b === void 0 ? void 0 : _b.slug) }],
                noChildren: true,
            },
        ],
    },
    {
        heading: 'fontWeight prop',
        description: 'The LogoAndName comes with two font weights: regular and medium. It uses regular as default',
        variations: [
            {
                component: 'LogoAndName',
                properties: [{ prop: 'brand', v: constants_1.sampleServices[2] }, { prop: 'fontWeight', v: 'regular' }],
                noChildren: true,
            },
            {
                component: 'LogoAndName',
                properties: [{ prop: 'brand', v: constants_1.sampleServices[2] }, { prop: 'fontWeight', v: 'medium' }],
                noChildren: true,
            },
        ],
    },
    {
        heading: 'noWrap prop',
        description: 'The noWrap prop prevents the text from wrapping into the next line. This props is set to true by default.',
        variations: [
            {
                component: 'LogoAndName',
                properties: [{ prop: 'brand', v: constants_1.sampleServices[1] }, { prop: 'noWrap', v: true }],
                noChildren: true,
            },
            {
                component: 'LogoAndName',
                properties: [{ prop: 'brand', v: constants_1.sampleServices[1] }, { prop: 'noWrap', v: false }],
                noChildren: true,
            },
        ],
        previewStackProps: { maxWidth: 350, mx: 'auto' },
    },
];
