"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanFbData = void 0;
var cleanFbValue = function (val) {
    if (!val) {
        return val;
    }
    if (val.path && val.type === 'document') {
        return val.path;
    }
    if (typeof val.toDate === 'function') {
        return val.toDate().toJSON();
    }
    if (val && typeof val === 'object' && 'i_' in val) {
        // eslint-disable-next-line no-underscore-dangle
        return val.i_;
    }
    if (typeof val === 'object' && JSON.stringify(val).length > 1000) {
        return 'TOO-LONG';
    }
    return val;
};
var cleanFbData = function (data) { return data
    && Object.fromEntries(Object.entries(data).map(function (_a) {
        var _b = __read(_a, 2), k = _b[0], v = _b[1];
        return [k, cleanFbValue(v)];
    })); };
exports.cleanFbData = cleanFbData;
