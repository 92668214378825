"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var styles_1 = require("@mui/material/styles");
exports.default = (0, styles_1.styled)(react_fontawesome_1.FontAwesomeIcon)(function (_a) {
    var theme = _a.theme;
    return ({
        width: theme.typography.fontSize,
        height: theme.typography.fontSize,
    });
});
