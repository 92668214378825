"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var PREFIX = 'AccordionDetails';
var classes = {
    root: "".concat(PREFIX, "-root"),
};
var AccordionDetails = (0, styles_1.styled)(material_1.AccordionDetails)(function () {
    var _a;
    return (_a = {
            padding: '0 16px'
        },
        _a[".".concat(classes.root)] = {
            borderRadius: 0,
            transition: 'none',
            padding: 0,
        },
        _a);
});
exports.default = AccordionDetails;
