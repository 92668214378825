"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ICON_BUTTON_SIZES = void 0;
exports.ICON_BUTTON_SIZES = {
    small: {
        currency: { height: 11, width: 10.5 },
        decimal: { height: 6.5, width: 12.5 },
        invalidSpend: { height: 8, width: 8 },
        stripe: { height: 9, width: 21.5 },
        xero: { height: 9, width: 9.27 },
        scale: 0.5,
    },
    medium: {
        currency: { height: 22, width: 21 },
        decimal: { height: 13, width: 25 },
        invalidSpend: { height: 16, width: 16 },
        stripe: { height: 18, width: 43 },
        xero: { height: 18, width: 18.54 },
        scale: 1,
    },
    large: {
        currency: { height: 44, width: 42 },
        decimal: { height: 26, width: 50 },
        invalidSpend: { height: 36, width: 36 },
        stripe: { height: 36, width: 86 },
        xero: { height: 36, width: 37.08 },
        scale: 2,
    },
};
