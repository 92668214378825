"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sampleProfile = exports.sampleServices = exports.DESIGN_SYSTEM_NAVBAR = exports.DESIGN_SYSTEM_MENU = void 0;
exports.DESIGN_SYSTEM_MENU = [
    {
        label: 'Button', link: '/design-system/button',
    },
    {
        label: 'Icon Button', link: '/design-system/icon-button',
    },
    {
        label: 'Logo', link: '/design-system/logo',
    },
    {
        label: 'Avatar', link: '/design-system/avatar',
    },
    {
        label: 'LogosAndName', link: '/design-system/logosandname',
    },
    {
        label: 'Tooltip', link: '/design-system/tooltip',
    },
    {
        label: 'Dropzone', link: '/design-system/dropzone',
    },
    {
        label: 'TextField', link: '/design-system/textfield',
    },
];
exports.DESIGN_SYSTEM_NAVBAR = [
    { label: 'Design System', link: '/design-system' },
];
exports.sampleServices = [
    {
        id: 'YhN7TtnFm6JHXMW54buw',
        logoUrl: 'https://storage.googleapis.com/hudled-5cfe5.appspot.com/images/logos/services/docusign.png',
        name: 'Docusign',
        slug: 'DocuSign',
    },
    {
        id: 'k9YIm2QPwVTxt3NPnDIT',
        logoUrl: 'https://storage.googleapis.com/hudled-5cfe5.appspot.com/images/logos/services/aakonsult campaign status.jpg',
        name: 'AAkonsult Campaign Status',
        slug: 'AAkonsult-Campaign-Status',
    },
    {
        id: 'RK93r5b8Djc7BNtp64Mh',
        logoUrl: 'https://storage.googleapis.com/hudled-5cfe5.appspot.com/images/logos/services/canva.com',
        name: 'Canva',
        slug: 'Canva',
        metaCategoryId: 'design',
    },
    {
        id: 'GWKXAujnhk6xBEUVw7yz',
        logoUrl: 'https://storage.googleapis.com/hudled-5cfe5.appspot.com/images/logos/services/github.png',
        name: 'GitHub',
        slug: 'GitHub',
    },
];
exports.sampleProfile = {
    companyTeamRels: {
        AmFqrUH3LJjlwH4cG9OQ: {
            role: 'LEAD',
            position: 'Team Lead',
        },
        L9gtwzKz2Fo6EcVyDHB7: {
            role: 'LEAD',
        },
        POf7WeHzS2DKGE9SARsl: {
            role: 'LEAD',
        },
        kaIhVHejeXOpQEpaWyGU: {
            role: 'LEAD',
        },
    },
    hasRequestedSaasAudit: true,
    termsAndConditionsVersion: 2,
    termsOfUseVersion: 2,
    estimationAnnualSpend: {
        0: '5',
        1: '0',
        2: '0',
        currency: 'AUD',
        amount: 13500,
    },
    firstName: 'Jazmin',
    role: 'TEAM_MEMBER',
    notificationsLastReadOn: {
        seconds: 1676526431,
        nanoseconds: 752000000,
    },
    companyData: {
        eFaxw5p6cDFUFETfRco6: {
            baseCurrency: 'AUD',
            totalLTMSaasSpend: 0,
            logoUrl: 'https://firebasestorage.googleapis.com/v0/b/hudled-5cfe5.appspot.com/o/images%2Flogos%2FsE9yh3KJMSOrOA2HApzk99RkuiG3%2Fcompanies%2Fcmpny_eFaxw5p6cDFUFETfRco6__6?alt=media&token=483cfa8b-64c0-4eeb-a870-db67d302089e',
            name: 'Hudled Showcase',
        },
    },
    hudledExpectations: [
        'spend',
        'reduce',
        'feedback',
        'track',
    ],
    providerData: [
        {},
    ],
    isAdmin: true,
    estimationNumTools: '10',
    lastChangeUid: 'MeN9IuXMAKbEZyec4vVDSkdPUWK2',
    reviewsStep: 'REVIEWS_DONE',
    reviewsCount: 2,
    companyAccessLevel: {
        eFaxw5p6cDFUFETfRco6: 'OWNER',
    },
    department: 'Dev',
    companyId: 'eFaxw5p6cDFUFETfRco6',
    privacyPolicyVersion: 1,
    companyIds: [
        'eFaxw5p6cDFUFETfRco6',
    ],
    mainCompanyId: 'g1mneoHoPT1s8Xxw3Jac',
    email: 'jazmin@hudled.com',
    lastName: 'Aldana',
    closedCheckoutBanner: true,
    displayName: 'Jazmin Aldana',
    avatarNr: 6,
    completedOnboardingSteps: [
        'REVIEW_RECOMMENDATIONS',
        'VISIT_DEALS',
        'WELCOME_STEP',
        'INVITE_TEAMMATES',
    ],
    notificationsLastReadOnPerCompany: {
        eFaxw5p6cDFUFETfRco6: {
            seconds: 1694673164,
            nanoseconds: 637000000,
        },
    },
    avatarUrl: 'https://firebasestorage.googleapis.com/v0/b/hudled-5cfe5.appspot.com/o/images%2Favatars%2FMeN9IuXMAKbEZyec4vVDSkdPUWK2%2Fusr_MeN9IuXMAKbEZyec4vVDSkdPUWK2__6?alt=media&token=333cf811-ab87-4edb-af1f-d4f7d19b4cc3',
    reviewsFormSubmitCount: 1,
    completedWelcomeOnbSteps: [
        'USER_INTRO',
        'HUDLED_EXPECTATIONS',
        'SAAS_QUIZ',
        'SETUP_TEAM',
    ],
    accessLevel: 'OWNER',
    isEmpty: false,
    isLoaded: true,
    id: 'MeN9IuXMAKbEZyec4vVDSkdPUWK2',
};
