"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
exports.default = (0, styles_1.styled)('button')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'inline-block',
        textTransform: 'lowercase',
        marginBottom: 2,
        height: 18,
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.primary.main,
        borderRadius: 6,
        ':hover': {
            textDecoration: 'underline',
        },
        ':focus-visible': __assign({}, theme.focusVisibleStyles),
    });
});
