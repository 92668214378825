"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCancelAgreementTitle = exports.getCancelAgreementVerb = exports.exportAgreements = exports.getAgreementDateStr = exports.getAgreementDate = exports.discountSplitGroupDef = exports.statusGroupDef = void 0;
var end2end_1 = require("@hudled/end2end");
var CSV = __importStar(require("csv-stringify/browser/esm/sync"));
var dateTime_1 = require("utils/dateTime");
var fieldProcessors_1 = require("utils/fieldProcessors");
var filter_1 = require("utils/filter");
var formatters_1 = require("utils/formatters");
var logging_1 = require("utils/logging");
var constants_1 = require("./constants");
var tableConf_1 = require("./tableConf");
exports.statusGroupDef = (0, filter_1.makeGroupDef)('Status', function (item) { return (0, end2end_1.getCustomerExtendedAgreementStatus)(item) ? constants_1.AGREEMENT_STATUS_MAPPER[(0, end2end_1.getCustomerExtendedAgreementStatus)(item)].text : 'Empty'; });
exports.discountSplitGroupDef = (0, filter_1.makeGroupDef)('Discount Split', function (item) { return item.accFirmDiscountSplit !== undefined
    ? "".concat((0, end2end_1.formatPercentage)(1 - item.accFirmDiscountSplit), " client | ").concat((0, end2end_1.formatPercentage)(item.accFirmDiscountSplit), " firm")
    : 'Set by vendor split'; });
var getAgreementDate = function (item) {
    var _a, _b, _c, _d;
    switch ((0, end2end_1.getCustomerExtendedAgreementStatus)(item)) {
        case 'scheduled':
            return ((_a = item.rechargerCompany) === null || _a === void 0 ? void 0 : _a.rechargesNextInvoiceDate) ? new Date(item.rechargerCompany.rechargesNextInvoiceDate) : undefined;
        case 'cancelled':
            return (_b = item.agreementInfo) === null || _b === void 0 ? void 0 : _b.cancelledAt;
        case 'accepted':
        case 'migrated':
            return (_c = item.agreementInfo) === null || _c === void 0 ? void 0 : _c.acceptedAt;
        case 'opened_by_all':
        case 'opened_by_copy_link':
        case 'opened_by_email':
            return (_d = item.agreementOpened) === null || _d === void 0 ? void 0 : _d.lastAccess;
        case 'sent':
            return item.agreementSentOn;
        default:
            return undefined;
    }
};
exports.getAgreementDate = getAgreementDate;
var getAgreementDateStr = function (item) {
    var date = (0, exports.getAgreementDate)(item);
    return date && (0, dateTime_1.formatRelativeMidSentence)(date);
};
exports.getAgreementDateStr = getAgreementDateStr;
var AGREEMENT_HEADERS = ['customerName', 'contactName', 'email', 'organizationsCount', 'agreementStatus',
    'agreementSent', 'lastOpened', 'acceptedDate', 'agreementLink', 'clientDiscountSplit'];
var ADMIN_AGREEMENT_HEADERS = __spreadArray(['id'], __read(AGREEMENT_HEADERS), false);
var getStatusText = function (item) { return constants_1.AGREEMENT_STATUS_MAPPER[(0, end2end_1.getCustomerExtendedAgreementStatus)(item)].text; };
var AGREEMENTS_MAPPER = {
    customerName: function (item) { return item.name; },
    contactName: function (item) { return item.primaryContactName; },
    email: function (item) { return item.primaryContactEmail; },
    organizationsCount: function (item) { var _a, _b; return "".concat((_b = (_a = tableConf_1.customerAgreementsTableConf.extractors) === null || _a === void 0 ? void 0 : _a.organizationsCount) === null || _b === void 0 ? void 0 : _b.call(_a, item)); },
    agreementSent: function (item) { return (item === null || item === void 0 ? void 0 : item.agreementSentOn) && (0, end2end_1.getISODate)(item.agreementSentOn); },
    lastOpened: function (item) { var _a; return ((_a = item.agreementOpened) === null || _a === void 0 ? void 0 : _a.lastAccess) && (0, end2end_1.getISODate)(item.agreementOpened.lastAccess); },
    // migrationDate: (item) => item.agreementInfo?.acceptedAt && getISODate(item.agreementInfo.acceptedAt),
    acceptedDate: function (item) {
        var _a;
        return ['accepted', 'migrated'].includes((0, end2end_1.getCustomerExtendedAgreementStatus)(item))
            ? (0, end2end_1.getISODate)((_a = item.agreementInfo) === null || _a === void 0 ? void 0 : _a.acceptedAt)
            : '';
    },
    agreementStatus: getStatusText,
    agreementLink: function (item) { return item.publicId ? (0, end2end_1.getCustomerAgreementUrl)(item) : ''; },
    clientDiscountSplit: function (item) { return item.accFirmDiscountSplit
        ? "".concat((0, end2end_1.formatPercentage)(item.accFirmDiscountSplit), " client | ").concat((0, end2end_1.formatPercentage)(1 - item.accFirmDiscountSplit), " firm")
        : 'Set by vendor split'; },
};
var exportAgreements = function (items, fileName, isAdmin) {
    var headers = isAdmin ? ADMIN_AGREEMENT_HEADERS : AGREEMENT_HEADERS;
    var rows = items.map((0, fieldProcessors_1.makeCsvRowGenerator)(headers, AGREEMENTS_MAPPER, true));
    try {
        (0, fieldProcessors_1.saveAsCSV)(CSV.stringify(__spreadArray([headers.map(formatters_1.camelToProper)], __read(rows), false)), fileName);
    }
    catch (e) {
        (0, logging_1.logError)(e);
    }
};
exports.exportAgreements = exportAgreements;
exports.getCancelAgreementVerb = end2end_1.getCancellationType;
var getCancelAgreementTitle = function (item) { return "".concat((0, exports.getCancelAgreementVerb)(item), " agreement"); };
exports.getCancelAgreementTitle = getCancelAgreementTitle;
