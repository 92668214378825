import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getErrorToShow } from 'utils/form/validators';

const InputSimple = ({
  input, inputProps, label, inputType, type = 'text', className, placeholder, disabled, meta,
}) => {
  const InputElement = inputType === 'textarea' ? 'textarea' : 'input';
  const errorToShow = getErrorToShow(meta);

  return (
    <div className="ds-form-group row form-group is-mobile-tablet mb-0">
      <div>
        <InputElement
          {...input}
          {...inputProps}
          placeholder={placeholder || label}
          type={inputType || type}
          disabled={disabled}
          className={classNames('form-control', className)}
        />
        {errorToShow && <span>{errorToShow}</span>}
      </div>
    </div>
  );
};

InputSimple.propTypes = {
  label: PropTypes.string,
  inputType: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  meta: PropTypes.shape({ // how to define this on interface Props for TSX
    dirty: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  input: PropTypes.object,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default InputSimple;
