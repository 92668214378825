"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var Graph_1 = require("./Graph");
var CssBarLine = function (_a) {
    var spend = _a.spend, maxScale = _a.maxScale, steps = _a.steps, isSelected = _a.isSelected;
    var heightPX = (steps === 4 ? 160 : 120) * (spend / maxScale);
    heightPX = heightPX > 166 ? 160 : heightPX;
    return (0, jsx_runtime_1.jsx)(Graph_1.StyledBarGraph, { heightPX: heightPX, isSelected: isSelected });
};
exports.default = CssBarLine;
