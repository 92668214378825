"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCountryCode = exports.getRegionNameLc = exports.getRegionName = void 0;
var interfaces_1 = require("@hudled/interfaces");
var getRegionName = function (countryCode) {
    var regionNamer = new Intl.DisplayNames(['en'], { type: 'region' });
    try {
        return regionNamer.of(countryCode);
    }
    catch (e) {
        console.log('Failed to name', countryCode);
        return undefined;
    }
};
exports.getRegionName = getRegionName;
var getRegionNameLc = function (countryCode) { var _a; return (_a = (0, exports.getRegionName)(countryCode)) === null || _a === void 0 ? void 0 : _a.toLowerCase(); };
exports.getRegionNameLc = getRegionNameLc;
var countryCodeMap;
var EXCEPTIONS = {
    'congo': 'CG',
    'great britain': 'GB',
    'england': 'GB',
    'united states of america': 'US',
    'usa': 'US',
    'hong kong': 'US',
    'the netherlands': 'NL',
    'turkey': 'TR',
    'uae': 'AE',
};
function getCountryCode(country) {
    if (!country) {
        return undefined;
    }
    if (country.match(/^[a-z]{2}$/i) && interfaces_1.COUNTRY_CODES.includes(country.toUpperCase())) {
        // ToDo: always save country code in it's 2 letters, but notify admins if it's not in the list of COUNTRY_CODES
        return country.toUpperCase();
    }
    var countryLc = country.toLowerCase();
    if (EXCEPTIONS[countryLc]) {
        return EXCEPTIONS[countryLc];
    }
    if (!countryCodeMap) {
        countryCodeMap = Object.fromEntries(interfaces_1.COUNTRY_CODES.map(function (cC) { return [(0, exports.getRegionNameLc)(cC), cC]; }));
    }
    return countryCodeMap[countryLc];
}
exports.getCountryCode = getCountryCode;
