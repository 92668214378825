"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.exportOrganizations = exports.ORGANIZATION_MAPPER = exports.organizationHeaders = exports.exportMappedCustomers = exports.CUSTOMERS_MAPPER = exports.headersWithAddress = exports.headersOnlyMapped = void 0;
var end2end_1 = require("@hudled/end2end");
var CSV = __importStar(require("csv-stringify/browser/esm/sync"));
var fieldProcessors_1 = require("utils/fieldProcessors");
var formatters_1 = require("utils/formatters");
var logging_1 = require("utils/logging");
exports.headersOnlyMapped = ['customer', 'email', 'firstName', 'fullName', 'organisations', 'organisationsMapped'];
exports.headersWithAddress = __spreadArray(__spreadArray([], __read(exports.headersOnlyMapped), false), ['addressLine1', 'addressLine2', 'city', 'state', 'postalCode', 'country'], false);
exports.CUSTOMERS_MAPPER = {
    customer: function (customer) { return customer.name; },
    email: function (customer) { return customer.primaryContactEmail; },
    firstName: function (customer) { return customer.primaryContactFirstName; },
    fullName: function (customer) { return customer.primaryContactName; },
    organisations: function (_a) {
        var organizations = _a.organizations;
        return "".concat(organizations === null || organizations === void 0 ? void 0 : organizations.length);
    },
    organisationsMapped: function (_a) {
        var organizations = _a.organizations;
        return organizations.map(function (org) { return org.name; }).join();
    },
    addressLine1: function (customer) { var _a; return (_a = customer.address) === null || _a === void 0 ? void 0 : _a.line1; },
    addressLine2: function (customer) { var _a; return (_a = customer.address) === null || _a === void 0 ? void 0 : _a.line2; },
    city: function (customer) { var _a; return (_a = customer.address) === null || _a === void 0 ? void 0 : _a.city; },
    state: function (customer) { var _a; return (_a = customer.address) === null || _a === void 0 ? void 0 : _a.state; },
    postalCode: function (customer) { var _a; return (_a = customer.address) === null || _a === void 0 ? void 0 : _a.postalCode; },
    country: function (customer) { var _a, _b; return ((_a = customer.address) === null || _a === void 0 ? void 0 : _a.country) ? (0, end2end_1.getRegionName)((_b = customer.address) === null || _b === void 0 ? void 0 : _b.country) : undefined; },
};
var exportMappedCustomers = function (rechCustomers, headers, fileName) {
    var customerRows = rechCustomers.map((0, fieldProcessors_1.makeCsvRowGenerator)(headers, exports.CUSTOMERS_MAPPER, true));
    try {
        (0, fieldProcessors_1.saveAsCSV)(CSV.stringify(__spreadArray([headers.map(formatters_1.camelToProper)], __read(customerRows), false)), fileName);
    }
    catch (e) {
        (0, logging_1.logError)(e);
    }
};
exports.exportMappedCustomers = exportMappedCustomers;
exports.organizationHeaders = ['organisation', 'mappingStatus', 'customerMapped', 'vendorSource'];
exports.ORGANIZATION_MAPPER = {
    organisation: function (_a) {
        var name = _a.name;
        return name;
    },
    mappingStatus: function (_a) {
        var matchStatus = _a.matchStatus;
        return (0, formatters_1.camelToProper)(matchStatus);
    },
    customerMapped: function (_a) {
        var customer = _a.customer;
        return customer === null || customer === void 0 ? void 0 : customer.name;
    },
    vendorSource: function (_a) {
        var firstVendorId = _a.firstVendorId;
        return firstVendorId;
    },
};
var exportOrganizations = function (rechOrgs, fileName) {
    var rechOrgRows = rechOrgs.map((0, fieldProcessors_1.makeCsvRowGenerator)(exports.organizationHeaders, exports.ORGANIZATION_MAPPER, true));
    try {
        (0, fieldProcessors_1.saveAsCSV)(CSV.stringify(__spreadArray([exports.organizationHeaders.map(formatters_1.camelToProper)], __read(rechOrgRows), false)), fileName);
    }
    catch (e) {
        (0, logging_1.logError)(e);
    }
};
exports.exportOrganizations = exportOrganizations;
