"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStatusFilter = exports.useAssigneesFilter = void 0;
var interfaces_1 = require("@hudled/interfaces");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var utils_1 = require("containers/Recommendations/utils");
var single_1 = require("../selectors/single");
var useAssigneesFilter = function (recommendations) {
    var auth = (0, react_redux_1.useSelector)(single_1.selectAuth);
    var location = (0, react_router_1.useLocation)();
    var queryParams = new URLSearchParams(location.search);
    var defaultToMine = queryParams.get('default_mine');
    var _a = __read((0, react_1.useState)(defaultToMine ? [auth.uid] : []), 2), ownersToFilterBy = _a[0], setOwnersToFilterBy = _a[1];
    var recommendationsToDisplay;
    if (!recommendations) {
        recommendationsToDisplay = recommendations;
    }
    else {
        recommendationsToDisplay = ownersToFilterBy.length === 0
            ? recommendations
            : recommendations.filter(function (recommendation) {
                if (!recommendation.assigneeId || !recommendation.defaultAssigneeId)
                    return false;
                return ownersToFilterBy.includes(recommendation.assigneeId || recommendation.defaultAssigneeId);
            });
    }
    return [recommendationsToDisplay, setOwnersToFilterBy, ownersToFilterBy];
};
exports.useAssigneesFilter = useAssigneesFilter;
var useStatusFilter = function (recommendations) {
    var _a = __read((0, react_1.useState)(null), 2), statusToFilterBy = _a[0], setStatusToFilterBy = _a[1];
    var recommendationsToDisplay;
    if (!recommendations) {
        recommendationsToDisplay = recommendations;
    }
    else {
        // When not filtering filter out the rejected that have been changed more than an hour ago
        recommendationsToDisplay = !statusToFilterBy
            ? recommendations === null || recommendations === void 0 ? void 0 : recommendations.filter(function (recommendation) { return (recommendation.status !== interfaces_1.RECOMMENDATIONS_STATUS.REJECTED
                || !recommendation.lastStatusChange
                || Date.now() - (recommendation.lastStatusChange.toMillis()) < 3600 * 1000); })
            : recommendations === null || recommendations === void 0 ? void 0 : recommendations.filter(function (recommendation) { return (0, utils_1.getRecommendationStatus)(recommendation) === statusToFilterBy; });
    }
    return [recommendationsToDisplay, setStatusToFilterBy, statusToFilterBy];
};
exports.useStatusFilter = useStatusFilter;
