"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectRechCustomerOptionsForCrawlMapping = exports.selectStripeMigratedCustomerOptions = exports.selectRechContactOptions = exports.selectRechargeCustomerOptions = exports.selectServiceOptions = exports.makeOptionFromService = exports.getServiceLabel = exports.selectUserOptions = exports.makeOptionFromUser = void 0;
var end2end_1 = require("@hudled/end2end");
var lodash_1 = require("lodash");
var reselect_1 = require("reselect");
var ordered_1 = require("./ordered");
var populatedDerived_1 = require("./populatedDerived");
var makeOptionFromUser = function (usr, k, users) { return ({
    value: usr.id,
    label: "".concat((0, end2end_1.getDisplayName)(usr)).concat(users.filter(function (u) { return (0, end2end_1.getDisplayName)(u) === (0, end2end_1.getDisplayName)(usr); }).length > 1 ? " (".concat(usr.email, ")") : ''),
}); };
exports.makeOptionFromUser = makeOptionFromUser;
exports.selectUserOptions = (0, reselect_1.createSelector)(ordered_1.selectUsers, function (users) { var _a; return (_a = users === null || users === void 0 ? void 0 : users.map(exports.makeOptionFromUser)) !== null && _a !== void 0 ? _a : []; });
var getServiceLabel = function (service) { return service.niceName || service.name; };
exports.getServiceLabel = getServiceLabel;
var makeOptionFromService = function (srv, k, services) { return ({
    value: srv.id,
    label: "".concat((0, exports.getServiceLabel)(srv)).concat(services.filter(function (s) { return (0, exports.getServiceLabel)(s) === (0, exports.getServiceLabel)(srv); }).length > 1
        ? " (".concat(srv.id, ")") : ''),
}); };
exports.makeOptionFromService = makeOptionFromService;
exports.selectServiceOptions = (0, reselect_1.createSelector)(ordered_1.selectServices, function (services) { var _a; return (_a = services === null || services === void 0 ? void 0 : services.map(exports.makeOptionFromService)) !== null && _a !== void 0 ? _a : []; });
var makeOptionFromAccountingContact = function (idGet) { return function (rechCust, rechargedCustomersNameGroups) { return ({
    value: idGet(rechCust),
    label: "".concat(rechCust.name).concat(rechargedCustomersNameGroups[rechCust.name].length > 1 ? " (".concat(rechCust.primaryContactEmail, ")") : ''),
}); }; };
var makeOptionFromStripeMigratedCustomer = function (strpMigrCust, strpMigrCustNameGroups, strpMigrCustNameGroups2) {
    var _a, _b;
    return ({
        value: strpMigrCust.id,
        // eslint-disable-next-line no-nested-ternary
        label: ((_a = strpMigrCustNameGroups[strpMigrCust.name]) === null || _a === void 0 ? void 0 : _a.length) > 1
            ? getFullStripeMigratedCustomerName(strpMigrCust, ((_b = strpMigrCustNameGroups2[getFullStripeMigratedCustomerName(strpMigrCust)]) === null || _b === void 0 ? void 0 : _b.length) > 1)
            : strpMigrCust.name,
        obj: strpMigrCust,
    });
};
var makeRechContactOptions = function (idGet) {
    if (idGet === void 0) { idGet = function (rC) { return rC.id; }; }
    return function (rechContacts) {
        var _a;
        var rechCustomersNameGroups = (0, lodash_1.groupBy)(rechContacts, 'name');
        return (_a = rechContacts === null || rechContacts === void 0 ? void 0 : rechContacts.map(function (rC) { return makeOptionFromAccountingContact(idGet)(rC, rechCustomersNameGroups); })) !== null && _a !== void 0 ? _a : [];
    };
};
exports.selectRechargeCustomerOptions = (0, reselect_1.createSelector)(ordered_1.selectRechargedCustomers, makeRechContactOptions());
exports.selectRechContactOptions = (0, reselect_1.createSelector)(ordered_1.selectRechContacts, makeRechContactOptions(function (rC) { var _a; return (_a = rC.inPlatformId) !== null && _a !== void 0 ? _a : ''; }));
var getExtraNameData = function (strpMigrCust, withId) {
    var _a, _b, _c, _d;
    var last4 = ((_b = (_a = strpMigrCust.defaultPaymentMethod) === null || _a === void 0 ? void 0 : _a.auBecsDebit) === null || _b === void 0 ? void 0 : _b.last4) || ((_d = (_c = strpMigrCust.defaultPaymentMethod) === null || _c === void 0 ? void 0 : _c.card) === null || _d === void 0 ? void 0 : _d.last4);
    return [
        strpMigrCust.fullName,
        last4 && "**".concat(last4),
        withId && "ID:..".concat(strpMigrCust.id.slice(-8)),
    ].filter(end2end_1.notFalsy).join(' - ');
};
var getFullStripeMigratedCustomerName = function (stripeMigratedCustomer, withId) { return [
    stripeMigratedCustomer.name,
    getExtraNameData(stripeMigratedCustomer, withId) && "(".concat(getExtraNameData(stripeMigratedCustomer, withId), ")"),
].filter(end2end_1.notFalsy).join(' '); };
exports.selectStripeMigratedCustomerOptions = (0, reselect_1.createSelector)(ordered_1.selectStripeMigratedCustomers, ordered_1.selectRechargedCustomers, function (stripeMigratedCustomers, rechCustomers) {
    var _a;
    var alreadyUsedStripeIds = new Set(rechCustomers === null || rechCustomers === void 0 ? void 0 : rechCustomers.map(function (rC) { return rC.inStripeId; }).filter(end2end_1.notFalsy));
    var alreadyUsedStripeMigratedIds = new Set(rechCustomers === null || rechCustomers === void 0 ? void 0 : rechCustomers.map(function (rC) { return rC.stripeMigratedCustomerId; }).filter(end2end_1.notFalsy));
    var selectableStripeMigratedCustomers = stripeMigratedCustomers === null || stripeMigratedCustomers === void 0 ? void 0 : stripeMigratedCustomers.filter(function (sMC) { return !alreadyUsedStripeIds.has(sMC.inStripeId) && !alreadyUsedStripeMigratedIds.has(sMC.id); });
    var strpMigrCustomersNameGroups = (0, lodash_1.groupBy)(selectableStripeMigratedCustomers, 'name');
    var strpMigrCustomersNameGroups2 = (0, lodash_1.groupBy)(selectableStripeMigratedCustomers, function (v) { return getFullStripeMigratedCustomerName(v); });
    return (_a = selectableStripeMigratedCustomers === null || selectableStripeMigratedCustomers === void 0 ? void 0 : selectableStripeMigratedCustomers.map(function (sMC) { return makeOptionFromStripeMigratedCustomer(sMC, strpMigrCustomersNameGroups, strpMigrCustomersNameGroups2); })) !== null && _a !== void 0 ? _a : [];
});
exports.selectRechCustomerOptionsForCrawlMapping = (0, reselect_1.createSelector)(populatedDerived_1.selectCustomersToMapToCrawl, function (rechCustomers) { return rechCustomers && rechCustomers.map(function (customer) { return ({ value: customer.id, label: customer.name, obj: customer }); }); });
