"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
exports.default = (0, material_1.styled)(material_1.Box)(function (_a) {
    var theme = _a.theme;
    return ({
        height: 'auto',
        width: 1000,
        margin: theme.spacing(1, 'auto', 2, 'auto'),
    });
});
