"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var end2end_1 = require("@hudled/end2end");
var react_helmet_1 = require("react-helmet");
var react_redux_1 = require("react-redux");
var other_1 = require("utils/selectors/other");
var HudledHelmet = function (_a) {
    var title = _a.title, description = _a.description, children = _a.children;
    var unreadNotificationsCount = (0, react_redux_1.useSelector)(other_1.selectUnreadNotificationsCount);
    var defaultTitle = end2end_1.IS_RECHARGLY
        ? 'Rechargly - Billing automation for software subscriptions'
        : 'Hudled - SaaS intelligence platform';
    return ((0, jsx_runtime_1.jsxs)(react_helmet_1.Helmet, { children: [(0, jsx_runtime_1.jsx)("link", { rel: "icon", href: end2end_1.IS_RECHARGLY ? '/rechargly-favicon-lt.png' : '/hudled-favicon.png', media: "(prefers-color-scheme: light)" }), (0, jsx_runtime_1.jsx)("link", { rel: "icon", href: end2end_1.IS_RECHARGLY ? '/rechargly-favicon-dk.png' : '/hudled-favicon.png', media: "(prefers-color-scheme: dark)" }), title
                ? (0, jsx_runtime_1.jsx)("title", { children: unreadNotificationsCount ? "(".concat(unreadNotificationsCount, ") ").concat(title) : title })
                : (0, jsx_runtime_1.jsx)("title", { children: defaultTitle }), description && (0, jsx_runtime_1.jsx)("meta", { name: "description", content: description }), children] }));
};
exports.default = HudledHelmet;
