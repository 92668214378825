"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Wrapper = (0, material_1.styled)('div')(function (_a) {
    var _b;
    var theme = _a.theme, maxWidth = _a.maxWidth;
    return ({
        padding: theme.spacing(0.25, 0.5, 0.5),
        fontSize: theme.typography.pxToRem(13),
        maxWidth: maxWidth && "".concat(maxWidth, "px"),
        color: (_b = theme.palette.neutral) === null || _b === void 0 ? void 0 : _b.contrastText,
        '& strong': {
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightMedium,
        },
    });
});
var TooltipDescription = (0, material_1.styled)('div')(function (_a) {
    var theme = _a.theme;
    return ({
        margin: 0,
        marginTop: theme.spacing(0.5),
        '& ul': {
            margin: 0,
        },
        fontSize: theme.typography.pxToRem(12),
        lineHeight: '1.33',
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightRegular,
    });
});
var TooltipTemplate = function (_a) {
    var title = _a.title, description = _a.description, maxWidth = _a.maxWidth;
    return ((0, jsx_runtime_1.jsxs)(Wrapper, __assign({ maxWidth: maxWidth }, { children: [title && (0, jsx_runtime_1.jsx)("div", { children: title }), description && (0, jsx_runtime_1.jsx)(TooltipDescription, { children: description })] })));
};
exports.default = TooltipTemplate;
