"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseNumber = void 0;
var parseNumber = function (value) {
    if (!value)
        return undefined;
    var numValue = parseInt(value.replace(/[^\d]/g, ''), 10); // Remove any text char typed in and Cast string to number ("123" => 123)
    return Number.isNaN(numValue) ? undefined : numValue;
};
exports.parseNumber = parseNumber;
