"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExchangeRate = void 0;
var getExchangeRate = function (dayRates, transaction, connection) { return function (targetCurrency) {
    var _a;
    if (transaction.currency === targetCurrency)
        return 1;
    return ((_a = connection === null || connection === void 0 ? void 0 : connection.baseCurrency) !== null && _a !== void 0 ? _a : 'AUD') === targetCurrency && transaction.currencyRate
        || dayRates && dayRates[transaction.currency] / dayRates[targetCurrency];
    //                       1 (AUD)                  0.7 (USD)
}; };
exports.getExchangeRate = getExchangeRate;
