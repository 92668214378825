"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var IconButtons_1 = require("atomicComponents/molecules/IconButtons/IconButtons");
var context_1 = require("../context");
var RemoveRowBtn = function (_a) {
    var item = _a.item;
    var removeRow = (0, react_1.useContext)(context_1.AnalyserFormContext).removeRow;
    return ((0, jsx_runtime_1.jsx)(IconButtons_1.RemoveButton, { outlined: true, onClick: function () { return removeRow(item.id); } }));
};
exports.default = RemoveRowBtn;
