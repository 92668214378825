"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
exports.default = (0, styles_1.styled)(material_1.TableRow, {
    shouldForwardProp: function (prop) { return prop !== 'selected'; },
})(function (_a) {
    var selected = _a.selected;
    return ({
        cursor: 'pointer',
        '#showOnHover': {
            opacity: 0,
        },
        '&:hover': {
            '#showOnHover': {
                opacity: 1,
            },
        },
        // Only if selected is explicitly passed in as false, we reduce the opacity.
        // selected is undefined when nothing is selected and false when something is selected, but not this row
        opacity: selected === false ? 0.5 : 1,
    });
});
