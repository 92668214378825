"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
exports.default = (0, material_1.styled)(material_1.Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(3, 0),
        padding: theme.spacing(3, 2),
        borderRadius: theme.shape.borderRadius,
        width: 450,
        boxShadow: '0px 3px 8px rgba(0,0,0,0.12)',
        textAlign: 'center',
        alignItems: 'center',
    });
});
