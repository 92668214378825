"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var general_1 = require("utils/general");
var constants_1 = require("./constants");
var LogoImg = (0, styles_1.styled)('img', { shouldForwardProp: (0, general_1.omitProps)('size', 'fullWidth') })(function (_a) {
    var _b = _a.size, size = _b === void 0 ? 'md' : _b, _c = _a.fullWidth, fullWidth = _c === void 0 ? false : _c;
    return (__assign({ verticalAlign: 'text-bottom', objectFit: 'contain' }, (size && {
        width: fullWidth ? 'auto' : constants_1.IMG_SIZES[size].size,
        height: constants_1.IMG_SIZES[size].size,
        borderRadius: !fullWidth ? constants_1.IMG_SIZES[size].borderRadius : undefined,
    })));
});
exports.default = LogoImg;
