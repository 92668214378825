"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.myStackToolViewTabs = exports.myStackTabs = void 0;
exports.myStackTabs = [
    { label: 'Tools', href: '/my-stack' },
    { label: 'By spend', href: '/my-stack/spend' },
];
exports.myStackToolViewTabs = [
    { label: 'Tools', href: '/my-stack' },
    { label: 'close preview' },
];
