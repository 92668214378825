"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSoql = void 0;
var getSoql = function (_a) {
    var fields = _a.fields, from = _a.from, where = _a.where, groupBy = _a.groupBy, orderBy = _a.orderBy, limit = _a.limit, offset = _a.offset, extra = _a.extra;
    return "SELECT ".concat(fields, " FROM ").concat(from).concat(where ? " WHERE ".concat(where) : '').concat(groupBy ? " GROUP BY ".concat(groupBy) : '').concat(orderBy ? " ORDER BY ".concat(orderBy) : '').concat(limit ? " LIMIT ".concat(limit) : '').concat(offset ? " OFFSET ".concat(offset) : '').concat(extra !== null && extra !== void 0 ? extra : '').trim();
};
exports.getSoql = getSoql;
/*
SELECT fieldList [subquery][...]
[TYPEOF typeOfField whenExpression[...] elseExpression END][...]
FROM objectType[,...]
    [USING SCOPE filterScope]
[WHERE conditionExpression]
[WITH [DATA CATEGORY] filteringExpression]
[GROUP BY {fieldGroupByList|ROLLUP (fieldSubtotalGroupByList)|CUBE (fieldSubtotalGroupByList)}
    [HAVING havingConditionExpression] ]
[ORDER BY fieldOrderByList {ASC|DESC} [NULLS {FIRST|LAST}] ]
[LIMIT numberOfRowsToReturn]
[OFFSET numberOfRowsToSkip]
[FOR {VIEW  | REFERENCE}[,...] ]
      [ UPDATE {TRACKING|VIEWSTAT}[,...] ]
* */
