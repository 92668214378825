"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var system_1 = require("@mui/system");
var TableBody = (0, system_1.styled)(material_1.Box)(function () { return ({
    zIndex: 1,
    '::-webkit-scrollbar': {
        width: 0,
        background: 'transparent',
    },
}); });
exports.default = TableBody;
