"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddTeamOnboardingStep = exports.useAddCompanyOnboardingStep = exports.useAddUserOnboardingStep = void 0;
var firestore_1 = require("firebase/firestore");
var react_redux_1 = require("react-redux");
var hooks_1 = require("utils/hooks");
var single_1 = require("utils/selectors/single");
var useAddUserOnboardingStep = function () {
    var authState = (0, react_redux_1.useSelector)(single_1.selectAuth);
    var _a = __read((0, hooks_1.useSubmitter)('users', authState.uid), 1), updateProfile = _a[0];
    return function (onboardingStep) { return updateProfile({ completedOnboardingSteps: (0, firestore_1.arrayUnion)(onboardingStep) }); };
};
exports.useAddUserOnboardingStep = useAddUserOnboardingStep;
var useAddCompanyOnboardingStep = function () {
    var profile = (0, react_redux_1.useSelector)(single_1.selectProfile);
    var _a = __read((0, hooks_1.useSubmitter)('companies', profile.companyId), 1), updateCompany = _a[0];
    return function (onboardingStep) { return updateCompany({ completedOnboardingSteps: (0, firestore_1.arrayUnion)(onboardingStep) }); };
};
exports.useAddCompanyOnboardingStep = useAddCompanyOnboardingStep;
var useAddTeamOnboardingStep = function () {
    var companyTeam = (0, react_redux_1.useSelector)(single_1.selectMyCompanyTeam);
    var _a = __read((0, hooks_1.useSubmitter)('company_teams', companyTeam === null || companyTeam === void 0 ? void 0 : companyTeam.id), 1), updateCompanyTeam = _a[0];
    return function (onboardingStep) {
        var _a;
        if (!((_a = companyTeam === null || companyTeam === void 0 ? void 0 : companyTeam.completedOnboardingSteps) === null || _a === void 0 ? void 0 : _a.includes(onboardingStep))) {
            return updateCompanyTeam({ completedOnboardingSteps: (0, firestore_1.arrayUnion)(onboardingStep) });
        }
        return null;
    };
};
exports.useAddTeamOnboardingStep = useAddTeamOnboardingStep;
