"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var end2end_1 = require("@hudled/end2end");
// source: 'Invite' | 'Organic' | 'Promotion' | 'Campaign';
// pricingPlanSelected?: 'Free' | 'Startup' | 'Growth' | 'Enterprise';
var AuthTypeMapper = { 'google.com': 'Google SSO', google: 'Google SSO', password: 'Email' };
var AuthSourceMapper = {
    activate: 'Invite', signup: 'Organic', promote: 'Promotion',
};
//    // pricingPlanSelected?: 'Free' | 'Startup' | 'Growth' | 'Enterprise';
//   plan?: 'free' | 'startup' | 'growth' | 'enterprise' | null;
var valueTranslator = {
    'User Logged-in': {
        authType: AuthTypeMapper,
    },
    'User Logged-out': {
        authType: AuthTypeMapper,
    },
    'User Account Created': {
        authType: AuthTypeMapper,
        source: AuthSourceMapper,
        inviteeAccessLevel: end2end_1.ACC_LVL_MAPPER,
        inviterAccessLevel: end2end_1.ACC_LVL_MAPPER,
    },
    'User Account Creation Initiated': {
        authType: AuthTypeMapper,
        source: AuthSourceMapper,
    },
    'Activation Step Completed': {
        stepName: {
            USER_INTRO: 'About User',
            SET_ACCOUNTING: 'Choose Accounting System',
            CONNECT_ACCOUNTING: 'Connect Accounting System',
            COMPANY_INTRO: 'About Company',
            TRACK_SOFTWARE: 'Tracking Method',
            TRACK_SOFTWARE_TEAM: 'Tracking Method Team',
            SAAS_QUIZ: 'SaaS Quiz',
        },
        previousSaasTracking: {
            SPREADSHEET: 'Spreadsheet',
            COMPANY_WIKI: 'Company wiki',
            ACCOUNTING_SYSTEM: 'Accounting System',
            NONE: 'None',
            DK: 'Don\'t know',
            OTHER: 'Other',
        },
    },
    'Activation Flow Completed': {
        accessLevel: end2end_1.ACC_LVL_MAPPER,
    },
    'Activation Flow Deferred': {
        accessLevel: end2end_1.ACC_LVL_MAPPER,
    },
};
exports.default = valueTranslator;
