import { createTheme } from '@mui/material/styles';

import { alertStyles } from './alertStyles';
import BUTTON_COLOR_MAPPER from './button/buttonColorMapper';
import { buttonGroupStyles } from './button/buttonGroupStyles';
import { buttonStyles } from './button/buttonStyles';
import { checkboxStyles } from './checkboxStyles';
import { COLORS } from './constants';
// import { textfieldStyles } from './forms/textfieldStyles';
import { iconButtonStyles } from './iconButtonStyles';
import { skeletonStyles } from './skeletonStyles';
import { tabStyles } from './tabStyles';

const rawTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 769,
      lg: 992,
      xl: 1920,
    },
  },
  typography: {
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontFamily: 'Fellix',
    fontHeightRegular: 1.5,
    letterSpacing: '0.023em',
  },
  palette: {
    text: {
      primary: COLORS.obsidian, // ToDo: set to COLORS.riverstone
      secondary: COLORS.charcoal,
      caption: COLORS.riverstone,
    },
    ...COLORS,
    ...BUTTON_COLOR_MAPPER,
  },
  shape: {
    borderRadius: 6,
  },
  button: {
    borderWidth: 1,
    borderRadius: 5,
  },
  focusVisibleStyles: {
    boxShadow: `0px 0px 0px 2px rgba(255, 255, 255, 0.9), 0px 0px 0px 3px ${BUTTON_COLOR_MAPPER.primary.main}`,
    outline: 'transparent',
  },
  focusVisibleLinkStyles: {
    outlineOffset: 0,
    outlineStyle: 'none',
    outlineWidth: 0,
  },
  primaryUnderline: {
    textDecoration: `2px underline ${BUTTON_COLOR_MAPPER.primary.main}`,
  },
  blend: {
    inverted500: `linear-gradient(140deg, ${COLORS.roseQuartz.A500} 0%, ${COLORS.azzurro.A500} 83.85%)`,
    500: `linear-gradient(140deg, ${COLORS.azzurro.A500} 0%, ${COLORS.roseQuartz.A500} 83.85%)`,
    400: `linear-gradient(140deg, ${COLORS.azzurro.A400} 0%, ${COLORS.roseQuartz.A400} 83.85%)`,
    300: `linear-gradient(140deg, ${COLORS.azzurro.A300} 0%, ${COLORS.roseQuartz.A300} 83.85%)`,
    100: `linear-gradient(140deg, ${COLORS.azzurro.A100} 0%, ${COLORS.roseQuartz.A100} 83.85%)`,
  },
  // To-do: consider refactoring when new theme is defined for Rechargly
  recharglyBlend: {
    500: 'linear-gradient(96deg, #200384 -34.25%, #EE35F5 124.57%)',
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamily,
  lineHeight: 1.2,
  letterSpacing: rawTheme.typography.letterSpacing,
};

const fontBody = {
  color: rawTheme.palette.text.secondary, // ToDo: switch to text.primary when text.primary is changed to riverstone
  fontWeight: rawTheme.typography.fontWeightRegular,
  fontFamily: rawTheme.typography.fontFamily,
  lineHeight: rawTheme.typography.fontHeightRegular,
  letterSpacing: rawTheme.typography.letterSpacing,
};

rawTheme.components.MuiButton = {
  defaultProps: {
    disableRipple: true,
    disableElevation: true,
  },
  styleOverrides: {
    root: (styles) => buttonStyles(styles),
    sizeSmall: { // todo use variables
      height: '24px',
      fontSize: '14px',
      padding: '0px 12px',
    },
    sizeMedium: {
      fontSize: '16px',
      padding: '4px 16px',
    },
    sizeLarge: {
      fontSize: '16px',
      padding: '8px 24px',
    },
    outlinedSizeMedium: {
      padding: '3px 16px',
    },
    outlinedSizeLarge: {
      padding: '7px 24px',
    },
  },
  variants: [
    { props: { variant: 'blend', color: 'primary' } },
  ],
};

rawTheme.components.MuiIconButton = {
  defaultProps: {
    disableRipple: true,
    disableElevation: true,
  },
  styleOverrides: {
    root: (styles) => iconButtonStyles(styles),
    sizeExtraSmall: {
      height: '20px',
      width: '20px',
    },
    sizeSmall: {
      height: '24px',
      width: '24px',
    },
    sizeMedium: {
      height: '28px',
      width: '28px',
    },
    sizeLarge: {
      height: '40px',
      width: '40px',
    },
  },
};

rawTheme.components.MuiAlert = {
  styleOverrides: {
    root: (styles) => alertStyles(styles),
    standard: {
      '& .MuiButton-text': {
        backgroundColor: 'transparent',
        color: 'inherit',
      },
      '& .MuiButton-text:hover': {
        fontWeight: 600,
      },
    },
    standardError: {
      backgroundColor: '#fdeded',
      '.MuiAlert-icon': { color: COLORS.red.A500 },
    },
    standardSuccess: {
      backgroundColor: COLORS.green.A100,
      '.MuiAlert-icon': {
        color: { color: COLORS.green.A500 },
      },
    },
  },
};

rawTheme.components.MuiMenuItem = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
  },
};

rawTheme.components.MuiTooltip = {
  defaultProps: {
    disableInteractive: true,
    arrow: false,
  },
};

rawTheme.components.MuiSkeleton = {
  styleOverrides: {
    root: (styles) => skeletonStyles(styles),
  },
  defaultProps: {
    animation: false,
  },
};

rawTheme.components.MuiTab = {
  styleOverrides: {
    root: (styles) => tabStyles(styles),
  },
  defaultProps: {
    disableFocusRipple: true,
    disableRipple: true,
  },
};

rawTheme.components.MuiToggleButton = {
  defaultProps: {
    disableFocusRipple: true,
    disableRipple: true,
  },
};

rawTheme.components.MuiButtonGroup = {
  defaultProps: {
    disableFocusRipple: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: (styles) => buttonGroupStyles(styles),
  },
};

rawTheme.components.MuiCheckbox = {
  defaultProps: {
    disableFocusRipple: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: (styles) => checkboxStyles(styles),
  },
};

rawTheme.components.MuiTextField = {
  // styleOverrides: {
  //   root: (styles) => textfieldStyles(styles),
  // },
};

export const DEFAULT_FONT_SIZE = 2;

const getTheme = (baseSize = 1) => ({
  ...rawTheme,
  baseSize,
  spacing: (...multipliers) => rawTheme.spacing(...multipliers.map((m) => m * baseSize)), // spacing: (factor) => `${0.25 * factor}rem`, // To Transform everything into rem
  typography: {
    ...rawTheme.typography,
    ...fontHeader,
    h1: { ...fontHeader, fontSize: `${DEFAULT_FONT_SIZE * baseSize}rem` },
    h2: { ...fontHeader, color: rawTheme.palette.text.secondary, fontSize: `${DEFAULT_FONT_SIZE * 0.75 * baseSize}rem` },
    h3: { ...fontHeader, color: rawTheme.palette.text.secondary, fontSize: `${DEFAULT_FONT_SIZE * 0.625 * baseSize}rem` },
    body1: { ...fontBody, fontSize: `${baseSize}rem` },
    body2: {
      ...fontBody, fontSize: `${DEFAULT_FONT_SIZE * 0.4375 * baseSize}rem`, lineHeight: `${DEFAULT_FONT_SIZE * 0.5 * baseSize}rem`,
    },
    caption: {
      ...fontBody,
      fontSize: `${0.875 * baseSize}rem`,
      lineHeight: `${baseSize}rem`,
      color: rawTheme.palette.text.caption,
      fontWeight: rawTheme.typography.fontWeightLight,
    },
  },
});

export default getTheme;
