"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var IconButtons_1 = require("atomicComponents/molecules/IconButtons/IconButtons");
var hooks_1 = require("utils/hooks");
var RemoveOrReinstateButton = function (_a) {
    var item = _a.item;
    var _b = __read((0, hooks_1.useSubmitter)('companies', item.id, { isTest: !item.isTest }), 2), setIsTest = _b[0], isSetting = _b[1];
    var _c = __read((0, hooks_1.useSubmitter)('companies_for_admin', item.id, { isTest: !item.isTest }), 2), setIsTestCompanyForAdmin = _c[0], isSettingCompanyForAdmin = _c[1];
    var Button = item.isTest ? IconButtons_1.UndoButton : IconButtons_1.RemoveButton;
    return ((0, jsx_runtime_1.jsx)(Button, { outlined: true, disabled: isSetting || isSettingCompanyForAdmin, onClick: function (e) {
            e.stopPropagation();
            setIsTest();
            setIsTestCompanyForAdmin();
        } }));
};
exports.default = RemoveOrReinstateButton;
